<template>
  <div
    v-if="event.battles.length"
    class="battles"
  >
    <BattleChip
      v-for="battle in event.battles"
      :id="battle.id"
      :key="battle.id"
      :class="[battle.isRegistered && event.period !== 'past' ? 'registered' : '', 'battle-chip']"
    >
      <template #details>
        <p class="title">
          {{ battle.title }}
        </p>
        <span class="type">{{ getBattleCategoryName(battle.crewSize) }}</span>
      </template>
      <template #additional-info>
        <template v-if="event.period !== 'past'">
          <span v-if="battle.isRegistered"> Registered </span>
          <span
            v-else
            class="register"
          >
            Register
          </span>
        </template>

        <OrganizationLabel
          v-if="battle.orgChip && battle.orgChip.id"
          :chip="battle.orgChip"
        />
      </template>
    </BattleChip>
  </div>
</template>

<script lang="ts" setup>
import { EventDB } from '@/enitites/event'
import { BattleChip } from '@/shared/ui/chips'
import { OrganizationLabel } from '@/shared/ui/labels'
import { useBattle } from '@/enitites/battle'

const { getBattleCategoryName } = useBattle()

defineProps<{
  event: EventDB
}>()
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.battles {
  display: grid;
  gap: 10px;

  .title {
    @include font-h4;

    margin-bottom: 0;
    font-weight: var(--font-weight-bold);
  }

  .type {
    @include font-body2;
  }

  .battle-chip {
    color: var(--color-dark-800);

    &.registered {
      color: var(--color-white);
      background: var(--color-green);
    }
  }

  .register {
    color: var(--color-green);
  }
}
</style>
