<script lang="ts" setup>
import BKButton from '@/components/AppShared/BKButton.vue'
import { UserDB } from '@/enitites/user'
import useValidationRules from '@/shared/composables/validation'
import { computed, ref, watch } from 'vue'
import { useCloned } from '@vueuse/core'
import OnboardingFormLayout from './OnboardingFormLayout.vue'

const props = withDefaults(
  defineProps<{
    currentStep: number
    error: string
    loading: boolean
    steps: number
    user: UserDB
  }>(),
  {
    currentStep: 0,
    error: '',
    loading: false,
    steps: 0,
  }
)
const emit = defineEmits<{
  (e: 'submitForm', value: UserDB): void
}>()

const user = ref(useCloned(props.user).cloned)

const rules = useValidationRules()
const firstName = ref(user.value.fullName.split(' ')[0])
const lastName = ref(user.value.fullName.split(' ')[1])
const fullName = computed(() => `${firstName.value} ${lastName.value}`)
const lastNameRef = ref<HTMLInputElement | null>(null)
const valid = ref(true)

watch(
  () => fullName.value,
  () => {
    user.value.fullName = fullName.value
  }
)
</script>

<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
    title="Full name"
  >
    <template #default>
      <v-form v-model="valid">
        <v-text-field
          v-model="firstName"
          :rules="[rules.required]"
          autocomplete="off"
          background-color="var(--color-white)"
          hide-details
          label="First name*"
          outlined
          @keyup.enter="$nextTick(() => lastNameRef?.focus())"
        />
        <v-text-field
          ref="lastNameRef"
          v-model="lastName"
          :rules="[rules.required]"
          autocomplete="off"
          background-color="var(--color-white)"
          hide-details
          label="Last name*"
          outlined
          @keyup.enter="emit('submitForm', user)"
        />
      </v-form>
    </template>
    <template #actions>
      <BKButton
        :disabled="loading || !valid"
        :loading="loading"
        white-text
        @click="emit('submitForm', user)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>
