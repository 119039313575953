<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import OrganizationMemberChip from '@/modules/Organizations/components/info/OrganizationMemberChip.vue'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { Organization, OrganizationUser, SearchParams } from '@/modules/Organizations/types'
import { fetchLeaderboard } from '@/enitites/organization/api/endpoints'

const props = defineProps<{
  org: Organization
}>()

const members = ref<OrganizationUser[]>([])
const sortDirection = ref(true)

const searchParams = ref<SearchParams>({
  pageNumber: 0,
  pageSize: 100,
  sortBy: 'rank',
  sortDir: 'asc',
  orgId: props.org.id,
  year: new Date().getFullYear(),
})

const toggleSortDirection = () => {
  sortDirection.value = !sortDirection.value
  searchParams.value.sortDir = sortDirection.value ? 'asc' : 'desc'
}

const seasons = computed(() => {
  const startYear = 2021
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i)
  return [...years.map((year) => ({ value: year, title: year })), { value: 0, title: 'All Years' }]
})

const loadMembers = async () => {
  const data = await fetchLeaderboard(searchParams.value)
  if (data) {
    members.value = data
  }
}

watch(searchParams, loadMembers, { deep: true })

onMounted(() => {
  loadMembers()
})
</script>

<template>
  <div class="members">
    <div class="filter-row">
      <div>
        <span class="members-title">
          Leaderboard:
          <span v-if="searchParams.year">{{ searchParams.year }} </span>
          <span v-else>All years</span>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="black"
                v-bind="attrs"
                v-on="on"
              >
                {{ mdiChevronUp }}
              </v-icon>
            </template>
            <v-list
              class="sort-menu"
              width="242"
            >
              <v-list-item>
                <span class="sort-title">Select year</span>
              </v-list-item>

              <v-radio-group
                v-model="searchParams.year"
                class="mt-0"
              >
                <v-list-item
                  v-for="season in seasons"
                  :key="season.value"
                  class="d-flex justify-space-between"
                >
                  <span>{{ season.title }}</span>
                  <div>
                    <v-radio
                      :value="season.value"
                      class="mr-0"
                    />
                  </div>
                </v-list-item>
              </v-radio-group>
            </v-list>
          </v-menu>
        </span>
      </div>

      <div class="filters">
        <div class="sort">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <div
                class="sort-string"
                v-on="on"
              >
                Order by:
                <span v-if="searchParams.sortBy === 'rank'"> Rank </span>

                <span v-else> A - Z </span>
              </div>
            </template>
            <v-list
              class="sort-menu"
              width="242"
            >
              <v-list-item>
                <span class="sort-title">Sort by</span>
              </v-list-item>

              <v-radio-group
                v-model="searchParams.sortBy"
                class="mt-0"
              >
                <v-list-item
                  class="d-flex justify-space-between"
                  @click="searchParams.sortBy = 'rank'"
                >
                  <span>Rank</span>
                  <div>
                    <v-radio
                      class="mr-0"
                      value="rank"
                    />
                  </div>
                </v-list-item>

                <v-list-item
                  class="d-flex justify-space-between"
                  @click="searchParams.sortBy = 'name'"
                >
                  <span>A - Z</span>
                  <div>
                    <v-radio
                      class="mr-0"
                      value="name"
                    />
                  </div>
                </v-list-item>
              </v-radio-group>
            </v-list>
          </v-menu>
          <button
            class="sort-arrows"
            type="button"
            @click="toggleSortDirection"
          >
            <v-icon class="icon-up">
              {{ mdiChevronUp }}
            </v-icon>
            <v-icon class="icon-down">
              {{ mdiChevronDown }}
            </v-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="list-header">
      <div class="rank">Rank</div>
      <div class="stats">
        <div>Member</div>
        <div>Points earned</div>
      </div>
    </div>
    <div class="list">
      <div
        v-for="(member, index) in members"
        :key="member.id || index"
      >
        <OrganizationMemberChip
          :member="member"
          class="member"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.filter-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filters {
  display: flex;

  .v-icon {
    color: var(--color-black);
  }

  .sort {
    display: flex;
    margin-left: 24px;
  }

  .sort-arrows {
    display: flex;
    flex-direction: column;

    .icon-up {
      top: -6px;
    }

    .icon-down {
      top: -12px;
    }
  }
}

.filter-checkbox {
  margin: 0;
}

.sort-string {
  cursor: pointer;
}

.sort-menu {
  .sort-title {
    font-weight: var(--font-weight-bold);
  }
}

.members-title {
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
}

.list-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: var(--color-black);

  .rank {
    min-width: 78px;
  }

  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media (max-width: $screen-sm) {
    display: none;
  }
}

.member {
  margin-bottom: 8px;
}
</style>
