<template>
  <v-progress-circular
    v-if="loading"
    class="loader"
    color="#FC7800"
    indeterminate
    size="200"
    width="15"
  />
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { eventApi } from '@/enitites/event'

const router = useRouter()

const loading = ref(true)

async function redirectToCreateEvent() {
  const event = await eventApi.addEvent()

  loading.value = false

  if (!event) {
    await router.push({ name: 'EventsPage' })
    return
  }

  await router.push({ name: 'CreateEventProcessing', params: { id: String(event.id) } })
}

onBeforeMount(() => {
  redirectToCreateEvent()
})
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  margin: 210px auto;
}
</style>
