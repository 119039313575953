<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounceFn } from '@vueuse/core'
import {
  CenterCoordinates,
  EventMapDB,
  EventMapPeriod,
  EventMapSearchParams,
  mapApi,
  MapCornersCoordinates,
} from '@/enitites/map'
import { EventPreviewCompactCard } from '@/features/event-card'

const props = defineProps<{
  centerCoordinates: CenterCoordinates
  visibleMapCoordinates?: MapCornersCoordinates
  searchParams: EventMapSearchParams
}>()

const emit = defineEmits<{
  (e: 'events', value: EventMapDB[]): void
  (e: 'changed-tab', value: { name: EventMapPeriod; amount: number }): void
  (e: 'hovered-event', value: EventMapDB): void
  (e: 'un-hovered-event'): void
}>()

const tabsInfo = ref<Record<EventMapPeriod, number>>({
  now: 0,
  future: 0,
  past: 0,
})
const tabsList = computed((): { name: EventMapPeriod; amount: number }[] => [
  { name: 'now', amount: tabsInfo.value.now },
  { name: 'future', amount: tabsInfo.value.future },
  { name: 'past', amount: tabsInfo.value.past },
])

const events = ref<EventMapDB[] | []>([])
const loading = ref(false)

async function getCoordinatesOfAddresses(value: EventMapDB[]) {
  if (!google) return
  events.value = value
  const geocoder = new google.maps.Geocoder()
  const promises = events.value.map(
    (event) =>
      new Promise<EventMapDB>((resolve) => {
        geocoder.geocode(
          { address: event.locationAddress },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            if (status === 'OK') {
              resolve({
                ...event,
                coordinates: {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                },
              })
            } else {
              resolve(event)
            }
          }
        )
      })
  )
  const eventsWithCoordinates = await Promise.all(promises)
  emit('events', eventsWithCoordinates)
}

const selectedTab = ref<null | number>(null)
const isNeedSelectTab = ref(true)

function selectTabWithEvents() {
  if (!isNeedSelectTab.value) return
  // eslint-disable-next-line no-restricted-syntax
  for (const key in tabsInfo.value) {
    if (tabsInfo.value[key as EventMapPeriod]) {
      selectedTab.value = Object.keys(tabsInfo.value).indexOf(key)
      emit('changed-tab', tabsList.value[selectedTab.value])
      isNeedSelectTab.value = false
      break
    }
  }
}

async function getEventsMap() {
  if (!props.visibleMapCoordinates) return
  loading.value = true
  const data = await mapApi.getMapEvents({
    ...props.searchParams,
    userCoords: [props.centerCoordinates.lat, props.centerCoordinates.lng],
    visibleMap: props.visibleMapCoordinates,
  })

  if (!data) {
    events.value = []
    loading.value = false
    return
  }
  const { events: dataEvents, stats } = data
  events.value = dataEvents
  tabsInfo.value = {
    now: stats.now,
    future: stats.future,
    past: stats.past,
  }

  selectTabWithEvents()
  await getCoordinatesOfAddresses(events.value)
  loading.value = false

  for (let i = 0; i < events.value.length; i++) {
    const event = events.value[i]
    if (event.coordinates) {
      emit('events', events.value)
      break
    }
  }
}

const debouncedGetEventsMap = useDebounceFn(getEventsMap, 750)
watch(
  [() => props.searchParams, () => props.centerCoordinates, () => props.visibleMapCoordinates],
  () => {
    debouncedGetEventsMap()
  },
  { deep: true }
)
</script>

<template>
  <div class="events-list-map">
    <v-tabs
      v-model="selectedTab"
      :show-arrows="false"
      centered
      color="var(--color-dark-800)"
      grow
    >
      <template v-for="tabItem in tabsList">
        <v-tab
          :key="tabItem.name"
          :ripple="false"
          class="tab"
          @click="emit('changed-tab', tabItem)"
        >
          {{ tabItem.name }}({{ tabItem.amount }})
        </v-tab>
      </template>
    </v-tabs>
    <v-progress-circular
      v-if="loading"
      aria-hidden="true"
      class="loader"
      color="var(--color-orange-500)"
      indeterminate
      size="60"
      width="6"
    />
    <template v-else>
      <ul
        v-if="events.length"
        aria-label="Events list"
        class="events-list"
      >
        <li
          v-for="event in events"
          :key="event.id"
          class="events-item"
          @blur="emit('un-hovered-event')"
          @focus="emit('hovered-event', event)"
          @mouseleave="emit('un-hovered-event')"
          @mouseover="emit('hovered-event', event)"
        >
          <EventPreviewCompactCard :event="event" />
        </li>
      </ul>
      <p
        v-else
        class="no-results-msg"
      >
        No events in this area
      </p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.events-list-map {
  display: grid;
  grid-template-rows: 48px auto;
  padding-top: 40px;
  padding-left: 20px;
  overflow: hidden;

  .v-tab {
    font-family: Roboto, Helvetica, sans-serif;
    border-bottom: 2px solid var(--color-grey-600);
  }

  .loader {
    margin: calc(50% - 30px);

    @media (max-width: 768px) {
      display: none;
    }
  }

  .events-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding: 0;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .events-item {
      display: inherit;
      width: 296px;

      &:first-child {
        margin-top: 30px;
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }

    .event-link {
      width: 100%;
    }
  }

  .no-results-msg {
    margin: 16px;
    text-align: center;
  }
}
</style>
