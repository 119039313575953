<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
    title="Username"
  >
    <template #default>
      <v-form
        v-model="valid"
        @submit.prevent="emit('submitForm', user)"
      >
        <v-text-field
          v-model="user.dancerName"
          :rules="[rules.required]"
          autocomplete="off"
          background-color="var(--color-white)"
          hide-details
          label="Dancer name, nickname, etc...*"
          outlined
        />
      </v-form>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading || !valid"
        :loading="loading"
        white-text
        @click="emit('submitForm', user)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script lang="ts" setup>
import BKButton from '@/components/AppShared/BKButton.vue'
import { UserDB } from '@/enitites/user'
import { ref } from 'vue'
import useValidationRules from '@/shared/composables/validation'
import { useCloned } from '@vueuse/core'
import OnboardingFormLayout from './OnboardingFormLayout.vue'

const props = withDefaults(
  defineProps<{
    currentStep: number
    error: string
    loading: boolean
    steps: number
    user: UserDB
  }>(),
  {
    currentStep: 0,
    error: '',
    loading: false,
    steps: 0,
  }
)

const emit = defineEmits<{
  (e: 'goBack'): void
  (e: 'submitForm', value: UserDB): void
}>()

const rules = useValidationRules()

const valid = ref(true)
const user = ref(useCloned(props.user).cloned)
</script>
