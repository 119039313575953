<template>
  <div>
    <BKLoader v-if="loading" />
    <section
      v-else
      class="brackets"
    >
      <div class="wrapper">
        <BattleHeading
          :battle="battle"
          :event-id="event.id"
        />

        <div class="content-wrapper">
          <BattleBracketsEditor
            v-if="isEditMode"
            :battle="battle"
            :brackets="brackets"
            @cancel="isEditMode = false"
            @update:brackets="brackets = $event"
          />

          <BattleBracketsRenderer
            v-else
            :battle="battle"
            :brackets.sync="brackets"
            :can-edit="event.permissions.canEdit"
            @edit="isEditMode = true"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'
import { mapActions } from 'vuex'

import BattleHeading from '@/components/Battle/BattleHeading.vue'
import BattleBracketsRenderer from '@/components/Battle/Brackets/BattleBracketsRenderer.vue'
import BattleBracketsEditor from '@/components/Battle/Brackets/BattleBracketsEditor.vue'
import { eventApi } from '@/enitites/event'

export default {
  name: 'BattleBrackets',
  components: {
    BattleBracketsEditor,
    BattleHeading,
    BattleBracketsRenderer,
  },
  data() {
    return {
      brackets: null,
      isEditMode: false,
      loading: true,
      battle: null,
      event: null,
    }
  },
  watch: {
    isEditMode() {
      window.scrollTo({ top: 0 })
    },
  },
  async mounted() {
    await Promise.all([this.initBattle(), this.initBrackets()])
    await this.initEvent()

    this.loading = false
  },
  methods: {
    ...mapActions(['setIsNotFound']),
    async initBattle() {
      const battle = await api.battles.getBattle(this.$route.params.id)
      if (!battle) {
        this.setIsNotFound(true)
        return
      }

      this.battle = battle
    },
    async initEvent() {
      if (!this.battle) return
      const event = await eventApi.getEvent(this.battle.eventId)
      if (!event) {
        this.setIsNotFound(true)
        return
      }

      this.event = event
    },
    async initBrackets() {
      const brackets = await api.battles.getBrackets(this.$route.params.id)
      if (!brackets) return
      this.brackets = brackets
    },
  },
}
</script>

<style lang="scss" scoped>
.brackets {
  background-color: var(--color-grey-100);
}

.wrapper {
  max-width: 904px;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 78px;
  padding-bottom: 40px;
}

.content-wrapper {
  padding: 0 20px;
}
</style>
