<template>
  <router-link
    :to="{ name: 'Profile', params: { id: user.id } }"
    class="user-link"
  >
    <v-avatar
      class="mr-1"
      size="32px"
    >
      <v-img :src="getImageById(user.avatar, 32, 32, 'avatar')" />
    </v-avatar>
    {{ user.dancerName }}
  </router-link>
</template>

<script lang="ts" setup>
import { UserChip } from '@/enitites/event'
import { useImage } from '@/shared/composables/image'

const { getImageById } = useImage()
defineProps<{
  user: UserChip
}>()
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.user-link {
  color: var(--color-black);
}
</style>
