<script lang="ts" setup>
import { EventDB } from '@/enitites/event'
import { EventPreviewBaseCard } from '@/features/event-card'

interface IProps {
  events: EventDB[]
  searchParamsTab: boolean
  isNotFound: boolean
  infiniteId: number
}

defineProps<IProps>()

const emit = defineEmits<{ (e: 'infinite', value: unknown): void }>()

function infiniteHandler($state: unknown) {
  emit('infinite', $state)
}
</script>

<template>
  <div class="cards">
    <div
      v-if="isNotFound"
      class="events-not-found"
    >
      <img
        alt="Not found image"
        src="/img/not-found.svg"
        width="100%"
      />
      No Events exist with these parameters
    </div>
    <div class="card-list">
      <EventPreviewBaseCard
        v-for="event in events"
        :key="event.id"
        :preview-event="event"
      />
    </div>
    <infinite-loading
      v-if="searchParamsTab"
      :distance="400"
      :identifier="infiniteId"
      spinner="bubbles"
      @infinite="infiniteHandler"
    >
      <template #spinner>
        <v-progress-circular
          color="#FC7800"
          indeterminate
          size="80"
          width="10"
        />
      </template>
      <span slot="no-more" />
      <span slot="no-results" />
    </infinite-loading>
  </div>
</template>
<style lang="scss" scoped>
.cards {
  padding-top: 62px;
  padding-inline: 48px;

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-inline: 20px;
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    padding: 0;
    list-style: none;
  }

  .events-not-found {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 30px 60px;
    color: #999;
    font-size: 96px;
    line-height: 115px;
    text-align: center;

    @media (max-width: 495px) {
      font-size: 60px;
      line-height: 65px;
    }

    img {
      margin-bottom: 20px;
    }
  }
}
</style>
