import { render, staticRenderFns } from "./EventPreviewBaseCardDesktop.vue?vue&type=template&id=082f34f0&scoped=true"
import script from "./EventPreviewBaseCardDesktop.vue?vue&type=script&lang=ts&setup=true"
export * from "./EventPreviewBaseCardDesktop.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./EventPreviewBaseCardDesktop.vue?vue&type=style&index=0&id=082f34f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "082f34f0",
  null
  
)

export default component.exports