<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { useImage } from '@/shared/composables/image'
import useValidationRules from '@/shared/composables/validation'
import { userApi } from '@/enitites/user'

const { getImageById } = useImage()
const rules = useValidationRules()
const route = useRoute()
const router = useRouter()

const password = ref('')
const passwordRef = ref<HTMLInputElement | null>(null)
const confirmedPassword = ref('')
const confirmPasswordRef = ref<HTMLInputElement | null>(null)
const email = ref('')
const showPassword = ref(false)
const error = ref<string | null>(null)
const valid = ref(true)
const fullName = ref('')
const avatar = ref('')

const getReleasingProfileId = computed(() => Number(route.params.id))

const saveProfileData = async (id: number) => {
  const user = await userApi.getUser(id)
  if (!user) {
    await store.dispatch('setIsNotFound', !user)
    return
  }

  localStorage.setItem('currentProfileId', id.toString())
  await store.dispatch('setUserDB', user)
  await router.push({ name: 'ProfileSetting', query: { tabName: 'SettingsOtherProfiles' } })
}

const sendProfileData = async () => {
  const { data } = await userApi.releaseProfile({
    id: getReleasingProfileId.value,
    password: password.value,
    email: email.value,
  })

  if (!data) return

  await saveProfileData(data.id)
}

const validateEqual = () => {
  error.value = password.value === confirmedPassword.value ? null : 'Passwords must be equal'
  if (error.value) return
  sendProfileData()
}

const fetchReleasingProfile = async () => {
  const user = await userApi.getUser(getReleasingProfileId.value)
  if (!user) {
    await store.dispatch('setIsNotFound', !user)
    return
  }

  fullName.value = user.fullName
  avatar.value = user.avatar
}

onMounted(() => {
  fetchReleasingProfile()
})
</script>

<template>
  <section class="release-profile">
    <div class="background">
      <div class="container">
        <div class="text-container">
          <h2 class="main-header page-title">Release Profile</h2>
          <p class="subtitle">
            By releasing this profile, it will no longer be associated with “email”. It will have it's own email and
            password.
          </p>
        </div>
        <div class="avatar-container">
          <img
            :src="getImageById(avatar, 48, 48, 'avatar')"
            alt="avatar"
            class="photo"
            height="48"
            width="48"
          />
          <p>{{ fullName }}</p>
        </div>
        <v-form
          v-model="valid"
          class="form"
          lazy-validation
        >
          <v-text-field
            v-model="email"
            autocomplete="off"
            background-color="#fff"
            class="input-email"
            hide-details="auto"
            label="Email"
            outlined
            @input="$nextTick(() => (email = email.trim()))"
            @keyup.enter="$nextTick(() => passwordRef?.focus())"
          />
          <div class="password-container">
            <p class="label-pass">Password</p>
            <v-text-field
              ref="passwordRef"
              v-model.trim="password"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="off"
              background-color="#fff"
              class="input-password"
              hide-details="auto"
              label="Password"
              outlined
              @click:append="showPassword = !showPassword"
              @keyup.enter="$nextTick(() => confirmPasswordRef?.focus())"
            />
            <v-text-field
              ref="confirmPasswordRef"
              v-model.trim="confirmedPassword"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="off"
              background-color="#fff"
              hide-details="auto"
              label="Confirm password"
              outlined
              @click:append="showPassword = !showPassword"
            />
          </div>

          <v-btn
            block
            class="btn"
            color="var(--color-blue-800)"
            height="48"
            tile
            @click="validateEqual"
          >
            Release profile
          </v-btn>
          <div class="error-container">
            <span
              v-show="error"
              class="error-message"
            >
              {{ error }}
            </span>
          </div>
        </v-form>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.release-profile {
  @include profile-settings-bg;
}

.container {
  padding: 40px 20px;

  @media (min-width: 768px) {
    max-width: 500px;
  }
}

.text-container {
  display: grid;
  gap: 10px;
  margin-bottom: 40px;
  color: var(--color-dark-800);
  text-align: center;

  .main-header {
    @include font-h2;

    color: var(--color-dark-800);
    text-transform: uppercase;
  }

  .subtitle {
    @include font-body2;
  }
}

.avatar-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 40px;

  .photo {
    object-fit: cover;
    border-radius: 50%;
  }
}

.form {
  .v-input {
    border-radius: var(--border-radius-rounded);
  }

  ::v-deep .theme--light.v-text-field--outlined fieldset {
    border-color: var(--color-grey-600);
  }

  .input-email {
    margin-bottom: 20px;
  }

  .password-container {
    margin-bottom: 40px;

    .label-pass {
      margin-bottom: 10px;
    }

    .input-password {
      margin-bottom: 8px;
    }
  }

  .btn {
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    border-radius: var(--border-radius-rounded);
    box-shadow: none;
  }
}

.error-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .error-message {
    color: var(--rasberry-red);
  }
}
</style>
