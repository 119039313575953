<script lang="ts" setup>
import { computed } from 'vue'
import { useVuetify } from '@/composables/vuetify'
import CalendarDesktop from './CalendarDesktop.vue'
import CalendarMobile from './CalendarMobile.vue'

const vuetify = useVuetify()
const isDesktop = computed(() => vuetify.breakpoint.mdAndUp)
</script>

<template>
  <CalendarDesktop v-if="isDesktop" />
  <CalendarMobile v-else />
</template>
