<template>
  <div class="event-sidebar">
    <div class="event-sidebar-copy">
      <button
        v-if="!isTabletDesktop"
        class="title-container"
        type="button"
        @click="handleMobileInfoToggle"
      >
        <span class="paragraph event-title">
          {{ event.title }}
        </span>

        <span>
          <v-icon
            v-if="isCollapsed"
            color="var(--color-white)"
          >
            {{ mdiChevronUp }}
          </v-icon>
          <v-icon
            v-if="!isCollapsed"
            color="var(--color-white)"
          >
            {{ mdiChevronDown }}
          </v-icon>
        </span>
      </button>
      <div
        v-else
        class="title-container"
      >
        <p class="paragraph event-title">
          {{ event.title }}
        </p>
      </div>
    </div>
    <div v-if="isTabletDesktop">
      <p class="paragraph">
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiCalendarCheck }}
        </v-icon>
        {{ event.dateFlat || date }}
      </p>
      <p class="paragraph">
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiClockOutline }}
        </v-icon>
        {{ event.timeFlat || time }}
      </p>
      <p
        v-if="event.locationAddress"
        class="paragraph"
      >
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiMapMarkerRadius }}
        </v-icon>
        <a
          :href="`https://www.google.com/maps/search/${event.locationAddress}`"
          class="link"
          target="_blank"
        >
          {{ event.locationAddress }}
        </a>
      </p>
      <p
        v-if="event.website"
        class="paragraph"
      >
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiLink }}
        </v-icon>
        <a
          :href="event.website"
          class="link"
          target="_blank"
        >
          Visit website to learn more
        </a>
      </p>
      <p
        v-if="totalTierCost !== 'Free'"
        class="paragraph"
      >
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiCurrencyUsd }}
        </v-icon>
        {{ totalTierCost }}<br />
      </p>
    </div>
    <div
      v-if="!isCollapsed && !isTabletDesktop"
      class="collapsible-content"
    >
      <p class="paragraph">
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiCalendarCheck }}
        </v-icon>
        {{ event.dateFlat || date }}
      </p>
      <p class="paragraph">
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiClockOutline }}
        </v-icon>
        {{ event.timeFlat || time }}
      </p>
      <p
        v-if="event.locationAddress"
        class="paragraph"
      >
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiMapMarkerRadius }}
        </v-icon>
        <a
          :href="`https://www.google.com/maps/search/${event.locationAddress}`"
          class="link"
          target="_blank"
        >
          {{ event.locationAddress }}
        </a>
      </p>
      <p
        v-if="event.website"
        class="paragraph"
      >
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiLink }}
        </v-icon>
        <a
          :href="event.website"
          class="link"
          target="_blank"
        >
          Visit website to learn more
        </a>
      </p>
      <p
        v-if="totalTierCost !== 'Free'"
        class="paragraph"
      >
        <v-icon
          class="icon"
          color="var(--color-white)"
        >
          {{ mdiCurrencyUsd }}
        </v-icon>
        {{ totalTierCost }}<br />
      </p>
    </div>
    <div>
      <v-btn
        v-if="event.permissions.canEdit"
        block
        class="white--text d-flex justify-space-between"
        color="var(--color-blue-800)"
        height="48"
        tile
        @click="editEvent"
      >
        edit event
        <v-icon right>
          {{ mdiPencilOutline }}
        </v-icon>
      </v-btn>

      <div class="mt-3">
        <BKCheckinButton
          :event="event"
          @purchase="emit('reload:event')"
          @update:event="emit('update:event', $event)"
        />
      </div>

      <v-btn
        v-if="event.permissions.canEdit"
        :to="{ name: 'Checkin', params: { idEvent: event.id } }"
        block
        class="mt-3 mb-4 white--text font-weight-bold"
        color="var(--color-green)"
        height="48"
        tile
      >
        Tickets and check-ins
        <v-icon
          class="ml-auto"
          color="white"
          size="28"
        >
          {{ mdiCheckCircle }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BKCheckinButton } from '@/features/checkin'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getDateRangeFormatted, getTimeRangeFormatted } from '@/services/date'
import {
  mdiCalendarCheck,
  mdiCheckCircle,
  mdiChevronDown,
  mdiChevronUp,
  mdiClockOutline,
  mdiCurrencyUsd,
  mdiLink,
  mdiMapMarkerRadius,
  mdiPencilOutline,
} from '@mdi/js'
import { EventDB } from '@/enitites/event'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useVuetify } from '@/services/useVuetify'

const vuetify = useVuetify()
const isTabletDesktop = computed(() => vuetify.breakpoint.mdAndUp)
const props = defineProps<{
  event: EventDB
}>()

const emit = defineEmits<{
  (e: 'update:event', updatedEvent: EventDB): void
  (e: 'reload:event'): void
}>()

const router = useRouter()

const isCollapsed = ref(true)
const date = computed(() => getDateRangeFormatted(props.event.dateStart, props.event.dateEnd, false))
const time = computed(() => getTimeRangeFormatted(props.event.dateStart, props.event.dateEnd))
const totalTierCost = computed(() => {
  if (!props.event.tiers.length) return 'Free'
  const costs = props.event.tiers.map((tier) => tier.cost)
  const min = Math.min(...costs)
  const max = Math.max(...costs)
  if (min === Infinity || max === Infinity) return ' '
  if (max === 0) {
    return 'Free'
  }
  if (min === max) return `$${max}`

  return `$${min} - $${max}`
})

function editEvent() {
  router.push({ name: 'CreateEventProcessing', params: { id: String(props.event.id) } })
}

function handleMobileInfoToggle() {
  isCollapsed.value = !isCollapsed.value
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.event-sidebar {
  position: relative;
  width: 100%;
  max-width: 340px;
  padding-left: 40px;

  @media (max-width: 920px) {
    width: 90%;
    max-width: 100%;
    margin: 30px auto 0;
    padding-left: 0;
  }
}

.event-sidebar-copy {
  position: relative;

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
  }
}

.paragraph {
  position: relative;
  display: flex;
  gap: 10px;
  min-height: 24px;
  color: var(--color-white);

  &.event-title {
    @include font-h2;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    padding-left: 0;
    font-weight: var(--font-weight-bold);

    @media (max-width: 600px) {
      @include font-h4;
    }
  }

  a {
    color: inherit;
  }
}
</style>
