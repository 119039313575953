<template>
  <div class="battle-org-info">
    <v-avatar size="40">
      <img
        :src="getImageById(chip.image, 80, 80, 'org')"
        alt=""
      />
    </v-avatar>
  </div>
</template>

<script lang="ts" setup>
import { OrganizationChip } from '@/enitites/event'
import { useImage } from '@/shared/composables/image'

const { getImageById } = useImage()
defineProps<{
  chip: OrganizationChip
}>()
</script>
