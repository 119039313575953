<script lang="ts" setup>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import BKImage from '@/components/AppShared/BKImage.vue'
import { useImage } from '@/shared/composables/image'
import { ref } from 'vue'
import interactionPlugin from '@fullcalendar/interaction'
import { CalendarOptions, EventSegment, EventSourceFuncArg, MoreLinkArg, MoreLinkContentArg } from '@fullcalendar/core'
import { eventApi, EventDB } from '@/enitites/event'

function getOffsetDate(date: string) {
  const newDate = new Date(date)
  const offsetDate = newDate.getTimezoneOffset()
  return new Date(newDate.getTime() - offsetDate * 60000).toISOString().split('T')[0]
}

const activeMobileList = ref(false)
const modalEventsData = ref<{ id: string; title: string; start: Date | null; img: string }[]>([])
const clickedDateEl = ref<HTMLElement | null>(null)
const calendar = ref<InstanceType<typeof FullCalendar> | null>(null)

function changePopoverPosition(cell: HTMLElement | null) {
  if (!cell) return
  setTimeout(() => {
    const popover: HTMLElement | null = document.querySelector('.fc-popover')
    const popoverContainerRect: DOMRect | undefined = document
      .querySelector('.fc-view-harness')
      ?.getBoundingClientRect()
    if (!popover || !popoverContainerRect) return
    const { width: calendarCellWidth } = cell.getBoundingClientRect()
    const { bottom: popoverBottomPosition, right: popoverRightPosition } = popover.getBoundingClientRect()
    const { bottom: popoverContainerBottomPosition, right: popoverContainerRightPosition } = popoverContainerRect
    const padding = 20
    const currentPopoverLeftPosition = parseFloat(popover.style.left)

    const isPopoverOverflowBottom = popoverBottomPosition > popoverContainerBottomPosition
    if (isPopoverOverflowBottom) {
      const differenceBottomPositions = popoverBottomPosition - popoverContainerBottomPosition
      const currentTop = parseFloat(popover.style.top)
      popover.style.top = `${currentTop - differenceBottomPositions - padding}px`
    }

    if (Math.abs(popoverRightPosition - popoverContainerRightPosition) < calendarCellWidth) {
      popover.style.left = `${currentPopoverLeftPosition - calendarCellWidth}px`
      return
    }

    const isPopoverOverflowRight = popoverRightPosition > popoverContainerRightPosition

    if (isPopoverOverflowRight) {
      const differenceRightPositions = popoverRightPosition - popoverContainerRightPosition
      popover.style.left = `${currentPopoverLeftPosition - differenceRightPositions - calendarCellWidth}px`
    } else {
      popover.style.left = `${currentPopoverLeftPosition + calendarCellWidth}px`
    }
  }, 100)
}

function updateCellClassName(cell: HTMLElement | null) {
  if (clickedDateEl.value) clickedDateEl.value.classList.remove('day-clicked')
  clickedDateEl.value = cell
  clickedDateEl.value?.classList.add('day-clicked')
}

const calendarOptions: CalendarOptions = {
  initialView: 'dayGridMonth',
  plugins: [dayGridPlugin, interactionPlugin],
  headerToolbar: {
    left: '',
    center: 'prev title next',
    end: '',
  },
  events: async (fetchInfo: EventSourceFuncArg) => {
    const start = getOffsetDate(fetchInfo.startStr)
    const end = getOffsetDate(fetchInfo.endStr)

    const data = await eventApi.getEvents({
      pageNumber: 0,
      pageSize: 999,
      start,
      end,
    })

    if (!data || typeof data === 'string') return []
    const publishedEvents = data.filter((event: EventDB) => event.status === 'published')

    return publishedEvents.map((event: EventDB) => ({
      id: event.id,
      title: event.title,
      start: event.dateStart,
      img: event.image,
      optionalEnd: event.dateEnd,
    }))
  },
  eventColor: 'transparent',
  eventBackgroundColor: 'transparent',
  eventDisplay: 'block',
  dayMaxEvents: true,
  dayHeaderFormat: {
    weekday: 'long',
  },
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: 'short',
  },
  views: {
    dayGridMonth: {
      dayMaxEvents: 4,
    },
  },
  fixedWeekCount: false,
  dayPopoverFormat: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  moreLinkContent: (args: MoreLinkContentArg) => `+${args.num}`,
  moreLinkClick: (info: MoreLinkArg) => {
    const dayEl = (info.jsEvent.target as HTMLElement)?.offsetParent?.offsetParent.parentElement
    updateCellClassName(dayEl)
    // wait for render of popover to calculate popover position
    changePopoverPosition(dayEl)

    modalEventsData.value = info.allSegs.map((event: EventSegment) => {
      return {
        id: event.event.id,
        title: event.event.title,
        start: event.event.start,
        img: event.event.extendedProps.img,
        optionalEnd: event.event.extendedProps.optionalEnd,
      }
    })
    return 'popover'
  },
  eventWillUnmount: () => {
    clickedDateEl.value?.classList.remove('day-clicked')
  },
  expandRows: true,
}

const { getImageById } = useImage()
</script>

<template>
  <section :class="['calendar-mode', { active: activeMobileList }]">
    <h1 class="title">Calendar Mode</h1>
    <FullCalendar
      ref="calendar"
      :events="calendarOptions.events"
      :options="calendarOptions"
      class="calendar"
    >
      <template #eventContent="{ event, timeText }">
        <router-link
          v-if="!activeMobileList"
          :to="{ name: 'Event', params: { id: event.id } }"
          class="link"
        >
          <BKImage
            :src="getImageById(event.extendedProps.img, 90, 90, 'event')"
            alt=""
            class="event-img"
            height="45"
            width="45"
          />
          <div class="event-description-wrapper">
            <span class="time-text">{{ timeText }}</span>
            <span class="title-text">{{ event.title || 'Untitled' }}</span>
          </div>
        </router-link>
      </template>
    </FullCalendar>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.calendar-mode {
  display: grid;
  height: auto;
  overflow: hidden;

  .title {
    @include visually-hidden;
  }

  .link {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .event-description-wrapper {
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 2px 4px;
    overflow: hidden;
  }

  .event-img {
    display: none;
  }

  .time-text {
    @include font-caption1;

    color: var(--color-grey-300);
    text-transform: uppercase;
  }

  .title-text {
    @include font-caption1;

    overflow: hidden;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-medium);
    text-overflow: ellipsis;
  }

  .fc-day-other {
    .time-text {
      opacity: 0.2;
    }

    .title-text {
      opacity: 0.2;
    }
  }

  .calendar {
    --fc-border-color: var(--color-grey-700);
    --fc-page-bg-color: var(--color-grey-100);

    height: 100%;
    background-color: var(--color-grey-100);
  }

  ::v-deep {
    .fc-daygrid-body tr {
      height: 160px;
    }

    .fc-daygrid-more-link.fc-more-link {
      @include font-caption1;

      margin-top: 6px;
      color: var(--color-dark-800);
      font-weight: var(--font-weight-medium);
    }

    .fc-day-other {
      pointer-events: none;

      .fc-daygrid-more-link.fc-more-link {
        opacity: 0.2;
      }
    }

    .fc-toolbar.fc-header-toolbar {
      padding-block: 16px;
      margin: 0;
    }

    .fc-toolbar-chunk {
      display: flex;
      gap: 20px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        color: var(--color-grey-300);
        background-color: transparent;
        border: none;

        .fc-icon {
          display: flex;
        }

        &:is(:hover, :focus, :active, :focus-visible) {
          color: var(--color-grey-300) !important;
          background-color: transparent !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }

      .fc-toolbar-title {
        @include font-body1;

        margin: 0;
        color: var(--color-grey-300);
      }
    }

    .fc-daygrid-day-events {
      min-height: 0 !important;
      margin: 0 !important;
    }

    .fc-daygrid-event {
      margin-top: 4px;
      margin-inline: 0 !important;
      border: none !important;
    }

    .fc-scrollgrid {
      border-top: none;
    }

    .fc-daygrid-day {
      background-color: var(--color-grey-100);
    }

    .fc-daygrid-day-frame {
      max-height: 160px;
      padding: 6px 2px 12px;

      .fc-daygrid-day-top {
        justify-content: center;

        .fc-daygrid-day-number {
          @include font-caption1;

          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          color: var(--color-dark-800);
        }
      }
    }

    .fc-col-header-cell.fc-day {
      border: none;
      border-bottom: 1px solid var(--color-grey-700);
    }

    .fc-col-header-cell-cushion {
      @include font-caption1;

      max-height: 40px;
      color: var(--color-pure-black);
      text-align: center;
      padding-block: 12px;
    }

    & .fc-daygrid-day.fc-day-today {
      background-color: var(--color-grey-100);

      & .fc-daygrid-day-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 4px;
        color: var(--color-white);
        background-color: var(--color-gold-900);
        border-radius: 50%;
      }
    }

    .fc-daygrid-day.day-clicked {
      background-color: var(--color-gold-800);

      .fc-daygrid-day-number {
        color: var(--color-gold-900);
      }

      .time-text {
        color: var(--color-gold-900);
      }

      .title-text {
        color: var(--calendar-active-text);
      }
    }

    .fc-popover {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      width: 388px;
      height: 100%;
      max-height: 378px;
      padding: 20px;
      overflow-y: scroll;
      background-color: var(--color-white);
      border: 1px solid var(--color-grey-600);
      border-radius: 10px;
      box-shadow: 0 8px 20px 0 rgb(0 0 0 / 16%);
      transition:
        top 0.5s ease-in-out,
        left 0.5s ease-in-out;

      .fc-popover-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: var(--color-white);

        .fc-popover-title {
          @include font-body1;

          width: 100%;
          color: var(--color-pure-black);
          text-align: center;
        }
      }

      .fc-popover-body {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        padding: 0;

        .fc-event-main a {
          .event-description-wrapper {
            flex-direction: column-reverse;
            align-items: flex-start;
            padding: 0;
          }

          .event-img {
            display: flex;
            border-radius: 4px;
          }

          .title-text {
            max-width: 276px;
            font-size: 16px;
          }

          .time-text {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.active {
    grid-template-rows: 1fr 1fr;

    ::v-deep {
      .fc-daygrid-day.day-clicked {
        background-color: var(--color-gold-800);

        .fc-more-link {
          &::before {
            background-color: var(--color-gold-900);
          }
        }
      }
    }
  }
}
</style>
