<script lang="ts" setup>
import BKInput from '@/components/AppShared/BKInput.vue'
import { onMounted, ref, watch } from 'vue'
import { mdiMagnify } from '@mdi/js'
import { eventApi, EventDB, EventPeriod, EventSearchParams } from '@/enitites/event'
import { EventPreviewCompactCard } from '@/features/event-card'
import { useThrottleFn } from '@vueuse/core'

const emit = defineEmits<{
  (e: 'changed-tab', tab: EventPeriod): void
}>()

const tabs = ref(0)
const tabsList = ref<
  {
    name: EventPeriod
    title: string
    amount: number
  }[]
>([
  { name: 'now', title: 'Now', amount: 0 },
  { name: 'future', title: 'Future', amount: 0 },
  { name: 'past', title: 'Past', amount: 0 },
])

const payload = ref<EventSearchParams>({
  pageNumber: 0,
  pageSize: 50,
  keyword: '',
  sortBy: 'date-asc',
  tab: tabsList.value[0].name,
  orgIds: [],
})

async function getAvailableTabs() {
  const availableTabs = await eventApi.getEventsAvailableTabs({
    userId: undefined,
    orgId: undefined,
  })
  tabsList.value = tabsList.value.map((tabItem) => ({
    ...tabItem,
    amount: availableTabs[tabItem.name as keyof typeof availableTabs],
  }))
}

const events = ref<EventDB[]>([])
const infiniteId = ref(+new Date())

function resetPayload() {
  payload.value.pageNumber = 0
  events.value = []
  infiniteId.value += 1
}

async function getEvents() {
  payload.value.tab = tabsList.value[tabs.value].name
  resetPayload()
  emit('changed-tab', payload.value.tab)
}

const sortBy = ref('date')
const sortDirection = ref(true)
const loading = ref(false)

const defaultSortDirections: Record<EventPeriod, boolean> = {
  now: true,
  past: false,
  future: true,
  draft: false,
}

watch(
  () => payload.value.tab,
  (tab) => {
    sortDirection.value = defaultSortDirections[tab]
    payload.value.tab = tab
    resetPayload()
    payload.value.keyword = ''
  }
)

async function infiniteHandler($state: { complete: () => void; loaded: () => void }) {
  payload.value.sortBy = `${sortBy.value}-${sortDirection.value ? 'asc' : 'desc'}`
  loading.value = true
  const data = await eventApi.getEvents(payload.value)
  loading.value = false
  if (!data || typeof data === 'string') return
  events.value.push(...data)
  payload.value.pageNumber += 1
  if (data.length < payload.value.pageSize) $state.complete()
  else $state.loaded()
}

const throttledResetPayload = useThrottleFn(resetPayload, 1200)

onMounted(() => {
  getAvailableTabs()
})
</script>

<template>
  <div class="search-events">
    <BKInput
      v-model="payload.keyword"
      :prepend-icon="mdiMagnify"
      color="var(--color-grey-300)"
      label="Search events"
      @input="throttledResetPayload"
    />
    <v-tabs
      v-model="tabs"
      centered
      color="var(--color-dark-800)"
      @change="getEvents"
    >
      <v-tab
        v-for="tabItem in tabsList"
        :key="tabItem.name"
        :ripple="false"
        class="tab"
      >
        {{ tabItem.title }}
        ({{ tabItem.amount }})
      </v-tab>
    </v-tabs>
    <ul
      v-if="events.length"
      class="events-list"
    >
      <li
        v-for="event in events"
        :key="event.id"
      >
        <EventPreviewCompactCard :event="event" />
      </li>
    </ul>
    <p
      v-else-if="!events.length && !loading"
      class="no-results-msg"
    >
      No results
    </p>
    <infinite-loading
      :identifier="infiniteId"
      spinner="bubbles"
      @infinite="infiniteHandler"
    >
      <template #spinner>
        <v-progress-circular
          color="#FC7800"
          indeterminate
          size="80"
          width="4"
        />
      </template>
      <span slot="no-more" />
      <span slot="no-results" />
    </infinite-loading>
  </div>
</template>

<style lang="scss" scoped>
.search-events {
  display: grid;
  gap: 40px;

  .v-tab {
    font-family: Roboto, Helvetica, sans-serif;
    border-bottom: 2px solid var(--color-grey-600);
  }

  .events-list {
    display: grid;
    gap: 20px;
    padding: 0;

    .link {
      display: block;
    }
  }

  .no-results-msg {
    text-align: center;
  }
}
</style>
