<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { mdiFacebook, mdiImageOutline, mdiInstagram, mdiLink, mdiTwitter, mdiYoutube } from '@mdi/js'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { useImage } from '@/shared/composables/image'
import OrganizationAbout from '@/modules/Organizations/components/info/OrganizationAbout.vue'
import OrganizationEvents from '@/modules/Organizations/components/info/OrganizationEvents.vue'
import OrganizationMedia from '@/modules/Organizations/components/info/OrganizationMedia.vue'
import OrganizationMembers from '@/modules/Organizations/components/info/OrganizationMembers.vue'
import { Organization, OrganizationTabsInfo } from '@/modules/Organizations/types'
import { fetchOrganization, updateOrganization } from '@/enitites/organization/api/endpoints'

const { saveImage, getOrgImage } = useImage()

const route = useRoute()
const router = useRouter()

const org = ref<Organization>()
const isExpansionPanelOpen = ref(false)
const tab = ref(0)
const loading = ref(false)
const filesOrgImage = ref<FileList | null>(null)

const tabs = ref<OrganizationTabsInfo[]>([
  { id: 0, name: 'events' },
  { id: 1, name: 'members' },
  { id: 2, name: 'media' },
  { id: 3, name: 'about' },
])

const currentTab = computed(() => tabs.value[tab.value])

const setCurrentTab = (tabItem: OrganizationTabsInfo) => {
  if (route.query.tab === tabItem.name) {
    isExpansionPanelOpen.value = false
    return
  }
  router.push({
    name: route.name as string,
    params: { id: route.params.id },
    query: { tab: tabItem.name },
    hash: '#org-tabs',
  })
  tab.value = tabItem.id
  isExpansionPanelOpen.value = false
}

const initOrg = async () => {
  const orgData = await fetchOrganization(route.params.id)
  if (!orgData) {
    await store.dispatch('setIsNotFound', true)
    return
  }
  org.value = orgData
}

const initTab = () => {
  if (!route.query.tab) return
  const currentTabItem = tabs.value.find((tabItem) => tabItem.name === route.query.tab)
  if (currentTabItem) tab.value = currentTabItem.id
}

const changeImage = async (event: Event) => {
  if (!org.value || !org.value.canEdit) return
  const input = event.target as HTMLInputElement
  filesOrgImage.value = input.files
  if (!filesOrgImage.value || !filesOrgImage.value.length) return

  const imageId = await saveImage(filesOrgImage.value[0])
  if (!imageId) return

  org.value.image = imageId
  await updateOrganization(org.value)
}

onMounted(async () => {
  await initOrg()
  initTab()
  loading.value = false
})
</script>

<template>
  <section class="org">
    <div
      v-if="org"
      class="org-container"
    >
      <div class="information">
        <v-img
          :src="getOrgImage(org.image, 1000)"
          alt="Organization image"
          class="image"
        >
          <label
            v-if="org.canEdit"
            aria-label="Edit organization image"
            class="image-edit"
            for="orgImageInput"
          >
            <v-icon>
              {{ mdiImageOutline }}
            </v-icon>
            <input
              id="orgImageInput"
              accept="image/png, image/jpeg"
              style="display: none"
              type="file"
              @change="changeImage"
            />
            Edit
          </label>
        </v-img>
        <div class="main">
          <div class="stats">
            <h2 class="org-name">
              {{ org.name }}
            </h2>
            <div class="stat">
              <span class="stat-icon stat-icon--members" />
              <span class="stat-name">Members</span>
              <div class="stat-val">
                {{ org.totalMembers }}
              </div>
            </div>

            <div class="stat">
              <span class="stat-icon stat-icon--events" />
              <span class="stat-name">Events thrown</span>
              <div class="stat-val">
                {{ org.totalEvents }}
              </div>
            </div>

            <div class="stat">
              <span class="stat-icon stat-icon--points" />
              <span class="stat-name">Points earned</span>
              <div class="stat-val">
                {{ org.totalPoints }}
              </div>
            </div>
          </div>

          <div class="socials">
            <h4 class="socials-title">Follow and connect</h4>
            <div class="list">
              <a
                v-if="org.socials.facebook"
                :href="`https://www.facebook.com/${org.socials.facebook}`"
                aria-label="Facebook"
                class="social"
              >
                <v-icon>{{ mdiFacebook }}</v-icon>
              </a>

              <a
                v-if="org.socials.instagram"
                :href="`https://www.instagram.com/${org.socials.instagram}`"
                aria-label="Instagram"
                class="social"
              >
                <v-icon>{{ mdiInstagram }}</v-icon>
              </a>

              <a
                v-if="org.socials.twitter"
                :href="`https://www.twitter.com/${org.socials.twitter}`"
                aria-label="Twitter"
                class="social"
              >
                <v-icon>{{ mdiTwitter }}</v-icon>
              </a>

              <a
                v-if="org.socials.youtube"
                :href="`https://www.youtube.com/channel/${org.socials.youtube}`"
                aria-label="YouTube"
                class="social"
              >
                <v-icon>{{ mdiYoutube }}</v-icon>
              </a>

              <a
                v-if="org.socials.website"
                :href="`https://${org.socials.website}`"
                aria-label="Website"
                class="social"
              >
                <v-icon>{{ mdiLink }}</v-icon>
              </a>
            </div>
          </div>

          <v-btn
            v-if="org.canEdit"
            :block="$vuetify.breakpoint.smAndDown"
            :height="$vuetify.breakpoint.smAndDown ? 32 : 52"
            :to="{
              name:
                $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                  ? 'OrganizationSettings'
                  : 'OrganizationSettingsGeneral',
              params: { id: org.id },
            }"
            class="options"
            color="var(--color-blue-800)"
            dark
            width="154"
          >
            <span class="options-icon" />
            Settings
          </v-btn>
        </div>
      </div>

      <div
        id="org-tabs"
        class="org-tabs"
      >
        <v-tabs
          v-if="$vuetify.breakpoint.mdAndUp"
          v-model="tab"
          background-color="var(--color-grey-300)"
          grow
        >
          <v-tab
            v-for="tabItem in tabs"
            :key="tabItem.id"
            :ripple="false"
            class="tab"
            @click="setCurrentTab(tabItem)"
          >
            {{ tabItem.name }}
          </v-tab>
        </v-tabs>
        <v-expansion-panels
          v-else
          v-model="isExpansionPanelOpen"
          class="expansion-panels"
          flat
        >
          <v-expansion-panel
            active-class="expansion-panel--active"
            class="expansion-panel"
          >
            <v-expansion-panel-header
              class="expansion-panel-header"
              color="var(--color-dark-800)"
            >
              {{ currentTab.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="expansion-panel-content"
              color="var(--color-grey-300)"
            >
              <v-list class="tabs-list">
                <v-list-item
                  v-for="tabItem in tabs"
                  :key="tabItem.id"
                  :class="{ 'tabs-list-item--active': tabItem.id === currentTab.id }"
                  class="tabs-list-item"
                  @click="setCurrentTab(tabItem)"
                >
                  <span class="tabs-list-item--name">{{ tabItem.name }}</span>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div
        v-if="!loading"
        class="tab-items"
      >
        <v-tabs-items v-model="tab">
          <v-tab-item class="item">
            <OrganizationEvents :org="org" />
          </v-tab-item>
          <v-tab-item class="item">
            <OrganizationMembers :org="org" />
          </v-tab-item>
          <v-tab-item class="item">
            <OrganizationMedia :org="org" />
          </v-tab-item>
          <v-tab-item class="item">
            <OrganizationAbout :org="org" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.org .org-tabs .v-tabs-slider-wrapper {
  display: none;
}

.org .org-tabs .tab.v-tab.v-tab--active {
  background-color: var(--color-dark-900);
}

.org .v-window__container {
  background-color: var(--color-grey-700);
}

.org .v-window__container--active {
  background-color: var(--color-grey-700);
}

.org {
  margin-top: 80px;
  background-color: var(--color-grey-700);
}

.org-container {
  width: 100%;
  max-width: 880px;
  margin: 40px auto 0;

  @media (max-width: $screen-md) {
    margin-top: 20px;
    padding: 0 20px;
  }
}

.information {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 757px;

  .image {
    position: relative;
    width: 100%;
    max-width: 420px;
    height: 100%;
    border-radius: var(--border-radius-rounded) !important;
    aspect-ratio: 1 / 1;

    .image-edit {
      position: absolute;
      top: 12px;
      left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 78px;
      height: 32px;
      padding: 0 16px;
      font-weight: var(--font-weight-bold);
      font-size: 0.875rem;
      text-transform: uppercase;
      background-color: var(--color-white);
      border-radius: 4px;
      box-shadow:
        0 3px 1px -2px rgb(0 0 0 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
      cursor: pointer;
      transition: background-color 0.2s cubic-bezier(0.4, 0, 0.6, 1);

      &:hover,
      &:focus {
        background-color: #f5f5f5;
      }
    }
  }

  @media (max-width: $screen-md) {
    gap: 16px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .org-name {
    @include font-h2;

    margin-bottom: 4px;
    color: var(--color-dark-800);
  }

  .stats {
    margin-bottom: 4px;
  }

  .stat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid var(--color-grey-600);

    &:last-child {
      border-bottom: none;
    }

    .stat-name {
      color: var(--color-dark-800);
    }

    .stat-val {
      margin-left: auto;
    }
  }

  .stat-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 12px;

    &--members {
      background: url('/img/ic_Avatar.svg') no-repeat center;
    }

    &--events {
      background: url('/img/ic_Calendar.svg') no-repeat center;
    }

    &--points {
      background: url('/img/ic_Ribbon.svg') no-repeat center;
    }
  }
}

.socials {
  .socials-title {
    margin-bottom: 12px;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-bold);
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.social {
  width: 40px;
  height: 40px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-rounded);
  cursor: pointer;

  .v-icon {
    display: flex;
    height: 100%;
    margin: auto;
    color: var(--color-black);
  }
}

.options {
  margin-top: auto;
  font-weight: var(--font-weight-bold);

  &-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url('/img/ic_Settings_White.svg') no-repeat center;

    @media (max-width: $screen-md) {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
    }
  }

  @media (max-width: $screen-md) {
    order: -1;
    margin-bottom: 16px;
  }
}

.org-tabs {
  margin-top: 40px;

  .tab {
    color: var(--color-white) !important;
  }

  @media (max-width: $screen-md) {
    margin-top: 20px;
  }
}

.tab-items {
  .item {
    min-height: 500px;
    padding-top: 40px;
    background-color: var(--color-grey-700);

    @media (max-width: $screen-md) {
      padding-top: 20px;
    }
  }
}

.expansion-panels {
  overflow: hidden;
  border-radius: 6px;
}

.expansion-panel-header {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  max-height: 52px;
  padding: 14px;
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;

  &::v-deep .v-expansion-panel-header__icon {
    margin-left: 0;

    .v-icon {
      color: var(--color-white) !important;

      &::before {
        @include font-h3;
      }
    }
  }
}

.expansion-panel--active .expansion-panel-header {
  min-height: 52px;
}

.expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}

.tabs-list {
  padding: 0;
  background-color: transparent !important;
}

.tabs-list-item {
  justify-content: center;
  height: 52px;
  padding: 0;

  &--active {
    display: none;
  }

  &--name {
    color: var(--color-white) !important;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }
}
</style>
