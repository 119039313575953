import { callPrivateApi } from '@/api/apiHelper'
import { initGetEventResp, initUpdateEventReq } from '@/api/adapter'

export const updateEvent = async (event) => {
  const data = await callPrivateApi('post', `/events/${event.id}`, initUpdateEventReq(event))
  return initGetEventResp(data)
}

export const addRole = async (eventId, userId, role) => {
  const data = await callPrivateApi('post', `/events/${eventId}/role`, { userId, role })
  return initGetEventResp(data)
}

export const removeRole = async (eventId, userId, role) => {
  const data = await callPrivateApi('delete', `/events/${eventId}/role`, { data: { userId, role } })
  return initGetEventResp(data)
}

export const addBattle = async (eventId) => callPrivateApi('post', `/events/${eventId}/add-battle`)

export const publishEvent = async (eventId) => callPrivateApi('post', `/events/${eventId}/publish`)

export const deleteEvent = async (eventId) => callPrivateApi('post', `/events/${eventId}/delete`)

export const createTicketPayment = async (eventId, tier) =>
  callPrivateApi('post', `/events/${eventId}/buy-ticket/${tier}`)

export const checkinCurrentUser = async (eventId) => {
  const data = await callPrivateApi('post', `/events/${eventId}/checkin`)
  return initGetEventResp(data)
}

export const checkInUser = async (eventId, userId) => {
  const data = await callPrivateApi('post', `/events/${eventId}/checkin/${userId}`)
  return initGetEventResp(data)
}
