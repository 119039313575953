import { callPrivateApi } from '@/api/apiHelper'

import { initBattleResp, initUpdateBattleReq } from '@/api/adapter'

export const getBattle = async (battleId) => {
  const data = await callPrivateApi('get', `/battles/${battleId}`)
  return initBattleResp(data)
}

export const update = async (battle) => callPrivateApi('put', `/battles/${battle.id}`, initUpdateBattleReq(battle))

export const setWinners = async (battleId, winners) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/winners`, winners)
  return initBattleResp(data)
}

export const deleteBattle = async (battleId) => callPrivateApi('delete', `/battles/${battleId}`)

export const registerDancer = async (battleId) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/dancer`)
  return initBattleResp(data)
}

export const unregisterDancer = async (battleId) => {
  const data = await callPrivateApi('delete', `/battles/${battleId}/dancer`)
  return initBattleResp(data)
}

export const addRole = async (battleId, userId, role) =>
  callPrivateApi('post', `/battles/${battleId}/role`, { userId, role })

export const removeRole = async (battleId, userId, role) =>
  callPrivateApi('delete', `/battles/${battleId}/role`, { data: { userId, role } })

export const signAgreement = async (battleId) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/sign-agreement`)
  return initBattleResp(data)
}

export const signWaiver = async (battleId) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/sign-waiver`)
  return initBattleResp(data)
}

export const getBrackets = async (battleId) => callPrivateApi('get', `/battles/${battleId}/brackets`)

export const seedParticipants = async (battleId, payload) =>
  callPrivateApi('post', `/battles/${battleId}/seed`, payload)
