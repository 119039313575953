<template>
  <div class="card">
    <h3 class="subtitle mb-5">Register</h3>
    <div>
      <v-chip
        v-if="getUserDB"
        class="chip my-2 pr-6"
        color="transparent"
        large
        pill
      >
        <v-avatar left>
          <v-img :src="getAvatar(getUserDB.avatar, 200)" />
        </v-avatar>
        <span>{{ getUserDB.dancerName }}</span>
      </v-chip>
    </div>

    <template v-if="battle.permissions.registered">
      <v-btn
        v-if="battle.canUnregister"
        :loading="loading"
        class="unregister-btn"
        color="var(--color-blue-800)"
        outlined
        @click="handleBattleUnregisterModal"
      >
        unregister
      </v-btn>

      <div class="check-in-section">
        <BKCheckinButton
          :event="event"
          @checkIn="$emit('reload:battle')"
          @purchase="$emit('reload:event')"
          @update:event="$emit('update:event', $event)"
        />
      </div>
    </template>

    <template v-else>
      <Agreements
        v-if="battle.permissions.showAgreement || battle.permissions.showWaiver"
        :battle="battle"
        @update:battle="$emit('update:battle', $event)"
        @reload:battle="$emit('reload:battle')"
      />

      <v-btn
        :disabled="!battle.permissions.registerButtonEnabled"
        :loading="loading"
        class="register-btn"
        color="var(--color-blue-800)"
        @click="register"
      >
        Register
      </v-btn>
    </template>
    <BattleUnregister
      v-if="isUnregisterModalOpen"
      :battle="battle"
      :is-show-modal.sync="isUnregisterModalOpen"
      @update:battle="$emit('update:battle', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
import Images from '@/mixins/image'
import Agreements from '@/components/AgreementsPage.vue'
import { BKCheckinButton } from '@/features/checkin'
import BattleUnregister from '@/components/Battle/BattleUnregister.vue'

export default {
  name: 'BattleRegister',
  components: {
    Agreements,
    BKCheckinButton,
    BattleUnregister,
  },
  mixins: [Images],
  props: {
    event: {
      type: Object,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      isUnregisterModalOpen: false,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
  },
  methods: {
    async register() {
      if (!this.getUserDB) {
        this.$router.push({ name: 'Login' })
        return
      }
      this.loading = true
      const updatedBattle = await api.battles.registerDancer(this.battle.id)
      if (updatedBattle) this.$emit('update:battle', updatedBattle)
      this.loading = false
    },
    handleBattleUnregisterModal() {
      this.isUnregisterModalOpen = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.card {
  position: relative;
  margin-bottom: 24px;
  background-color: var(--color-grey-100) !important;
}

.subtitle {
  @include font-h3;

  font-weight: var(--font-weight-bold);
}

.unregister-btn,
.register-btn {
  display: block;
  width: 330px;
  height: 52px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  border: 2px solid;

  @media (max-width: $screen-sm) {
    width: 100%;
  }
}

.check-in-section {
  width: 330px;

  @media (max-width: $screen-sm) {
    width: 100%;
  }
}

.register-btn {
  color: var(--color-white) !important;
}
</style>
