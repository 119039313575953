<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { mdiCog } from '@mdi/js'
import ProfileEventsTab from '@/pages/ProfilePage/ui/ProfileEventsTab.vue'
import ProfileBadgesTab from '@/pages/ProfilePage/ui/ProfileBadgesTab.vue'
import ProfilePlayerCard from '@/pages/ProfilePage/ui/ProfilePlayerCard.vue'
import ProfileSocials from '@/pages/ProfilePage/ui/ProfileSocials.vue'
import ProfileStats from '@/pages/ProfilePage/ui/ProfileStats.vue'
import { PatreonLabel } from '@/shared/ui/labels'
import { userApi, UserDB } from '@/enitites/user'
import { useImage } from '@/shared/composables/image'

const { getImageById, getFlag } = useImage()
const route = useRoute()
const tabs = ref<number | null>(null)
const user = ref<UserDB | null>(null)

const age = computed(() => {
  if (!user.value?.id || !user.value?.birthday) return null
  const birthday = new Date(user.value.birthday)
  const ageMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
})

const userId = computed(() => route.params.id || 0)

const fetchUser = async () => {
  const fetchedUser = await userApi.getUser(Number(userId.value))
  if (!fetchedUser) {
    store.commit('setIsNotFound', true)
    return
  }
  user.value = fetchedUser
}

watch(userId, fetchUser)

onMounted(() => {
  fetchUser()
})
</script>

<template>
  <section
    v-if="user"
    class="profile"
  >
    <v-container>
      <div class="person-info-row">
        <div class="person-info">
          <div class="avatar-row">
            <v-avatar class="avatar">
              <v-img
                :src="getImageById(user.avatar, 400, 400, 'avatar')"
                alt="Person photo"
              />
            </v-avatar>
            <v-avatar
              class="flag"
              size="auto"
            >
              <v-img
                :src="getFlag(user.country)"
                alt="Flag"
              />
            </v-avatar>
          </div>

          <div class="person-contacts">
            <p class="name">{{ user.dancerName }}</p>
            <span>{{ user.aEmail }}</span>
            <span class="full-name-age"> {{ user.fullName }} <span v-if="age">,</span> {{ age }} </span>
            <span class="person-from">
              {{ user.city }} {{ user.state }}<span v-if="user.country">,</span> {{ user.country }}
            </span>
            <PatreonLabel
              v-if="user.isPatron"
              style="margin-top: 8px"
            />
            <ProfileSocials
              v-if="user.socials"
              :socials="user.socials"
            />
            <v-btn
              v-if="user.canEdit"
              :to="{ path: '/profile-setting', query: { tabName: 'SettingsSocial' } }"
              class="add-social-link px-1"
              color="#1976d2"
              text
            >
              Add your social media
            </v-btn>

            <v-btn
              v-if="user.canEdit"
              :to="{ path: '/profile-setting' }"
              block
              class="btn"
              color="var(--color-blue-800)"
              dark
              tile
            >
              SETTINGS
              <v-icon
                class="btn-icon"
                dark
              >
                {{ mdiCog }}
              </v-icon>
            </v-btn>
          </div>
        </div>
        <ProfileStats :user="user" />
      </div>

      <v-tabs
        v-model="tabs"
        background-color="transparent"
        class="links links-row"
        slider-color="black"
      >
        <v-tab class="link">Events</v-tab>
        <v-tab class="link">Badges</v-tab>
        <v-tab class="link">Player Card</v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tabs"
        class="tabs-content"
      >
        <v-tab-item>
          <ProfileEventsTab :user="user" />
        </v-tab-item>
        <v-tab-item>
          <ProfileBadgesTab :events-attended="user.eventsAttended" />
        </v-tab-item>
        <v-tab-item>
          <ProfilePlayerCard :user="user" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </section>
</template>

<style lang="scss" scoped>
@import '~@/assets/fonts/style.css';
@import '@/assets/style/mixins';

.profile {
  width: 100%;
  margin: 56px auto 0;
  padding: 100px 10px 420px;
  background-color: var(--color-grey-150);

  .person-info-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ddd;
  }

  .tabs-content {
    background-color: transparent;
  }
}

.person-info {
  display: flex;

  .person-contacts {
    margin-left: 20px;
    padding-top: 10px;
  }

  .avatar-row {
    position: relative;
  }

  .avatar {
    width: 184px !important;
    height: 184px !important;
  }

  .flag {
    position: absolute;
    top: -10px;
    right: -12px;
    width: 64px !important;
    height: 64px !important;
    border: 4px solid white;
    border-radius: 50px;
  }

  .name {
    @include font-h3;

    margin-bottom: 0;
    font-weight: var(--font-weight-bold);
  }

  .full-name-age,
  .person-from {
    display: block;
    width: 100%;
  }

  .add-social-link {
    margin: 0 0 16px;
    text-transform: none;
  }

  .btn {
    max-width: 180px;
    background-color: #041e42;
  }

  .btn-icon {
    margin-left: 30px;
  }
}

.links-row {
  margin: 0 -26px 56px;

  .link {
    @include font-body1;

    margin: 0 16px;
    padding: 0 16px;
    color: black !important;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .profile {
    padding-top: 60px;
  }

  .links-row {
    margin: 0 0 56px;

    .link {
      width: 100%;
      min-width: 40px;
      margin: 0;
      padding: 0;
    }
  }
}

@media (max-width: 400px) {
  .person-info {
    .person-contacts {
      padding-top: 0;
    }

    .add-social-link {
      margin: 3px 0;
    }

    .avatar {
      width: 96px !important;
      height: 96px !important;
    }

    .flag {
      top: -5px;
      right: 2px;
      width: 26px !important;
      height: 26px !important;
      border: 2px solid white;
    }
  }

  .links-row {
    .link {
      @include font-body2;
    }
  }
}
</style>
