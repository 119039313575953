export function useMap() {
  function visiblePercentOfElement(el: HTMLElement) {
    const rect = el.getBoundingClientRect()

    const viewportWidth = window.innerWidth || document.documentElement.clientWidth

    const visibleWidth = Math.min(rect.right, viewportWidth) - Math.max(rect.left, 0)
    const elementWidth = el.offsetWidth

    return (visibleWidth / elementWidth) * 100
  }

  return {
    visiblePercentOfElement,
  }
}
