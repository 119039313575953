<template>
  <div class="battle-detail">
    <div
      v-if="battle.judges.length"
      class="judges"
    >
      <span class="info-title"> Judges </span>
      <article
        v-for="judge in battle.judges"
        :key="judge.id"
      >
        <router-link
          :to="{ name: 'Profile', params: { id: judge.id } }"
          class="user-link"
        >
          <v-avatar
            class="d-inline-block mr-1"
            size="32"
          >
            <v-img :src="getAvatar(judge.avatar, 32)" />
          </v-avatar>
          <span>{{ judge.dancerName }}</span>
        </router-link>
      </article>
    </div>

    <div
      v-if="battle.description || battle.prizesDesc"
      class="description-wrapper"
    >
      <span class="info-title"> Prize purse and more </span>
      <!--      eslint-disable-next-line-->
      <div
        v-if="battle.description"
        class="description"
        v-html="$sanitize(battle.description)"
      />
      <span
        v-if="battle.prizesDesc"
        class="info-battle"
      >
        {{ battle.prizesDesc }}
      </span>
    </div>

    <div
      v-if="battle.prizes.length"
      class="places"
    >
      <div
        v-for="(place, i) in battle.prizes"
        :key="i"
        class="place-card"
      >
        <div
          v-if="i === 0"
          class="top-place"
        >
          <span class="place-text first-place"> 1st </span>
          <v-img
            src="/img/1st-place.svg"
            width="38"
          />
        </div>
        <div
          v-else-if="i === 1"
          class="top-place"
        >
          <span class="place-text"> 2nd </span>
          <v-img
            src="/img/2nd-place.svg"
            width="38"
          />
        </div>
        <!--      eslint-disable-next-line-->
        <span
          v-else
          class="place-text"
          v-html="place.title.replace(' ', '<br>')"
        />
        <span class="prize"> ${{ place.amount }} </span>
      </div>
    </div>

    <OrganizationLabel
      v-if="battle.orgChip && battle.orgChip.id"
      :chip="battle.orgChip"
      class="org-label"
    />
    <BKButton
      v-if="$route.name === 'Prelims' && !isThereGroups && battle.canEdit"
      :to="{ name: 'CreatingPrelimsGroups' }"
      class="create-group-btn"
      outlined
    >
      <img
        alt="Groups"
        src="/img/ic_Groups.svg"
      />
      Create groups
    </BKButton>
  </div>
</template>

<script>
import Images from '@/mixins/image'

import { OrganizationLabel } from '@/shared/ui/labels'

export default {
  name: 'BattleDetails',
  components: {
    OrganizationLabel,
  },
  mixins: [Images],
  props: {
    battle: {
      type: Object,
      required: true,
    },
    isInfoOpened: {
      type: Boolean,
      default: false,
    },
    isThereGroups: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.battle-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;

  .org-label {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }

  .judges {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .description-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .places {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 13px;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;

    @media (min-width: $screen-sm) {
      grid-row: 1/3;
      grid-column: 2/3;
      align-self: start;
    }
  }

  .place-card {
    display: flex;
    flex: 1 1 40%;
    flex-direction: column;
    gap: 9px;
    min-height: 131px;
    padding: 12px;
    border: 1px solid var(--color-grey-600);
    border-radius: var(--border-radius-rounded);
  }

  .top-place {
    display: flex;
    flex-direction: column;
    gap: 9px;
    align-items: center;
  }

  .place-text {
    margin: auto;
    color: var(--color-grey-500);
    font-weight: var(--font-weight-bold);
    text-align: center;

    &.first-place {
      color: var(--color-gold-900);
    }
  }

  .info-title {
    color: var(--color-dark-800);
    font-weight: bold;
  }

  .info-battle {
    width: 100%;
    color: var(--color-dark-800);
  }

  .prize {
    @include font-h4;

    color: var(--color-dark-800);
    font-weight: var(--font-weight-bold);
    text-align: center;
  }

  .user-link {
    color: var(--color-black);
  }

  .create-group-btn {
    & ::v-deep .button-text {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  @media (min-width: $screen-sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
<style lang="scss">
.description {
  ul,
  li {
    list-style-type: inherit;
  }

  p {
    margin: 0 !important;
  }
}
</style>
