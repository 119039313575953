<script lang="ts" setup>
import { useImage } from '@/shared/composables/image'
import BKImage from '@/components/AppShared/BKImage.vue'
import { EventDB } from '@/enitites/event'
import { EventMapDB } from '@/enitites/map'
import EventCompactCard from '@/shared/ui/cards/EventCompactCard.vue'

const { getImageById } = useImage()

interface IProps {
  event: EventDB | EventMapDB
}

const props = defineProps<IProps>()
const srcImg = getImageById(props.event.image, 190, 190, 'event')

function formattedDate(date: Date) {
  return new Date(date).toLocaleDateString('en-US')
}
</script>

<template>
  <router-link
    :aria-label="`Link to ${event.title}`"
    :to="{ name: 'Event', params: { id: event.id } }"
    class="event-preview-compact-card"
  >
    <EventCompactCard
      v-if="event"
      class="card"
    >
      <template #background>
        <img
          :src="srcImg"
          alt=""
          aria-hidden="true"
          class="background-img"
        />
      </template>
      <template #content>
        <div class="content">
          <div class="img-container">
            <BKImage
              :src="srcImg"
              alt=""
              class="event-img"
              height="auto"
              width="85"
            />
          </div>
          <div class="data">
            <span v-if="event.title">{{ event.title }}</span>
            <span v-if="event.dateStart">{{ formattedDate(event.dateStart) }}</span>
            <span v-if="event.locationAddress">{{ event.locationAddress }}</span>
          </div>
        </div>
      </template>
    </EventCompactCard>
  </router-link>
</template>

<style lang="scss" scoped>
.event-preview-compact-card {
  width: 100%;

  .card {
    position: relative;
  }

  .content {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 12px;
    align-items: center;
    color: var(--color-white);

    .data {
      display: grid;
      gap: 4px;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .event-img {
      border-radius: 6px;
    }

    .img-container {
      width: 85px;
      height: 85px;
    }
  }

  .background-img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(60px);
  }
}
</style>
