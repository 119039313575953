<template>
  <section>
    <BKLoader v-if="loading" />
    <template v-else>
      <Battle
        v-if="isOneVsOneBattle"
        :battle.sync="battle"
        :event.sync="event"
        @reload:battle="initBattle"
        @reload:event="initEvent"
      />
      <BattleCrew
        v-else
        :battle.sync="battle"
        :event.sync="event"
        @reload:battle="initBattle"
        @reload:event="initEvent"
      />
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '@/api'
import Battle from '@/components/Battle/BKBattle.vue'
import BattleCrew from '@/components/Battle/Crew/BattleCrew.vue'
import BKLoader from '@/components/BKLoader.vue'
import { eventApi } from '@/enitites/event'

export default {
  components: {
    BKLoader,
    Battle,
    BattleCrew,
  },
  data() {
    return {
      loading: true,
      event: null,
      battle: null,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    isOneVsOneBattle() {
      return this.battle.crewSize === 1
    },
  },
  async mounted() {
    this.loading = true

    await this.initBattle()
    await this.initEvent()

    this.loading = false
  },
  methods: {
    ...mapActions(['setIsNotFound']),
    async initEvent() {
      if (!this.battle) return
      const event = await eventApi.getEvent(this.battle.eventId)
      if (!event) {
        this.setIsNotFound(!this.event)
        return
      }

      this.event = event
    },
    async initBattle() {
      const battle = await api.battles.getBattle(this.$route.params.id)
      if (!battle) {
        this.setIsNotFound(!this.event)
        return
      }

      this.battle = battle
    },
  },
}
</script>
