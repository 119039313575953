<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from 'vue'
import { useErrorHandlers } from '@/composables/errorHandlers'
import { useImage } from '@/shared/composables/image'
import BKImage from '@/components/AppShared/BKImage.vue'
import GmapCluster from 'vue2-google-maps/src/components/cluster'
import { useVuetify } from '@/services/useVuetify'
import {
  EventMapDB,
  EventMapPeriod,
  EventMapSearchParams,
  MapCornersCoordinates,
  MappedMarkerCoordinates,
  useMap,
} from '@/enitites/map'
import { useThrottleFn } from '@vueuse/core'
import { EventPreviewCompactCard } from '@/features/event-card'
import EventsListMap from './EventsListMap.vue'

const vuetify = useVuetify()
const isDesktopTablet = computed(() => vuetify.breakpoint.smAndUp)
const { getImageById } = useImage()
const { visiblePercentOfElement } = useMap()

defineProps<{
  searchParams: EventMapSearchParams
}>()

const emit = defineEmits<{
  (e: 'changed-tab', value: { tab: EventMapPeriod; sortBy: 'date-asc' | 'date-desc' }): void
}>()
const MAP_OPTIONS = {
  // new york coordinates
  INIT_COORDINATES: { lat: 40.7128, lng: -74.006 },
  SHOW_ALL_EVENTS_ZOOM: 1,
} as const

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const map = ref<google.maps.Map | null>(null)
const cluster = ref<google.maps.MarkerClusterer | null>(null)
const center = ref<{ lat: number; lng: number }>(MAP_OPTIONS.INIT_COORDINATES)
const visibleMapCoordinates = ref<MapCornersCoordinates>()

async function getVisibleMapCoordinates() {
  await map.value.$gmapApiPromiseLazy()
  const bounds = await map.value?.$mapObject?.getBounds()
  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()
  const se = new google.maps.LatLng(sw.lat(), ne.lng())
  const nw = new google.maps.LatLng(ne.lat(), sw.lng())

  visibleMapCoordinates.value = {
    nw: [nw.lat(), nw.lng()],
    ne: [ne.lat(), ne.lng()],
    se: [se.lat(), se.lng()],
    sw: [sw.lat(), sw.lng()],
  }
}

function saveMapCenterCoordinates() {
  sessionStorage.setItem('lat', String(center.value.lat))
  sessionStorage.setItem('lng', String(center.value.lng))
}

function onIdle() {
  if (!map.value) return
  center.value = map.value.$mapObject.getCenter().toJSON()
  saveMapCenterCoordinates()
  getVisibleMapCoordinates()
}

function setUserLocation(position: GeolocationPosition) {
  center.value = {
    lat: Number(sessionStorage.getItem('lat')) || position.coords.latitude,
    lng: Number(sessionStorage.getItem('lng')) || position.coords.longitude,
  }
}

const eventItems = ref<HTMLElement[] | null>(null)
const visibleEventId = ref(0)
const hoveredEventId = ref(0)
const MIN_VISIBLE_EVENT_PERCENT = 60
const visibleMarkers = ref<EventMapDB[]>([])

function checkElementInViewport() {
  if (!eventItems.value) return
  visibleMarkers.value.forEach((event, index: number) => {
    const element = eventItems.value?.[index]
    const visiblePercentage = visiblePercentOfElement(element as HTMLElement)
    if (visiblePercentage < MIN_VISIBLE_EVENT_PERCENT && event.id === visibleEventId.value) {
      visibleEventId.value = 0
      return
    }
    if (visiblePercentage >= MIN_VISIBLE_EVENT_PERCENT) {
      visibleEventId.value = event.id
    }
  })
}

function setVisibleMarkers(value: EventMapDB[]) {
  visibleMarkers.value = value
  nextTick(() => {
    checkElementInViewport()
  })
}

const CLUSTER_STYLES = [
  {
    textColor: 'var(--cluster-event-color)',
    url: '/img/ui-icons/selectedCluster.svg',
    height: 71,
    width: 76,
    textSize: 16,
  },
  {
    textColor: 'var(--cluster-event-color)',
    url: '/img/ui-icons/cluster.svg',
    height: 53,
    width: 58,
    textSize: 16,
  },
]

const mapOptions = {
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  gestureHandling: 'greedy',
}

const sortOptionsByTab = new Map([
  ['now', 'date-asc'],
  ['future', 'date-asc'],
  ['past', 'date-desc'],
])

function setMapZoom(tab: { name: EventMapPeriod; amount: number }) {
  emit('changed-tab', { tab: tab.name, sortBy: sortOptionsByTab.get(tab.name) as 'date-asc' | 'date-desc' })
  if (tab.amount) return
  map.value.$mapPromise.then((mapItem: { setZoom: (arg0: number) => void }) => {
    mapItem.setZoom(MAP_OPTIONS.SHOW_ALL_EVENTS_ZOOM)
  })
}

function isActiveMapEvent(id: number) {
  return (id === visibleEventId.value && !isDesktopTablet.value) || id === hoveredEventId.value
}

const throttledCheckElementInViewport = useThrottleFn(checkElementInViewport, 100)
const { geoErrorHandler } = useErrorHandlers()
const hoveredEvent = computed(() => visibleMarkers.value.find((event) => event.id === hoveredEventId.value))

function checkIsHoveredEventInCluster(mappedMarkers: MappedMarkerCoordinates[]) {
  return mappedMarkers.some(
    (mappedMarker: MappedMarkerCoordinates) =>
      mappedMarker.coordinates.lat === hoveredEvent.value?.coordinates?.lat &&
      mappedMarker.coordinates.lng === hoveredEvent.value?.coordinates?.lng
  )
}

const visibleEvent = computed(() => visibleMarkers.value.find((event) => event.id === visibleEventId.value))

function checkIsVisibleEventInCluster(mappedMarkers: MappedMarkerCoordinates[]) {
  return mappedMarkers.some(
    (mappedMarker: MappedMarkerCoordinates) =>
      mappedMarker.coordinates.lat === visibleEvent.value?.coordinates?.lat &&
      mappedMarker.coordinates.lng === visibleEvent.value?.coordinates?.lng
  )
}

function calculateClusters(markers: google.maps.Marker[]) {
  const mappedMarkers = markers.map((marker: google.maps.Marker) => ({
    coordinates: {
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    },
  }))
  const hoveredEventIsInCluster = checkIsHoveredEventInCluster(mappedMarkers)
  const visibleEventIsInCluster = checkIsVisibleEventInCluster(mappedMarkers)

  if (visibleEventIsInCluster || hoveredEventIsInCluster) {
    return {
      index: 1,
      text: markers.length,
    }
  }
  return {
    index: 2,
    text: markers.length,
  }
}

onMounted(() => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(setUserLocation, geoErrorHandler, { enableHighAccuracy: true })
  }
  checkElementInViewport()
})
</script>

<template>
  <section
    aria-label="Events map view mode"
    class="map-view"
  >
    <EventsListMap
      :center-coordinates="center"
      :search-params="searchParams"
      :visible-map-coordinates="visibleMapCoordinates"
      class="events"
      @events="setVisibleMarkers"
      @changed-tab="setMapZoom($event)"
      @hovered-event="hoveredEventId = $event.id"
      @un-hovered-event="hoveredEventId = 0"
    />
    <GmapMap
      ref="map"
      :center="center"
      :options="mapOptions"
      :zoom="5"
      class="map"
      style="height: 100%"
      @idle="onIdle"
    >
      <ul
        class="map-event-list"
        @scroll="throttledCheckElementInViewport"
      >
        <li
          v-for="event in visibleMarkers"
          :key="event.id"
          ref="eventItems"
        >
          <EventPreviewCompactCard
            :event="event"
            class="preview-card"
          />
        </li>
      </ul>
      <GmapCluster
        ref="cluster"
        :calculator="calculateClusters"
        :grid-size="20"
        :styles="CLUSTER_STYLES"
        enable-retina-icons
        zoom-on-click
      >
        <template v-for="event in visibleMarkers">
          <GmapMarker
            v-if="event.coordinates"
            :key="event.id"
            :clickable="true"
            :draggable="false"
            :opacity="0"
            :position="event.coordinates"
          >
            <GmapInfoWindow
              :options="{ pixelOffset: 60, maxWidth: 60, disableAutoPan: true }"
              :position="event.coordinates"
            >
              <router-link
                :class="['link', { active: isActiveMapEvent(event.id) }]"
                :to="{ name: 'Event', params: { id: event.id } }"
              >
                <BKImage
                  :src="getImageById(event.image, 136, 136, 'event')"
                  class="img"
                  height="42"
                  width="42"
                />
              </router-link>
            </GmapInfoWindow>
          </GmapMarker>
        </template>
      </GmapCluster>
    </GmapMap>
  </section>
</template>
<style lang="scss" scoped>
@import '@/assets/style/mixins';

.map-view {
  display: grid;
  grid-template-columns: 336px 1fr;
  gap: 20px;
  height: calc(100vh - 205px);
  height: calc(100dvh - 205px);

  .preview-card {
    width: 296px;
  }

  @media (max-width: 768px) {
    display: block;
  }

  .events {
    @media (max-width: 768px) {
      @include visually-hidden;
    }
  }

  .link {
    display: flex;
    padding: 0;
    background-color: #fff;
    border-radius: 4px;
    transition: padding 0.2s ease-in-out;

    .img {
      border-radius: 4px;
      transition:
        width 0.2s ease-in-out,
        height 0.2s ease-in-out;
    }

    &:is(:hover, &.active) {
      padding: 4px;

      .img {
        width: 52px;
        height: 52px;
      }
    }
  }

  ::v-deep .cluster {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .map {
    ::v-deep {
      .vue-map-hidden {
        display: flex;
        align-items: flex-end;
        height: 100%;
      }

      .map-event-list {
        display: none;
        gap: 10px;
        align-items: flex-end;
        padding: 0 16px;
        overflow: auto;

        .event-preview-card {
          width: 296px;
        }

        @media (max-width: 768px) {
          display: flex;
          padding: 30px 16px;
        }
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.gm-ui-hover-effect {
  display: none !important;
}

.gm-style {
  .gm-style-iw-c {
    padding: 0 !important;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;

    &:is(:focus-visible, :focus, :focus-within) {
      outline: none;
    }
  }

  .gm-style-iw-ch {
    padding: 0 !important;
  }

  .gm-style-iw-d {
    padding: 0;
    overflow: auto !important;
  }

  .gm-style-iw-tc::after {
    display: none;
  }
}
</style>
