// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { callPrivateApi } from '@/api/apiHelper'
import { MediaDB, MediaParams } from '@/enitites/media/model/types'

export const mediaApi = {
  async getMedia({ eventId: e, orgId: o }: { eventId: number; orgId: number }): Promise<MediaDB[]> {
    return callPrivateApi<MediaDB[]>('get', '/media', { params: { e, o } })
  },
  async addMedia(media: MediaParams): Promise<MediaDB[]> {
    return callPrivateApi<MediaDB[]>('post', '/media', media)
  },
  async removeMedia(id: number): Promise<MediaDB[]> {
    return callPrivateApi<MediaDB[]>('delete', `/media/${id}`)
  },
}
