<template>
  <div>
    <div
      v-if="event.permissions.checkedIn || event.permissions.showCheckin"
      :class="{ checked: event.permissions.checkedIn }"
      class="checkin-container"
    >
      <span
        v-if="event.permissions.checkedIn"
        class="checked-text"
      >
        <v-icon class="mr-2">{{ mdiCheck }}</v-icon>
        Checked in
      </span>

      <BKButton
        v-else
        :disabled="event.period !== 'now'"
        :loading="loading"
        class="checkin-button"
        color="var(--color-green)"
        height="48"
        white-text
        @click="checkinUser"
      >
        Check in at event
      </BKButton>
      <!--      eslint-disable vuejs-accessibility/click-events-have-key-events-->
      <span
        v-if="event.permissions.hasTicket"
        class="view-ticket-btn"
        @click="isShowTicketModal = true"
      >
        View ticket
      </span>
      <!--      eslint-утable vuejs-accessibility/click-events-have-key-events-->
    </div>

    <EventTicket
      :event="event"
      :is-show-modal.sync="isShowTicketModal"
      :permissions="event.permissions"
    />
  </div>
</template>

<script lang="ts" setup>
import { mdiCheck } from '@mdi/js'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { eventApi, EventDB } from '@/enitites/event'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import EventTicket from './EventTicket.vue'

const props = defineProps<{
  event: EventDB
}>()

const loading = ref(false)
const isShowTicketModal = ref(false)

const getUserDB = computed(() => store.getters.getUserDB)
const emit = defineEmits<{
  (e: 'update:event', value: EventDB): void
  (e: 'checkIn'): void
  (e: 'purchase'): void
}>()

const router = useRouter()

function redirectUnauthorized() {
  if (getUserDB.value) return
  router.push({ name: 'Signup' })
}

async function checkinUser() {
  redirectUnauthorized()
  loading.value = true
  const updatedEvent = await eventApi.checkinCurrentUser(props.event.id)
  loading.value = false

  if (updatedEvent) {
    emit('checkIn')
    emit('update:event', updatedEvent)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.purchase-word {
  color: var(--color-green);
  cursor: pointer;
}

.checkin-button,
.purchase-button {
  color: white !important;
}

.checkin-container {
  &.checked {
    display: flex;
    justify-content: space-between;

    .view-ticket-btn {
      display: block;
      margin: 10px 0 0;
      color: var(--color-green);
      font-weight: 700;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .checked-text,
  .checked-text .v-icon {
    color: var(--color-green);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }
}
</style>
