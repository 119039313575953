<script lang="ts" setup>
import { computed, defineEmits, defineProps } from 'vue'
import { addUserWithRole, removeUserWithRole } from '@/enitites/organization/api/endpoints'

import BKUserSelect from '@/components/BKUserSelect.vue'
import { Organization, OrganizationUser, UserRole } from '@/modules/Organizations/types'

const props = defineProps<{
  org: Organization
}>()
const emit = defineEmits<{
  (e: 'back'): void
  (e: 'end'): void
}>()

const excludedUsersIds = computed(() => props.org.promoters.map((a) => a.id))

const addPromoter = async (user: OrganizationUser, role: UserRole) => {
  const resp = await addUserWithRole(props.org.id, user.id, role)
  if (resp) props.org.promoters.push(user)
}

const removePromoter = async (user: OrganizationUser, role: UserRole) => {
  const resp = await removeUserWithRole(props.org.id, user.id, role)
  if (resp) {
    props.org.promoters = props.org.promoters.filter((promoter) => promoter.id !== user.id)
  }
}
</script>

<template>
  <v-card class="org-promoters">
    <div class="promoters-wrap">
      <p class="promoters-title">Organization promoters</p>
      <!--eslint-disable  vuejs-accessibility/aria-role-->
      <BKUserSelect
        :exclude="excludedUsersIds"
        :select-user-chip="org.promoters"
        label-text="Select promoters"
        role="promoter"
        @removeUser="removePromoter"
        @selectUser="addPromoter"
      />
      <!--eslint-disable  vuejs-accessibility/aria-role-->
      <v-btn
        class="btn-edit white--text"
        color="var(--color-blue-800)"
        tile
        @click="emit('back')"
      >
        Back
      </v-btn>
      <v-btn
        v-if="org.status === 'draft'"
        class="btn-edit white--text"
        color="var(--color-blue-800)"
        tile
        @click="emit('end')"
      >
        Publish
      </v-btn>
      <v-btn
        v-else
        class="btn-edit white--text"
        color="var(--color-blue-800)"
        tile
        @click="emit('end')"
      >
        Done
      </v-btn>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.org-promoters {
  width: 100%;
  min-height: 500px;
  margin: 20px auto;
}

.promoters-title {
  margin: 12px 0;
  color: var(--color-dark-800);
}

.promoters-wrap {
  padding: 20px;
}
</style>
