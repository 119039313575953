// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { callPrivateApi } from '@/api/apiHelper'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { EventMapSearchParams, EventsMapResponse } from '../model/types'

export const mapApi = {
  async getMapEvents({
    tab: t,
    sortBy: s,
    keyword: k,
    userCoords: uc,
    visibleMap,
  }: EventMapSearchParams): EventsMapResponse {
    return callPrivateApi('get', '/events-map', {
      params: {
        t,
        s,
        k,
        uc,
        ...visibleMap,
      },
    })
  },
}
