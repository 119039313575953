import { render, staticRenderFns } from "./EventTicket.vue?vue&type=template&id=204e4a9f&scoped=true"
import script from "./EventTicket.vue?vue&type=script&lang=ts&setup=true"
export * from "./EventTicket.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./EventTicket.vue?vue&type=style&index=0&id=204e4a9f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204e4a9f",
  null
  
)

export default component.exports