import { BATTLE_CATEGORIES, BATTLE_PLACES } from './constants'

export function useBattle() {
  const battleCategories = BATTLE_CATEGORIES
  const battlePlaces = BATTLE_PLACES

  function getBattleCategoryName(crewSize: number): string | undefined {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const category = BATTLE_CATEGORIES.find((item) => item.value === crewSize)
    return category?.text
  }

  return {
    getBattleCategoryName,
    battleCategories,
    battlePlaces,
  }
}
