<script lang="ts" setup>
import { computed, ref } from 'vue'
import OrganizationSettingsAddUser from '@/modules/Organizations/components/settings/OrganizationSettingsAddUser.vue'
import OrganizationSettingsUserChip from '@/modules/Organizations/components/settings/OrganizationSettingsUserChip.vue'
import BKUserSelect from '@/components/BKUserSelect.vue'
import { Organization, OrganizationUser, UserRole } from '@/modules/Organizations/types'
import { addUserWithRole, removeUserWithRole } from '@/enitites/organization/api/endpoints'

const props = defineProps<{ org: Organization }>()

const isEditMode = ref(false)

const excludedUsersIds = computed(() => props.org.admins.map((a) => a.id))

const addAdmin = async (user: OrganizationUser, role: UserRole) => {
  const resp = await addUserWithRole(props.org.id, user.id, role)
  if (resp) props.org.admins.push(user)
}

const removeAdmin = async (user: OrganizationUser, role: UserRole) => {
  const resp = await removeUserWithRole(props.org.id, user.id, role)
  if (resp) props.org.admins = props.org.admins.filter((admin) => admin.id !== user.id)
}
</script>

<template>
  <div class="admins">
    <div
      v-if="isEditMode"
      class="editor"
    >
      <span class="editor-title">Edit Admin</span>
      <!-- eslint-disable vuejs-accessibility/aria-role -->
      <BKUserSelect
        :exclude="excludedUsersIds"
        :select-user-chip="org.admins"
        label-text="Search to add admin"
        role="admin"
        @removeUser="removeAdmin"
        @selectUser="addAdmin"
      />
    </div>

    <div
      v-else
      class="list"
    >
      <OrganizationSettingsAddUser
        class="item add-user-btn"
        user-role="admin"
        @click="isEditMode = true"
      />
      <OrganizationSettingsUserChip
        v-for="admin in org.admins"
        :key="admin.id"
        :user="admin"
        class="item user"
      />
    </div>

    <div
      v-if="isEditMode"
      class="options"
    >
      <v-btn
        color="var(--color-blue-800)"
        dark
        height="52"
        width="180"
        @click="isEditMode = false"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.admins {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .options {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding: 20px;
    border-top: 1px solid var(--color-grey-600);
  }
}

.editor {
  padding: 20px;

  .editor-title {
    @include font-h3;

    display: block;
    margin-bottom: 9px;
    font-weight: var(--font-weight-bold);

    @media (max-width: 768px) {
      @include font-body1;
    }
  }

  &::v-deep {
    .chip {
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }

    .v-chip__content {
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .chip__close-icon {
      @media (max-width: 768px) {
        margin-left: auto;
      }
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  .item {
    @media (max-width: 768px) {
      display: flex;
      flex-basis: 100%;
      gap: 8px;
      align-items: center;
      height: auto;
      padding: 16px 0;
    }

    ::v-deep .avatar {
      @media (max-width: 768px) {
        width: 48px;
        height: 48px;
        margin: 0;
      }
    }

    &:hover {
      @media (max-width: 768px) {
        box-shadow: none;
      }
    }
  }

  .add-user-btn {
    ::v-deep {
      .avatar {
        @media (max-width: 768px) {
          background-color: var(--color-grey-500);
        }

        .icon {
          @media (max-width: 768px) {
            font-size: 36px;
          }
        }
      }

      .label {
        @media (max-width: 768px) {
          font-weight: var(--font-weight-normal);
        }
      }
    }
  }

  .user {
    ::v-deep {
      .avatar {
        @media (max-width: 768px) {
          max-width: 48px;
        }
      }

      .name {
        @media (max-width: 768px) {
          font-weight: var(--font-weight-normal);
        }
      }
    }
  }
}
</style>
