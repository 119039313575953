<template>
  <section class="media-section">
    <h2 class="media-title">
      <span>Media</span>
      <BKButton
        v-if="canEdit"
        class="add-media-btn"
        outlined
        width="max-content"
        @click="isShowMediaEditor = true"
      >
        Add media
      </BKButton>

      <BKMediaEditor
        :event-id="eventId"
        :is-show-editor.sync="isShowMediaEditor"
        :org-id="orgId"
        @save="submitSaveMedia"
      />
    </h2>

    <ModalWrapper :is-show.sync="isShowMediaPlayer">
      <v-card
        v-if="selectedMedia && isShowMediaPlayer"
        height="440"
        max-width="830"
      >
        <iframe
          :src="embedSourceUrl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          height="100%"
          title="YouTube video player"
          width="100%"
        />
      </v-card>
    </ModalWrapper>

    <div
      v-if="media && media.length"
      class="media-items"
    >
      <div
        v-for="item in media"
        :key="item.id"
        class="media-item"
      >
        <v-img
          :alt="item.name"
          :src="getPreviewImageUrl(item.link)"
          class="image"
          height="117"
          width="205"
          @click="showMedia(item.link)"
        >
          <img
            :src="'/img/icon-play.svg'"
            alt="Play icon"
            class="icon-play"
          />
        </v-img>
        <div class="media-name">
          {{ item.name }}
          <v-btn
            :loading="loadingDeleteMedia"
            color="var(--rasberry-red)"
            icon
            @click="removeMedia(item.id)"
          >
            <v-icon v-if="canEdit">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ModalWrapper } from '@/shared/ui/modals'
import { mdiClose } from '@mdi/js'
import { mediaApi, MediaDB } from '@/enitites/media'
import { computed, onMounted, ref } from 'vue'
import BKMediaEditor from './BKMediaEditor.vue'

const props = withDefaults(
  defineProps<{
    orgId: number
    eventId?: number
    canEdit?: boolean
    title?: string
  }>(),
  {
    canEdit: false,
    title: 'Media',
    eventId: 0,
  }
)

const media = ref<MediaDB[]>([])
const isShowMediaEditor = ref(false)
const isShowMediaPlayer = ref(false)
const selectedMedia = ref('')
const loadingDeleteMedia = ref(false)

const embedSourceUrl = computed(() => {
  return `https://www.youtube.com/embed/${selectedMedia.value}?autoplay=1`
})

async function fetchMedia() {
  const fetchedMedia = await mediaApi.getMedia({
    eventId: props.eventId,
    orgId: props.orgId,
  })

  if (fetchedMedia) media.value = fetchedMedia
}

function submitSaveMedia(updatedMedia: MediaDB[]) {
  media.value = updatedMedia
  isShowMediaEditor.value = false
}

function showMedia(link: string) {
  isShowMediaPlayer.value = true
  selectedMedia.value = link
}

async function removeMedia(id: number) {
  loadingDeleteMedia.value = true
  const mediaUpdated = await mediaApi.removeMedia(id)
  if (mediaUpdated) media.value = mediaUpdated
  loadingDeleteMedia.value = false
}

function getPreviewImageUrl(youtubeId: string) {
  return `https://img.youtube.com/vi/${youtubeId}/0.jpg`
}

onMounted(() => {
  fetchMedia()
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.media-title {
  @include font-h4;

  display: flex;
  align-items: center;
  column-gap: 10px;
}

.add-media-btn {
  max-width: 200px;
}

.media-items {
  display: flex;
  flex-wrap: wrap;
  margin: 12px -10px;
}

.media-item {
  margin: 0 10px;

  .image {
    cursor: pointer;
  }

  .icon-play {
    position: absolute;
    top: 35px;
    right: 78px;
  }
}

.media-name {
  @include font-body2;

  margin-top: 8px;
  color: var(--color-black);
}
</style>
