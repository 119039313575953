<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
    title="Location"
  >
    <template #default>
      <v-form v-model="valid">
        <BKCountrySelect
          :class="'create-form__field'"
          :country.sync="user.country"
          :hide-details="true"
          :is-show-state.sync="isShowState"
        />
        <div class="inputs-wrapper">
          <v-text-field
            v-model="user.city"
            :rules="[rules.required]"
            autocomplete="off"
            background-color="var(--color-white)"
            hide-details
            label="City*"
            outlined
            @keyup.enter="emit('submitForm', user)"
          />
          <v-text-field
            v-if="isShowState"
            v-model="user.state"
            :rules="[rules.required]"
            autocomplete="off"
            background-color="var(--color-white)"
            hide-details
            label="State*"
            outlined
            @keyup.enter="emit('submitForm', user)"
          />
        </div>
      </v-form>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="$emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading || !valid"
        :loading="loading"
        white-text
        @click="emit('submitForm', user)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script lang="ts" setup>
import BKButton from '@/components/AppShared/BKButton.vue'
import BKCountrySelect from '@/components/AppShared/BKCountrySelect.vue'
import { UserDB } from '@/enitites/user'
import useValidationRules from '@/shared/composables/validation'
import { ref, watch } from 'vue'
import { useCloned } from '@vueuse/core'
import OnboardingFormLayout from './OnboardingFormLayout.vue'

const props = withDefaults(
  defineProps<{
    currentStep: number
    error: string
    loading: boolean
    steps: number
    user: UserDB
  }>(),
  {
    currentStep: 0,
    error: '',
    loading: false,
    steps: 0,
  }
)

const emit = defineEmits<{
  (e: 'goBack'): void
  (e: 'submitForm', value: UserDB): void
}>()

const user = ref(useCloned(props.user).cloned)

const rules = useValidationRules()

const isShowState = ref(true)
const valid = ref(true)

watch(isShowState, () => {
  user.value.state = isShowState.value ? user.value.state : ''
})
</script>

<style lang="scss" scoped>
.inputs-wrapper {
  display: flex;
  gap: 8px;

  @media screen and (max-width: 460px) {
    flex-direction: column;
    gap: 0;
  }
}
</style>
