<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import ProfileEventRow from '@/pages/ProfilePage/ui/ProfileEventRow.vue'
import { eventApi, EventDB, EventPeriod } from '@/enitites/event'

const props = defineProps<{
  tab: EventPeriod
  userId: number
  withPagination?: boolean
}>()

const events = ref<EventDB[]>([])
const searchParams = ref({
  pageNumber: 0,
  pageSize: props.withPagination ? 5 : 100,
  userId: props.userId,
  tab: props.tab,
})

const infiniteId = ref(+new Date())

async function fetchEvents() {
  const data = await eventApi.getEvents(searchParams.value)
  if (!data || typeof data === 'string') return
  events.value = data
  searchParams.value.pageNumber += 1
}

async function infiniteHandler($state: { complete: () => void; loaded: () => void }) {
  const data = await eventApi.getEvents(searchParams.value)
  if (!data || typeof data === 'string') return
  searchParams.value.pageNumber += 1
  events.value = [...events.value, ...data]
  if (data.length < searchParams.value.pageSize) $state.complete()
  else $state.loaded()
}

function resetLoading() {
  searchParams.value = {
    pageNumber: 0,
    pageSize: props.withPagination ? 5 : 100,
    userId: props.userId,
    tab: props.tab,
  }
  events.value = []
  infiniteId.value = +new Date() + Math.random()
  fetchEvents()
}

watch(() => props.userId, resetLoading)
watch(() => props.tab, resetLoading)

onMounted(async () => {
  await fetchEvents()
})
</script>

<template>
  <v-row
    class="events"
    justify="start"
    no-gutters
  >
    <ProfileEventRow
      v-for="(event, i) in events"
      :key="i"
      :event="event"
    />
    <infinite-loading
      v-if="withPagination"
      :identifier="infiniteId"
      spinner="bubbles"
      @infinite="infiniteHandler"
    >
      <div
        slot="spinner"
        class="loader"
      >
        <v-progress-circular
          color="#FC7800"
          indeterminate
          size="50"
          width="10"
        />
      </div>
      <span slot="no-more" />
      <span slot="no-results" />
    </infinite-loading>
  </v-row>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.nav-events {
  width: 100%;
  max-width: 335px;
  background-color: #ddd;
  border-radius: 20px;

  @media screen and (min-width: 600px) {
    max-width: 633px;
  }

  &__btn {
    width: 33%;

    &--active {
      color: #333 !important;
      font-weight: 600;
    }

    &--active::after {
      position: absolute;
      bottom: -25px;
      left: calc(50% - 15px);
      border: 15px solid transparent;
      border-top: 10px solid #ddd;
      transition-duration: 0.28s;
      content: '';
    }

    &--active:hover::after {
      border-top: 10px solid #d6d6d6 !important;
      transition-duration: 0.28s;
    }

    &:first-child::before {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child::before {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.events {
  &__row {
    display: flex;
    align-items: center;
    padding: 12px 0;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  &__draft-label {
    color: #ff2d53;
  }

  &__check-in-badge {
    margin-bottom: 10px;
    color: white !important;
    text-transform: uppercase;
  }

  &__title {
    margin: 8px 0 4px;
    color: var(--color-dark-800);
    font-weight: 500;
    font-style: normal;

    @media (min-width: 600px) {
      @include font-h4;
    }
  }

  &__line {
    display: inline-block;
    width: 1px;
    height: 20px;
    margin-bottom: -3px;
    background-color: #ddd;
  }

  &__battle-lines {
    border-top: 0.5px solid #ddd;
  }

  &__battle-line {
    border-bottom: 0.5px solid #ddd;
  }

  &__line-bottom {
    border-bottom: 1px solid #ddd;
  }

  &__battle-title {
    color: #ddd;
    font-weight: 400;
  }

  &__img {
    width: 80px;
    height: 80px;

    @media (min-width: 600px) {
      width: 200px;
      height: 100px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__sticket {
    width: 30px;
    height: 30px;

    @media (min-width: 600px) {
      width: 60px;
      height: 60px;
    }
  }

  &__circle {
    @include font-caption1;

    position: relative;
    display: inherit;
    width: 33px;
    height: 33px;
    background-color: #fc7800;
    border-radius: 50%;

    &.cash {
      background-color: #6cace4;
    }

    &.card {
      background-color: var(--color-green);
    }

    &.staff {
      background-color: var(--color-green);
    }

    &.view {
      background-color: #000;
    }

    @media (min-width: 600px) {
      @include font-body1;

      width: 60px;
      height: 60px;
    }
  }

  &__icon-ticket,
  &__icon-buy {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--color-white);
    text-transform: uppercase;
    transform: translate(-50%, -50%);
  }

  &__icon-view {
    position: absolute;
    top: 30%;
    left: 30%;
    color: var(--color-white);
    text-transform: uppercase;
  }

  &__icon-ticket {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.filter-bar {
  &__az {
    color: #333;
  }

  &__btn-filter {
    border: #ddd 1px solid !important;
  }

  &__menu {
    position: absolute;
    top: 36px;
    right: 0;
    z-index: 1;
    width: 100%;
    padding: 0;
  }
}

.badge {
  display: inline-block;
  width: 25px;
  height: 25px;
  color: #fff;
  text-align: center;
  background-color: var(--color-green);
  border-radius: 50%;
}

.loader::v-deep {
  width: 100%;
  margin: 20px;
}
</style>
