<template>
  <section class="authentication">
    <div class="background" />
    <div class="signup-form">
      <h2 class="signup-form__title page-title">Create Profile</h2>
      <v-card
        v-if="isIncomplete"
        class="mb-6"
        color="#FFEDDC"
        height="68"
      >
        <v-card-text class="signup-form__incomplete-text text-center">
          Hey it looks like your profile is incomplete.<br />
          Finish setting up your profile to access the site
        </v-card-text>
        <v-btn
          class="signup-form__incomplete-close"
          icon
          @click="isIncomplete = false"
        >
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card>

      <component
        :is="currentForm"
        @signup="currentForm = OnboardingForm"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { mdiClose } from '@mdi/js'
import { computed, onMounted, onUpdated, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { OnboardingForm } from '@/widgets/Onboarding'
import StartFormSignup from './StartFormSignup.vue'

const route = useRoute()
const router = useRouter()
const isIncomplete = ref(!!route.query.incomplete)
const getUserDB = computed(() => store.getters.getUserDB)
type AuthForm = typeof StartFormSignup | typeof OnboardingForm
const currentForm = ref<AuthForm>(StartFormSignup)
watch(
  () => getUserDB,
  () => {
    if (getUserDB.value.uid) {
      currentForm.value = OnboardingForm
    }
  }
)

onMounted(() => {
  if (!getUserDB.value) return
  currentForm.value = OnboardingForm

  if (getUserDB.value.dancerName) {
    router.push({ name: 'Home' })
  }
})

onUpdated(() => {
  document.body.scrollIntoView()
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.authentication {
  @include profile-settings-bg;
}

.signup-form {
  position: relative;
  width: 100%;
  max-width: 460px;
  min-height: 500px;
  margin: 180px auto 0;
  padding: 0 20px;

  &__title {
    @include font-h1;

    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
  }

  &__incomplete-text {
    color: #fc7800 !important;
  }

  &__incomplete-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--color-grey-400);
  }
}
</style>
