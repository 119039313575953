<script lang="ts" setup>
import { useVuetify } from '@/services/useVuetify'
import { computed } from 'vue'
import { EventDB } from '@/enitites/event'
import EventCardMobile from './EventPreviewBaseCardMobile.vue'
import EventCardDesktop from './EventPreviewBaseCardDesktop.vue'

const vuetify = useVuetify()
const isDesktopTablet = computed(() => vuetify.breakpoint.smAndUp)

interface IProps {
  previewEvent: EventDB
}

defineProps<IProps>()
</script>

<template>
  <EventCardDesktop
    v-if="isDesktopTablet"
    :preview-event="previewEvent"
  />
  <EventCardMobile
    v-else
    :preview-event="previewEvent"
  />
</template>
