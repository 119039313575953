<template>
  <div class="register-crew">
    <section>
      <h3 class="register-title">Register crew</h3>

      <section
        v-if="isEditMode"
        class="editor"
      >
        <div>
          <v-form
            ref="crewNameForm"
            v-model="isCrewNameValid"
            @submit.prevent="updateCrew"
          >
            <v-text-field
              v-model="userCrew.name"
              :rules="crewNameRules"
              autocomplete="off"
              background-color="#fff"
              class="battle-detail__text-field"
              label="Crew name*"
              outlined
            />
          </v-form>
          <BattleParticipantSelect
            :disabled="disabledParticipants"
            :exclude="judgesIds"
            :non-removable="nonRemovableParticipants"
            :participants.sync="userCrew.participants"
            @removeUser="removeCrewDancer"
            @selectUser="addCrewDancer"
          />
        </div>
      </section>

      <div
        v-if="!battle.permissions.registered"
        class="actions"
      >
        <Agreements
          v-if="battle.permissions.showAgreement || battle.permissions.showWaiver"
          :battle="battle"
          class="agreements"
          @update:battle="$emit('update:battle', $event)"
          @reload:battle="$emit('reload:battle')"
        />

        <v-btn
          :disabled="!battle.permissions.registerButtonEnabled || !userCrew.name"
          :loading="loading"
          block
          class="action-btn white--text"
          color="var(--color-blue-800)"
          @click.stop="updateCrew"
        >
          Register Crew
        </v-btn>
      </div>

      <div
        v-else
        class="actions"
      >
        <template v-if="isEditMode">
          <BKButton
            :loading="loading"
            class="action-btn"
            color="var(--color-green)"
            white-text
            @click="updateCrew"
          >
            Update Crew
          </BKButton>

          <BKButton
            class="action-btn"
            color="#FF2D53"
            outlined
            @click="cancelEditCrew"
          >
            Cancel
          </BKButton>
        </template>

        <template v-else>
          <BKCrewChip
            :crew="userCrew"
            class="crew-chip"
          />
          <BKButton
            class="action-btn"
            color="var(--color-blue-800)"
            white-text
            @click="isEditMode = true"
          >
            Edit crew
          </BKButton>

          <BKButton
            v-if="battle.canUnregister"
            :loading="loading"
            class="action-btn"
            color="var(--color-blue-800)"
            outlined
            @click="handleBattleUnregisterModal"
          >
            Unregister
          </BKButton>

          <BattleUnregisterCrew
            v-if="isUnregisterModalOpen"
            :battle="battle"
            :init-crew-editor="initCrewEditor"
            :is-show-modal.sync="isUnregisterModalOpen"
            :user-crew="userCrew"
            @update:battle="$emit('update:battle', $event)"
          />
        </template>
      </div>

      <div class="actions">
        <BKCheckinButton
          v-if="battle.permissions.registered"
          :event="event"
          @checkIn="$emit('reload:battle')"
          @purchase="$emit('reload:event')"
          @update:event="$emit('update:event', $event)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'
import Images from '@/mixins/image'
import BattleParticipantSelect from '@/components/Battle/Info/BattleParticipantSelect.vue'
import Agreements from '@/components/AgreementsPage.vue'
import BKCrewChip from '@/components/BKCrewChip.vue'
import { BKCheckinButton } from '@/features/checkin'
import { BATTLE_CATEGORIES } from '@/common/constants'
import BattleUnregisterCrew from '@/components/Battle/Info/BattleUnregisterCrew.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'BattleRegisterCrew',
  components: {
    BattleParticipantSelect,
    Agreements,
    BKCrewChip,
    BKCheckinButton,
    BattleUnregisterCrew,
  },
  mixins: [Images],
  props: {
    event: {
      type: Object,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userCrew: {
        id: 0,
        name: '',
        participants: [],
        order: 0,
      },
      crewNameRules: [(v) => !!v || 'Crew name is required'],
      isCrewNameValid: false,
      isEditMode: false,
      loading: false,
      isUnregisterModalOpen: false,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    nonRemovableParticipants() {
      if (!this.getUserDB) return []
      return [this.getUserDB.id]
    },
    disabledParticipants() {
      let excludedIds = [...this.event.staff.admins.map((r) => r.id)]
      if (this.event.staff.organizer) excludedIds.push(this.event.staff.organizer.id)
      if (this.getUserDB) excludedIds.push(this.getUserDB.id)

      this.battle.crews.forEach((cr) => {
        if (!cr.participants.length) return
        excludedIds = [...excludedIds, ...cr.participants.filter((p) => p.confirmed).map((p) => p.id)]
      })

      return excludedIds
    },
    getUserCrew() {
      const emptyCrew = {
        id: 0,
        name: '',
        participants: [],
        order: 0,
      }

      if (!this.getUserDB) {
        return { ...emptyCrew }
      }

      const userCrew = this.battle.crews.find(
        (crew) => !!crew.participants.filter((mem) => mem.id === this.getUserDB.id && mem.confirmed).length
      )

      if (!userCrew) {
        return { ...emptyCrew }
      }

      return Object.assign(emptyCrew, userCrew)
    },
    judgesIds() {
      return this.battle.judges.map((judge) => judge.id)
    },
  },
  watch: {
    'battle.permissions.registered': function () {
      this.userCrew = this.getUserCrew
    },
  },
  mounted() {
    this.initCrewEditor()
  },
  methods: {
    initCrewEditor() {
      this.userCrew = this.getUserCrew
      if (!this.battle.permissions.registered) this.isEditMode = true

      if (this.userCrew.participants.length) return
      this.userCrew.participants.push({
        id: this.getUserDB.id,
        dancerName: this.getUserDB.dancerName,
        avatar: this.getUserDB.avatar,
        confirmed: true,
      })
    },
    removeCrewDancer(dancer) {
      this.userCrew.participants = this.userCrew.participants.filter((dancerCrew) => dancerCrew.id !== dancer.id)
    },
    addCrewDancer(dancer) {
      this.$refs.crewNameForm.validate()
      const isUnlimited = this.battle.crewSize === BATTLE_CATEGORIES[BATTLE_CATEGORIES.length - 1].value
      if (!isUnlimited && this.userCrew.participants.length >= this.battle.crewSize) return

      this.userCrew.participants = [...this.userCrew.participants, dancer]
    },
    cancelEditCrew() {
      this.$refs.crewNameForm.validate()
      this.userCrew = this.getUserCrew
      this.isEditMode = false
    },
    async updateCrew() {
      if (!this.getUserDB) {
        this.$router.push({ name: 'Login' })
        return
      }

      if (
        this.userCrew.participants.length <= 1 &&
        // eslint-disable-next-line no-restricted-globals
        !confirm("Looks like you didn't add anyone to your crew, if you are sure and want to continue, click OK")
      ) {
        return
      }

      this.loading = true
      const payload = {
        eventId: this.event.id,
        battleId: this.battle.id,
        crewId: this.userCrew.id,
        name: this.userCrew.name,
        participants: this.userCrew.participants,
      }

      const updatedBattle = this.userCrew.id
        ? await api.crews.updateCrew(payload)
        : await api.crews.registerCrew(payload)

      if (!updatedBattle) {
        this.loading = false
        return
      }

      this.isEditMode = false
      this.$emit('update:battle', updatedBattle)

      this.$nextTick(() => {
        this.initCrewEditor()
        this.loading = false
      })
    },
    handleBattleUnregisterModal() {
      this.isUnregisterModalOpen = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.register-crew {
  margin: 20px 0;
  padding: 0 20px;

  .register-title {
    @include font-h3;

    margin-bottom: 20px;
  }

  .crew-chip {
    margin-bottom: 20px;
  }

  .actions {
    .action-btn {
      width: 100%;
      height: 52px !important;
      margin-bottom: 12px;
    }
  }

  .agreements {
    padding-bottom: 10px;
  }
}
</style>
