<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { eventApi, EventPeriod, EventPeriodStats } from '@/enitites/event'
import ProfileEventList from './ProfileEventList.vue'

const route = useRoute()

const tabs = ref<EventPeriodStats>({
  now: 0,
  past: 0,
  draft: 0,
  future: 0,
})
const sortAZ = ref(true)
const loading = ref(false)

type ExistedTab = { name: EventPeriod; value: number }

const userId = computed(() => Number(route.params.id))
const isShowNowTab = computed(() => !!tabs.value.now)
const getUserDB = computed(() => store.getters.getUserDB)
const existedTabs = computed((): ExistedTab[] => {
  if (!getUserDB.value || Number(route.params.id) !== Number(getUserDB.value?.id)) {
    return Object.keys(tabs.value)
      .filter((key) => key !== 'now' && key !== 'draft')
      .map((key) => ({
        name: key as EventPeriod,
        value: tabs.value[key as EventPeriod],
      }))
  }
  return Object.keys(tabs.value)
    .filter((key) => key !== 'now')
    .map((key) => ({
      name: key as EventPeriod,
      value: tabs.value[key as EventPeriod],
    }))
})

const currentTab = ref<EventPeriod | undefined>('past')

const sortEvents = () => {
  if (loading.value) return
  sortAZ.value = !sortAZ.value
}

const setCurrentTab = () => {
  currentTab.value = existedTabs.value.find((tab: ExistedTab) => tab.value > 0)?.name
}

const fetchTabs = async () => {
  const data = await eventApi.getEventsAvailableTabs({ userId: userId.value })
  if (data) tabs.value = data
}

watch(userId, async () => {
  await fetchTabs()
  setCurrentTab()
})

onMounted(async () => {
  await fetchTabs()
  setCurrentTab()
})
</script>

<template>
  <section>
    <section v-if="isShowNowTab">
      <span>Happening now</span>
      <ProfileEventList
        :user-id="userId"
        tab="now"
      />
    </section>

    <div class="event-tabs ma-auto d-flex justify-space-between mt-6">
      <v-btn
        v-for="eTab in existedTabs"
        :key="eTab.name"
        :class="{ active: currentTab === eTab.name }"
        :ripple="false"
        class="tabs-btn text-none pr-1"
        color="#666"
        text
        @click="currentTab = eTab.name"
      >
        <span class="tab-item"> {{ eTab.name }}({{ eTab.value }})</span>
      </v-btn>
    </div>

    <div class="filter-bar">
      <v-row class="relative">
        <v-col
          class="mr-auto"
          cols="5"
          sm="auto"
        >
          <v-btn
            class="px-1 text-none d-flex align-center"
            color="var(--color-grey-300)"
            text
            @click="sortEvents"
          >
            Sort by:
            <span class="toggle-sort">{{ sortAZ ? 'A-Z' : 'Z-A' }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <ProfileEventList
      v-if="currentTab"
      :tab="currentTab"
      :user-id="userId"
      with-pagination
    />
  </section>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.event-tabs {
  width: 100%;
  max-width: 335px;
  background-color: #ddd;
  border-radius: 20px;

  @media screen and (min-width: 600px) {
    max-width: 633px;
  }
}

.tabs-btn {
  flex: 1 1 0;

  &.active {
    color: #333 !important;
    font-weight: 600;
  }

  &.active::after {
    position: absolute;
    bottom: -25px;
    left: calc(50% - 15px);
    border: 15px solid transparent;
    border-top: 10px solid #ddd;
    transition-duration: 0.28s;
    content: '';
  }

  &.active:hover::after {
    border-top: 10px solid #d6d6d6 !important;
    transition-duration: 0.28s;
  }

  &:first-child::before {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &:last-child::before {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.filter-bar {
  .toggle-sort {
    color: #333;
  }

  .btn-filter {
    border: #ddd 1px solid !important;
  }

  .menu {
    position: absolute;
    top: 36px;
    right: 0;
    z-index: 1;
    width: 100%;
    padding: 0;
  }

  .filter-title {
    color: #999;
    font-weight: normal;
    font-style: normal;
  }
}

.tab-item {
  text-transform: capitalize;
}
</style>
