<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
    title="Enter your birthday"
  >
    <template #default>
      <v-form
        v-model="valid"
        @submit.prevent="emit('submitForm', user)"
      >
        <v-text-field
          v-model="user.birthday"
          v-mask="mask"
          :rules="[rules.required, rules.birthday]"
          autocomplete="off"
          background-color="var(--color-white)"
          hide-details
          label="Birthday*"
          outlined
          placeholder="10/18/1993"
        />
      </v-form>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading || !valid"
        :loading="loading"
        white-text
        @click="emit('submitForm', user)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script lang="ts" setup>
import { UserDB } from '@/enitites/user'
import BKButton from '@/components/AppShared/BKButton.vue'
import useValidationRules from '@/shared/composables/validation'
import { ref } from 'vue'
import { useCloned } from '@vueuse/core'
import OnboardingFormLayout from './OnboardingFormLayout.vue'

const props = withDefaults(
  defineProps<{
    currentStep: number
    error: string
    loading: boolean
    steps: number
    user: UserDB
  }>(),
  {
    currentStep: 0,
    error: '',
    loading: false,
    steps: 0,
  }
)

const user = ref(useCloned(props.user).cloned)

const emit = defineEmits<{
  (e: 'goBack'): void
  (e: 'submitForm', value: UserDB): void
}>()

const rules = useValidationRules()
const mask = '##/##/####'
const valid = ref(true)
</script>
