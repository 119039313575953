<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { BKModal } from '@/shared/ui/modals'
import { useImage } from '@/shared/composables/image'
import BKInput from '@/components/AppShared/BKInput.vue'
import BKImage from '@/components/AppShared/BKImage.vue'
import { mdiMagnify, mdiTuneVariant } from '@mdi/js'
import { IOrganizationApiData, IOrganizations } from '@/types/organizations'
import { fetchOrganizationsList } from '@/enitites/organization'

const { getOrgImage } = useImage()

const emit = defineEmits<{ (e: 'filterByOrg', value: number[]): void }>()

const isShow = ref(false)
const organizationsList = ref<IOrganizations[] | []>([])
const keyword = ref('')
const searchedOrganizations = computed<IOrganizations[] | []>(() =>
  organizationsList.value.filter((org) => org.name.toLowerCase().includes(keyword.value.toLowerCase()))
)

const selectedOrganizationsIds = ref<number[] | []>([])
const isShowAllOrganizations = ref(!selectedOrganizationsIds.value.length)

watch(selectedOrganizationsIds, () => {
  isShowAllOrganizations.value = !selectedOrganizationsIds.value.length
})

function clearSelectedOrganizations() {
  if (!isShowAllOrganizations.value) return
  selectedOrganizationsIds.value = []
  emit('filterByOrg', selectedOrganizationsIds.value)
}

const totalOrgStats = reactive({
  now: 0,
  future: 0,
  past: 0,
})

async function fetchOrganizations() {
  const organizations: IOrganizationApiData = await fetchOrganizationsList({ pageNumber: 0, pageSize: 100, userId: 0 })
  if (!organizations) return
  organizationsList.value = organizations.items
  totalOrgStats.now = organizations.totalCurrent
  totalOrgStats.future = organizations.totalFuture
  totalOrgStats.past = organizations.totalPast
}

onMounted(() => {
  fetchOrganizations()
})
</script>

<template>
  <div
    aria-label="Filter events dialog"
    class="filter-events-dialog"
  >
    <BKButton
      aria-label="Open filters"
      color="var(--color-black)"
      height="24"
      icon
      width="24"
      @click="isShow = true"
    >
      <v-icon>
        {{ mdiTuneVariant }}
      </v-icon>
    </BKButton>
    <BKModal :is-show.sync="isShow">
      <template #default>
        <div class="dialog-content">
          <BKInput
            v-model.trim="keyword"
            :prepend-icon="mdiMagnify"
            label="Search organizations"
          />
          <v-list class="filter-container">
            <v-list-item>
              <v-list-item-title class="filter-option">
                <div class="organization-data">
                  <BKImage
                    alt=""
                    height="60"
                    src="/img/all-orgs.png"
                    srcset="/img/all-orgs@2x.png 2x"
                    width="60"
                  />
                  <p class="information">
                    <span class="name">All organizations</span>
                    <span class="amount">
                      {{ totalOrgStats.now }} Now, {{ totalOrgStats.future }} Future, {{ totalOrgStats.past }} Past
                    </span>
                  </p>
                </div>
                <v-checkbox
                  v-model="isShowAllOrganizations"
                  :disabled="isShowAllOrganizations"
                  class="filter-checkbox"
                  color="var(--color-blue-800)"
                  hide-details
                  @change="clearSelectedOrganizations"
                />
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="org in searchedOrganizations"
              :key="org.id"
            >
              <v-list-item-title class="filter-option">
                <div class="organization-data">
                  <BKImage
                    :src="getOrgImage(org.image, 60)"
                    alt=""
                    class="org-thumb"
                    height="60"
                    width="60"
                  />
                  <p class="information">
                    <span class="name">{{ org.name }}</span>
                    <span class="amount">{{ org.current }} Now, {{ org.future }} Future, {{ org.past }} Past</span>
                  </p>
                </div>
                <v-checkbox
                  v-model="selectedOrganizationsIds"
                  :value="org.id"
                  class="filter-checkbox"
                  color="var(--color-blue-800)"
                  hide-details
                  @change="emit('filterByOrg', selectedOrganizationsIds)"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </BKModal>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/mixins';

.dialog-content {
  padding-top: 16px;

  .v-list-item {
    padding: 0;

    .organization-data {
      display: flex;
      gap: 12px;
      align-items: center;
      max-width: 260px;

      .org-thumb {
        border-radius: 6px;
      }

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .information {
        display: flex;
        flex-direction: column;

        .amount {
          @include font-body2;

          color: var(--color-grey-300);
        }
      }
    }
  }
}
</style>
