<script lang="ts" setup>
import BKInput from '@/components/AppShared/BKInput.vue'
import SearchEventsDialog from '@/pages/EventsPage/ui/SearchEventsDialog.vue'
import BKSvgIcon from '@/components/AppShared/BKSvgIcon.vue'
import { mdiChevronDown, mdiChevronUp, mdiMagnify, mdiPlus } from '@mdi/js'
import { useRoute, useRouter } from 'vue-router/composables'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { computed, onMounted, ref, watch } from 'vue'
import { eventApi, EventDB, EventPeriod, EventSearchParams } from '@/enitites/event'
import { useVuetify } from '@/services/useVuetify'
import FilterEventsDialog from './FilterEventsDialog.vue'

const vuetify = useVuetify()
const isDesktopTablet = computed(() => vuetify.breakpoint.mdAndUp)
const router = useRouter()
const route = useRoute()

const searchParams = ref<EventSearchParams>({
  pageNumber: 0,
  pageSize: 50,
  keyword: '',
  sortBy: 'date-asc',
  tab: undefined,
  orgIds: [],
  userLat: 0,
  userLng: 0,
})

const tabs = ref<
  {
    name: Exclude<EventPeriod, 'draft'>
    title: string
    isShow: boolean
  }[]
>([
  { name: 'now', title: 'Happening now', isShow: false },
  { name: 'future', title: 'Future events', isShow: false },
  { name: 'past', title: 'Past events', isShow: false },
])

const sortBy = ref('date')
const sortDirection = ref(true)

const events = ref<EventDB[]>([])
const showNotFound = ref(false)
const infiniteId = ref(+new Date())
const loading = ref(false)
const currentTab = ref<{ name: string; title: string } | null>(null)

function resetLoading() {
  searchParams.value.pageNumber = 0
  events.value = []
  showNotFound.value = false
  infiniteId.value += 1
}

function filterByOrg(ids: number[]) {
  resetLoading()
  searchParams.value.orgIds = ids
}

function createEvent() {
  if (!store.getters.getUserDB) {
    router.push({
      name: 'Signup',
      query: { redirect: route.path },
    })
    return
  }
  router.push({ name: 'CreateEvent' })
}

async function infiniteHandler($state: { complete: () => void; loaded: () => void }) {
  loading.value = true
  searchParams.value.sortBy = `${sortBy.value}-${sortDirection.value ? 'asc' : 'desc'}`
  const data = await eventApi.getEvents(searchParams.value)
  if (typeof data === 'string') return
  searchParams.value.pageNumber += 1
  events.value.push(...data)
  if (events.value.length === 0) showNotFound.value = true
  if (data.length < searchParams.value.pageSize) $state.complete()
  else $state.loaded()
  loading.value = false
}

function getGeolocationCoordinates() {
  navigator.geolocation.getCurrentPosition((location: GeolocationPosition) => {
    searchParams.value.userLat = location.coords.latitude
    searchParams.value.userLng = location.coords.longitude
  })
}

const dateStart = ref<Date | null>(null)
const dateEnd = ref<Date | null>(null)

function clearFilter() {
  dateStart.value = null
  dateEnd.value = null
  searchParams.value.keyword = ''
}

function handleChangeTab({ tab, sortBy }: { tab: Exclude<EventPeriod, 'draft'>; sortBy: 'date-asc' | 'date-desc' }) {
  searchParams.value.tab = tab
  searchParams.value.sortBy = sortBy
}

watch([sortBy, sortDirection], () => {
  if (loading.value) return
  resetLoading()
})

const timer = ref<number | null>(null)

watch(
  () => searchParams.value.keyword,
  () => {
    if (timer.value) clearTimeout(timer.value)
    timer.value = setTimeout(() => {
      resetLoading()
      timer.value = null
    }, 1200)
  }
)

const defaultSortDirections: Record<EventPeriod, boolean> = {
  now: true,
  past: false,
  future: true,
  draft: false,
}

watch(
  () => searchParams.value.tab,
  (tab) => {
    sortDirection.value = defaultSortDirections[tab]
    searchParams.value.tab = tab
    clearFilter()
    resetLoading()
  }
)

onMounted(async () => {
  getGeolocationCoordinates()
  const availableTabs = await eventApi.getEventsAvailableTabs({ userId: 0, orgId: 0 })
  if (!availableTabs) return
  tabs.value = tabs.value.map((tab) => ({ ...tab, isShow: !!availableTabs[tab.name] }))
  const firstAvailableTab = tabs.value.find((period) => period.isShow)
  if (!firstAvailableTab) return
  searchParams.value.tab = firstAvailableTab.name
  currentTab.value = { ...firstAvailableTab }
})
</script>

<template>
  <section :class="['events', { 'light-bg': route.name === 'EventsMap' || route.name === 'EventsCalendar' }]">
    <div class="background" />
    <div class="wrapper">
      <div class="title-controls-wrapper">
        <div class="title-container">
          <v-btn
            class="create-event-button"
            color="var(--color-white)"
            icon
            @click="createEvent"
          >
            <v-icon class="icon-plus">
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
          <h1 class="events-title page-title">Events</h1>
        </div>
        <BKInput
          v-model="searchParams.keyword"
          :prepend-icon="mdiMagnify"
          class="search"
          color="var(--color-grey-500)"
          hide-details
          placeholder="Search events"
        >
          <template #append>
            <FilterEventsDialog
              class="filter-dialog-desktop"
              @filterByOrg="filterByOrg($event)"
            />
          </template>
        </BKInput>
        <div
          v-if="!isDesktopTablet"
          class="controls"
        >
          <SearchEventsDialog
            :events="events"
            @changed-tab="searchParams.tab = $event"
          />
          <FilterEventsDialog @filterByOrg="filterByOrg($event)" />
        </div>
        <div class="views-wrapper">
          <v-tabs
            centered
            class="tabs"
            color="var(--color-grey-300)"
            grow
            hide-slider
          >
            <v-tab
              :to="{ name: 'EventsGrid' }"
              class="view-tab"
            >
              <BKSvgIcon
                class="tab-icon"
                color="var(--color-grey-300)"
                height="20px"
                name="grid"
                width="20px"
              />
              Grid
            </v-tab>
            <v-tab
              :to="{ name: 'EventsMap' }"
              class="view-tab"
            >
              <BKSvgIcon
                class="tab-icon"
                color="var(--color-grey-300)"
                height="20px"
                name="pinpoint"
                width="20px"
              />
              Map
            </v-tab>
            <v-tab
              :to="{ name: 'EventsCalendar' }"
              class="view-tab"
            >
              <BKSvgIcon
                class="tab-icon"
                color="var(--color-grey-300)"
                height="20px"
                name="calendar"
                width="20px"
              />
              Calendar
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <div
        v-if="searchParams.tab && route.name === 'EventsGrid'"
        class="controls-container"
      >
        <div class="period-tabs">
          <template v-for="(tab, index) in tabs">
            <button
              v-if="tab.isShow"
              :key="index"
              :class="searchParams.tab === tab.name && 'active'"
              class="period-button"
              @click="searchParams.tab = tab.name"
            >
              {{ tab.title }}
            </button>
          </template>
        </div>
        <v-select
          v-if="tabs.length"
          v-model="searchParams.tab"
          :items="tabs"
          class="period-select"
          dark
          height="52"
          hide-details
          item-text="title"
          item-value="name"
          solo
        >
          <template #append>
            <v-icon
              color="white"
              size="40"
            >
              {{ mdiChevronDown }}
            </v-icon>
          </template>
        </v-select>
        <div class="filter-bar">
          <div class="sort">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  Sort by:
                  <button>
                    <span v-if="sortBy === 'date'"> Date </span>
                    <span v-else-if="sortBy === 'distance'"> Distance </span>
                    <span v-else> A - Z </span>
                  </button>
                </div>
              </template>
              <v-list
                class="sort-menu"
                width="242"
              >
                <v-radio-group
                  v-model="sortBy"
                  class="mt-0"
                  hide-details
                >
                  <v-list-item
                    class="d-flex justify-space-between"
                    @click="sortBy = 'date'"
                  >
                    <span>Date created</span>
                    <div>
                      <v-radio
                        class="mr-0"
                        value="date"
                      />
                    </div>
                  </v-list-item>
                  <v-list-item
                    class="d-flex justify-space-between"
                    @click="sortBy = 'name'"
                  >
                    <span>A - Z</span>
                    <div>
                      <v-radio
                        class="mr-0"
                        value="title"
                      />
                    </div>
                  </v-list-item>
                </v-radio-group>
              </v-list>
            </v-menu>
            <button
              class="sort-arrows"
              type="button"
              @click="sortDirection = !sortDirection"
            >
              <v-icon
                class="icon-up"
                color="white"
                size="20"
              >
                {{ mdiChevronUp }}
              </v-icon>
              <v-icon
                class="icon-down"
                color="white"
                size="20"
              >
                {{ mdiChevronDown }}
              </v-icon>
            </button>
          </div>
        </div>
      </div>
      <router-view
        :events="events"
        :infinite-id="infiniteId"
        :is-not-found="showNotFound"
        :search-params="searchParams"
        :search-params-tab="!!searchParams.tab"
        @infinite="infiniteHandler"
        @changed-tab="handleChangeTab($event)"
      />
    </div>
  </section>
</template>
<style lang="scss" scoped>
@import '@/assets/style/mixins';

.events {
  .background {
    position: absolute;
    width: 100%;
    height: 1052px;
    background: #1b2432 url('/img/events.jpg') 50% 100% / cover no-repeat;
  }

  .wrapper {
    position: relative;
    height: 100%;
    padding-top: 82px;
    overflow: hidden;

    @media (max-width: $screen-sm) {
      padding-top: 78px;
    }
  }

  .views-wrapper {
    flex-basis: 25%;
    grid-area: views;

    .tabs {
      border-radius: 6px;

      .view-tab {
        display: flex;
        flex: 1 1 0;
        gap: 4px;
        align-items: center;
        font-family: Roboto, Helvetica, sans-serif;
        text-transform: capitalize;
        border-radius: 6px;
      }

      .view-tab.v-tab--active {
        background-color: var(--color-grey-700);
      }

      .tab-icon {
        min-width: 20px;
      }
    }
  }

  .title-controls-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 32px 50px;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey-700);

    .search {
      display: flex;
      max-width: 340px;

      .filter-dialog-desktop ::v-deep .bk-button {
        color: var(--color-black) !important;
      }

      @media (max-width: 768px) {
        display: none;
        width: 100%;
      }
    }

    .controls {
      display: none;
      gap: 20px;
      align-items: center;

      @media (max-width: 768px) {
        display: flex;
      }
    }

    .views-wrapper {
      flex-basis: auto;

      @media (max-width: 768px) {
        flex-basis: 100%;
      }
    }

    @media (max-width: 768px) {
      padding: 16px 20px;
    }
  }

  .title-container {
    display: flex;
    flex-basis: 25%;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-blue-800);

    .events-title {
      @include font-h2;

      text-transform: uppercase;

      @media (max-width: 768px) {
        @include font-h3;
      }
    }
  }

  .create-event-button {
    width: 40px;
    height: 40px;
    background-color: var(--color-blue-800) !important;

    .icon-plus {
      width: 24px;

      @media (max-width: 768px) {
        width: 20px;
      }
    }

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }

  .controls-container {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-template:
      'tabs tabs tabs tabs tabs tabs'
      'filter search search search search views';
    place-items: center center;
    padding: 40px 48px 0;
    row-gap: 60px;

    @media (max-width: $screen-sm) {
      grid-template:
        'filter filter filter filter filter filter'
        'views views views views views views'
        'select select select select select select'
        'search search search search search search';
      justify-items: initial;
      padding-top: 28px;
      row-gap: 0;
    }

    .period-select {
      display: none;
      grid-area: select;
      padding-top: 25px;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;

      @media (max-width: $screen-sm) {
        display: block;
      }
    }

    .period-tabs {
      display: flex;
      grid-area: tabs;
      justify-content: center;
      box-sizing: border-box;

      @media (max-width: $screen-sm) {
        display: none;
      }

      .period-button {
        width: 216px;
        height: 52px;
        color: var(--color-dark-800);
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        background: var(--color-white);
        transition: all 0.25s ease-out;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        &.active {
          color: var(--color-white);
          background: var(--color-grey-300);
        }
      }
    }

    .filter-bar {
      display: flex;
      grid-area: filter;
      justify-self: flex-start;
      min-width: 200px;

      .sort {
        display: flex;
        color: var(--color-white);

        .sort-arrows {
          display: flex;
          flex-direction: column;
          margin-left: 12px;

          .icon-up {
            top: -6px;
          }

          .icon-down {
            top: -12px;
          }
        }
      }
    }

    .search {
      display: none;
      grid-area: search;
      padding-top: 20px;

      input::placeholder {
        color: var(--color-grey-500);
      }

      // stylelint-disable no-descending-specificity
      // TODO: fix stylelint errors

      .v-icon {
        cursor: pointer;
      }

      // stylelint-enable no-descending-specificity
      @media (min-width: $screen-sm) {
        width: 29.1667vw;
        max-width: 420px;
        padding-top: 0;
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }
  }
}

.events.light-bg {
  background-color: var(--color-white);

  .background {
    display: none;
  }

  .title-container {
    color: var(--color-blue-800);
  }

  .create-event-button {
    background-color: var(--color-blue-800);
  }
}
</style>
