<template>
  <section class="members">
    <v-progress-circular
      v-if="loading"
      class="loader"
      color="#FC7800"
      indeterminate
      size="280"
      width="15"
    />
    <div
      v-else
      class="content"
    >
      <div class="list">
        <!--          eslint-disable vuejs-accessibility/click-events-have-key-events-->
        <span
          class="back-button back-button--mobile"
          @click="backToEvent"
        >
          <v-icon>{{ mdiChevronLeft }}</v-icon> Back
        </span>
        <!--          eslint-anable vuejs-accessibility/click-events-have-key-events-->
        <h2 class="checkin-title page-title">Check people in</h2>
        <h3 class="dancers-count">{{ participantsCheckedInCount }}/{{ participantsCount }} checked in</h3>

        <v-text-field
          v-model="searchKeyword"
          background-color="var(--color-white)"
          hide-details
          label="Search people"
          outlined
        />
        <v-row class="list-header">
          <v-col
            class="item"
            cols="6"
          >
            User
          </v-col>

          <!--    TODO uncomment when we start to sell tickets online-->
          <!--          <v-col class="item" cols="2">-->
          <!--            Payment type-->
          <!--          </v-col>-->
          <!--          <v-col class="item" cols="3">-->
          <!--            Ticket type + Cost-->
          <!--          </v-col>-->

          <v-col
            class="item status"
            cols="6"
          >
            Status
          </v-col>
        </v-row>
        <CheckInParticipantsRow
          v-for="(participant, index) in participants"
          :key="index"
          :event.sync="event"
          :is-free="isEventFree"
          :participant="participant"
        />
      </div>

      <div class="legend">
        <div class="legend-header">
          <h4 class="legend-title">Key</h4>
          <!--          eslint-disable vuejs-accessibility/click-events-have-key-events-->
          <div
            class="legend-control"
            @click="isShowLegend = !isShowLegend"
          >
            <v-icon v-if="isShowLegend">
              {{ mdiChevronUp }}
            </v-icon>
            <v-icon v-else>
              {{ mdiChevronDown }}
            </v-icon>
          </div>
          <!--          eslint-enable vuejs-accessibility/click-events-have-key-events-->
        </div>
        <div
          v-if="isShowLegend"
          class="points"
        >
          <div class="point">
            <div class="checked-label">
              <v-icon>{{ mdiCheck }}</v-icon>
              Checked-in
            </div>
            <div class="text">
              User has paid for a ticket and has already either checked themselves in or has been checked in
            </div>
          </div>

          <div class="point">
            <v-btn
              class="font-weight-bold"
              color="var(--color-green)"
              dark
              height="32"
              tile
            >
              Check-In
            </v-btn>
            <div class="text">
              User has bought a ticket through Break Konnect or has been given a ticket, but has not checked-in. Judges,
              DJs, hosts, admins, or other staff are given tickets.
            </div>
          </div>

          <div class="point">
            <v-icon class="icon">
              {{ mdiCreditCardOutline }}
            </v-icon>
            <div class="text">This means the user bought their ticket with a credit card.</div>
          </div>

          <div class="point">
            <v-icon class="icon">
              {{ mdiCurrencyUsd }}
            </v-icon>
            <div class="text">This means the user bought their ticket with cash at the door.</div>
          </div>

          <div class="point">
            <v-btn
              class="font-weight-bold"
              color="#6CACE4"
              dark
              height="32"
              tile
            >
              Pay & Check-In
            </v-btn>
            <div class="text">
              User has clicked the “pay with cash at event” option and hasn’t payed yet. Once they pay the admin can
              check them in.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { toDollars } from '@/services/currency'
import CheckInParticipantsRow from '@/components/CheckInParticipantsRow.vue'
import { mdiCheck, mdiChevronDown, mdiChevronLeft, mdiChevronUp, mdiCreditCardOutline, mdiCurrencyUsd } from '@mdi/js'
import { eventApi } from '@/enitites/event'

export default {
  name: 'CheckInParticipants',
  components: {
    CheckInParticipantsRow,
  },
  data() {
    return {
      mdiChevronDown,
      mdiChevronUp,
      mdiChevronLeft,
      mdiCheck,
      mdiCurrencyUsd,
      mdiCreditCardOutline,
      searchKeyword: '',
      isShowLegend: false,
      loading: true,
      event: null,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    eventId() {
      return this.$route.params.id
    },
    participants() {
      if (!this.event.attendees) return []
      if (!this.searchKeyword) return this.event.attendees

      return this.event.attendees.filter((user) =>
        user.dancerName.toLowerCase().includes(this.searchKeyword.toLowerCase())
      )
    },
    participantsCount() {
      if (!this.event.attendees) return 0
      return this.event.attendees.length
    },
    participantsCheckedInCount() {
      if (!this.event.attendees) return 0
      return this.event.attendees.filter((a) => a.checkedIn).length
    },
    participantsWithTickets() {
      return this.event.attendees.filter((attendee) => attendee.hasTicket)
    },
    ticketsTotalSum() {
      return toDollars(
        this.participantsWithTickets.reduce(
          (accumulator, participant) => accumulator + participant.tickets.reduce((acc, ticket) => acc + ticket.cost, 0),
          0
        )
      )
    },
    isEventFree() {
      if (!this.event.tiers.length) return true

      const deltaCost = this.event.tiers.reduce((acc, tier) => acc + tier.cost, 0)
      return deltaCost === 0
    },
  },
  async mounted() {
    if (this.$vuetify.breakpoint.mdAndUp) this.isShowLegend = true
    await this.initEvent()
    this.loading = false
  },
  methods: {
    ...mapActions(['setIsNotFound']),
    async initEvent() {
      this.event = null
      const event = await eventApi.getEvent(this.eventId)
      if (!event) {
        this.setIsNotFound(!this.event)
        return
      }

      this.event = event
    },
    participantsFilter(item, queryString) {
      return item.dancerName.toLocaleLowerCase().indexOf(queryString.toLocaleLowerCase()) > -1
    },
    backToEvent() {
      this.$router.push({ name: 'Event', params: { id: this.event.id } })
    },
  },
}
</script>

<style lang="scss">
.v-application--is-ltr .tier-item .v-input--selection-controls__input {
  margin-right: 0 !important;
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.legend {
  padding: 12px;
  border-radius: var(--border-radius-rounded);
  box-shadow: 0 4px 12px rgb(0 0 0 / 12%);

  .legend-header {
    display: flex;
    justify-content: space-between;

    & .legend-control {
      cursor: pointer;
    }
  }

  .points {
    margin-top: 16px;
  }

  .point {
    margin-bottom: 16px;

    & .icon {
      color: var(--color-black);
    }

    & .text {
      margin-top: 6px;
      color: var(--color-grey-300);
    }
  }
}

.loader {
  display: flex !important;
  margin: 210px auto;
}

.members {
  background-color: var(--color-grey-100) !important;
}

.content {
  .content {
    @include page-with-content;
  }

  position: relative;
  display: flex;
  max-width: 1100px;
  margin: 180px auto 0;

  @media (max-width: $screen-md) {
    flex-direction: column-reverse;
    margin-top: 100px;
    padding: 0 20px;
  }

  .list {
    flex-grow: 1;
    margin-right: 40px;

    @media (max-width: $screen-md) {
      margin-right: 0;
    }
  }

  .legend {
    width: 100%;
    max-width: 305px;
    height: fit-content;
    background-color: var(--color-white);

    @media (max-width: $screen-md) {
      max-width: unset;
      margin-bottom: 20px;
    }
  }
}

.back-button {
  position: absolute;
  top: 120px;
  right: 77%;
  font-weight: var(--font-weight-extra-bold);
  cursor: pointer;

  &--mobile {
    position: static;
  }
}

.member-link {
  color: black;
}

.checked-label {
  margin-left: auto;
  color: var(--color-green);

  .v-icon {
    color: var(--color-green);
  }
}

.card {
  position: relative;
  height: 100%;
  min-height: 570px;
  margin: 120px auto;
}

.checkin-title {
  @include font-h1;
}

.dancers-count {
  @include font-h3;

  margin: 12px 0;
}

.list-header {
  margin-bottom: 30px;

  @media (max-width: $screen-md) {
    display: none;
  }

  .item {
    @include font-body2;

    font-weight: var(--font-weight-bold);
  }

  .status {
    text-align: end;
  }
}
</style>
