<template>
  <div>
    <header :class="headerClass">
      <div class="header__wrap row">
        <nav class="header__links col-auto">
          <div class="header__links-row">
            <button
              v-if="!isSignupRoute && !isEventCreateRoute && !isEventCreateRoute"
              class="header__menu-btn"
              @click="toggleMenu"
            >
              <img
                v-show="isHomePage && scrolled < 151"
                alt="menu btn"
                class="header__menu-img"
                src="/img/ic_Menu%20Hamburger.svg"
              />
              <img
                v-show="!isHomePage || (isHomePage && scrolled >= 151)"
                alt="menu btn"
                class="header__menu-img"
                src="/img/ic_Menu%20Hamburger_Black.svg"
              />
            </button>
            <div
              :class="{ 'header__menu--active': isShowMenu }"
              class="header__menu"
            >
              <router-link
                :to="{ name: 'EventsGrid' }"
                class="header__link header__link--mobile"
                @click="toggleMenu"
              >
                Events
              </router-link>
              <router-link
                class="header__link header__link--mobile"
                to="/members"
                @click="toggleMenu"
              >
                Members
              </router-link>
              <router-link
                class="header__link header__link--mobile"
                to="/organizations"
                @click="toggleMenu"
              >
                Organizations
              </router-link>
              <router-link
                class="header__link header__link--mobile"
                to="/faq"
                @click="toggleMenu"
              >
                Faqs
              </router-link>
              <router-link
                class="header__link header__link--mobile"
                to="/patreon"
                @click="toggleMenu"
              >
                Patreon
              </router-link>
            </div>
            <router-link
              v-if="!isEventCreateRoute"
              class="header__logo-link"
              to="/"
            >
              <img
                v-show="isHomePage && scrolled < 151"
                alt="Break Konnect"
                class="header__logo"
                src="/img/header-logo-white.svg"
              />
              <img
                v-show="!isHomePage || (isHomePage && scrolled >= 151)"
                alt="Break Konnect"
                class="header__logo"
                src="/img/header-logo.svg"
              />
            </router-link>
            <template v-if="!isSignupRoute && !isEventCreateRoute">
              <router-link
                :to="{ name: 'EventsGrid' }"
                class="header__link"
              >
                Events
              </router-link>
              <router-link
                class="header__link"
                to="/organizations"
              >
                Organizations
              </router-link>
              <router-link
                class="header__link"
                to="/members"
              >
                Members
              </router-link>
              <router-link
                class="header__link"
                to="/faq"
                @click="toggleMenu"
              >
                Faqs
              </router-link>
              <router-link
                class="header__link"
                to="/patreon"
                @click="toggleMenu"
              >
                Patreon
              </router-link>
            </template>
          </div>
        </nav>
        <div
          v-if="!getUserDB && !isSignupRoute"
          class="header__actions"
        >
          <router-link
            class="login-link"
            to="/login"
          >
            <button
              type="button"
              @click="closeMenuAndUserAlert"
            >
              Log in
            </button>
          </router-link>
          <router-link
            class="signup-link"
            to="/signup"
          >
            <v-btn
              :class="{ home: !isWhiteHeader }"
              :color="isWhiteHeader ? '#041e42' : 'var(--color-white)'"
              class="create-profile-button"
              height="32"
              width="142"
              @click="closeMenuAndUserAlert"
            >
              Create profile
            </v-btn>
          </router-link>
        </div>
        <div
          v-if="isSignupRoute"
          class="header__actions"
        >
          <HeaderLogoutButton />
        </div>
        <div
          v-if="getUserDB && !isEventCreateRoute && !isSignupRoute"
          class="user col-auto"
        >
          <v-menu
            v-model="showNotificationMenu"
            :close-on-content-click="false"
            :disabled="notifications.length < 1"
            class="notification-menu"
            left
            min-width="320"
            offset-overflow
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                :class="notifications.length < 1 ? 'inactive' : 'active'"
                class="notification-button"
                v-bind="attrs"
                @click="toggleNotificationMenu"
                v-on="on"
              >
                <v-badge
                  :value="notificationsCount !== 0"
                  class="notification-badge"
                  offset-x="17"
                  offset-y="17"
                >
                  <template v-slot:badge>
                    <div class="counter">
                      {{ notificationsCount }}
                    </div>
                  </template>
                  <v-img
                    :src="isWhiteHeader ? '/img/ic_Bell_Blue.svg' : '/img/ic_Bell.svg'"
                    alt="notifications"
                    class="icon"
                    height="24"
                    width="22"
                  />
                </v-badge>
              </button>
            </template>
            <div class="notification-popup">
              <div class="notification-title">
                <img
                  alt="notification icon"
                  class="notification-icon"
                  src="/img/ic_Bell_Red.svg"
                />
                <div class="text">
                  {{ getPluralString(notifications.length, 'new message') }}
                </div>
              </div>
              <div class="notification-list">
                <div
                  v-for="notification in notifications"
                  :key="notification.id"
                  class="notification-card"
                >
                  <div class="notification-header">
                    <button
                      class="notification-text"
                      type="button"
                      @click="onClickNotification(notification)"
                    >
                      {{ notification.text }}
                    </button>
                    <button
                      class="close-button"
                      @click.stop="readUserNotification(notification, true)"
                    >
                      <img
                        alt="close"
                        class="icon"
                        src="/img/ic_Close_Red.svg"
                      />
                    </button>
                  </div>
                  <div class="notification-info">
                    <div class="event-type">
                      {{ notification.notificationType }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-menu>
          <v-menu
            v-model="isShowUserAlert"
            :close-on-content-click="false"
            class="user-menu"
            left
            min-width="308"
            offset-overflow
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                v-bind="attrs"
                @click="toggleUserAlert"
                v-on="on"
              >
                <v-avatar
                  class="avatar"
                  size="32"
                >
                  <v-img
                    :src="getAvatar(getUserDB.avatar, 32)"
                    alt="avatar"
                    class="icon"
                  />
                </v-avatar>
              </button>
            </template>
            <UserAlert />
          </v-menu>
        </div>
      </div>
    </header>
    <div
      ref="checkInAlert"
      :class="alertClass"
    >
      <v-alert
        v-if="getAlertToastMessage"
        :class="getAlertToastMessage.status"
        :type="getAlertToastMessage.status"
        :value="true"
        class="header__check-in-alert-content font-weight-bold transition-swing"
        tile
        transition="slide-y-transition"
      >
        <span class="header__check-in-alert-text"> {{ getAlertToastMessage.message }}</span>
      </v-alert>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { useRoute } from 'vue-router/composables'
import UserAlert from '@/widgets/Header/ui/UserAlert.vue'
import { userApi, UserNotification } from '@/enitites/user'
import { useImage } from '@/shared/composables/image'
import { getPluralString } from '@/services/pluralization'
import { HeaderLogoutButton } from '@/features/authorization'

const route = useRoute()
const { getAvatar } = useImage()
const getAlertToastMessage = computed(() => store.getters.getAlertToastMessage)
const getUserDB = computed(() => store.getters.getUserDB)

const scrolled = ref(window.scrollY)
const scrollPrev = ref(0)
const isShowMenu = ref(false)
const isShowUserAlert = ref(false)
const showNotificationMenu = ref(false)
const headerClass = ref('header')
const alertClass = ref('header__check-in-alert')
const notifications = ref<UserNotification[]>([])
const documentEl = document.documentElement

const isSignupRoute = computed(() => route.name === 'Signup' && store.getters.getUserDB)
const isEventCreateRoute = computed(() => route.name === 'CreateEventProcessing' && store.state.isCreateEventFlow)
const isHomePage = computed(() => route.name === 'Home')
const notificationsCount = computed(() => notifications.value.length)
const isWhiteHeader = computed(() => (isHomePage.value && scrolled.value > 151) || !isHomePage.value)
const getUserNotifications = async () => {
  if (!store.getters.getUserDB) return
  notifications.value = await userApi.getNotifications()
}

const readUserNotification = async (notification: UserNotification, silent: boolean = false) => {
  await userApi.readNotification(notification.id)

  if (silent) {
    notifications.value = notifications.value.filter((n) => n.id !== notification.id)
    return
  }

  showNotificationMenu.value = false
  window.location.href = notification.link
}

const onClickNotification = (notification: UserNotification) => {
  readUserNotification(notification)
  window.location.href = notification.link
}

const toggleNotificationMenu = () => {
  showNotificationMenu.value = !showNotificationMenu.value
  isShowUserAlert.value = false
  isShowMenu.value = false
  documentEl.classList.remove('no-scroll')
}

const toggleUserAlert = () => {
  isShowUserAlert.value = !isShowUserAlert.value
  isShowMenu.value = false
  showNotificationMenu.value = false
  documentEl.classList.remove('no-scroll')
}

const toggleMenu = () => {
  isShowMenu.value = !isShowMenu.value
  documentEl.classList.toggle('no-scroll')
  isShowUserAlert.value = false
  showNotificationMenu.value = false
}

const closeMenuAndUserAlert = () => {
  isShowMenu.value = false
  isShowUserAlert.value = false
}

const getHeaderClass = () => {
  if (isHomePage.value && scrolled.value < 151) return 'header header--up-page'
  if (isHomePage.value && scrolled.value >= 151 && scrolled.value > scrollPrev.value) return 'header header--up'
  if (!isHomePage.value && scrolled.value > 50 && scrolled.value > scrollPrev.value) return 'header header--up'
  return 'header'
}

const getAlertClass = () => {
  if (isHomePage.value && scrolled.value < 151) return 'header__check-in-alert--top'
  if (isHomePage.value && scrolled.value >= 151 && scrolled.value > scrollPrev.value)
    return 'header__check-in-alert--top'
  if (!isHomePage.value && scrolled.value > 50 && scrolled.value > scrollPrev.value)
    return 'header__check-in-alert--top'
  return 'header__check-in-alert'
}

const handlerChangeHeader = () => {
  scrolled.value = window.scrollY
  headerClass.value = getHeaderClass()
  alertClass.value = getAlertClass()
  scrollPrev.value = scrolled.value
}

watch(isHomePage, () => {
  handlerChangeHeader()
})

watch(
  () => route.name,
  () => {
    getUserNotifications()
    closeMenuAndUserAlert()
    documentEl.classList.remove('no-scroll')
  }
)

onMounted(async () => {
  await getUserNotifications()
  handlerChangeHeader()
  document.addEventListener('scroll', handlerChangeHeader)
  document.addEventListener('scroll', () => {
    if (isShowUserAlert.value) isShowUserAlert.value = false
    if (showNotificationMenu.value) showNotificationMenu.value = false
  })
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.notification-popup {
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  background: white;

  .notification-title {
    display: inline-flex;
    align-items: center;

    .notification-icon {
      width: 20px;
      height: 20px;
    }

    .text {
      margin-left: 8px;
      color: var(--rasberry-red);
    }
  }

  .notification-list {
    max-height: 369px;
    padding-top: 8px;
    overflow: hidden auto;

    .notification-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 268px;
      margin-top: 8px;
      padding: 12px 14px 12px 12px;
      color: var(--rasberry-red);
      border: 2px solid var(--rasberry-red);
      border-radius: 6px;
      box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      .notification-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .notification-text {
          color: var(--rasberry-red);
        }

        .close-button {
          width: 24px;
          height: 24px;
        }
      }

      .notification-info {
        margin-top: 6px;

        .event-type {
          margin-top: 4px;
          font-weight: var(--font-weight-bold);
        }
      }
    }
  }
}

.header {
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: 2720px;
  height: 82px;
  margin-right: auto;
  margin-left: auto;
  padding: 18px 50px;
  color: #041e42;
  font-weight: 700;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  background: #fff;
  transition: all 0.3s ease;

  @media (max-width: $screen-sm) {
    padding: 18px 10px;
  }

  .login-link {
    margin: 0 20px 0 0;
    color: var(--color-blue-800);

    button {
      font: inherit;
      text-transform: uppercase;
    }
  }

  .signup-link {
    color: var(--color-white);
  }

  .create-profile-button {
    color: var(--color-white);
    font-weight: var(--font-weight-bold);

    @include font-body2;

    &.home {
      color: var(--color-blue-800);
    }

    .icon-plus {
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }

  .user {
    position: relative;
    display: flex;
    align-items: center;

    .notification-button {
      width: 24px;
      height: 24px;
      padding: 0;
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      cursor: default;
      user-select: none;

      &.active {
        cursor: pointer;
      }

      .notification-badge {
        .counter {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          font-weight: var(--font-weight-bold);
          background-color: var(--rasberry-red);
          border: 1px solid var(--color-white);
          border-radius: 50%;

          @include font-caption2;
        }
      }
    }

    .avatar {
      margin-left: 20px;
      border: 2px solid var(--color-white);
      cursor: pointer;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }

  &--up {
    transform: translateY(-100%);
  }

  &__link {
    display: none;
    margin: 10px;
    color: var(--color-blue-800);
    text-decoration: none;

    @media (min-width: $screen-sm) {
      display: inline;
    }

    &--mobile {
      display: inline;
      color: var(--color-dark-800);
      text-transform: uppercase;

      @include font-h2;
    }
  }

  &--up-page {
    color: var(--color-white);
    background: transparent;

    & .header__link:not(.header__link--mobile) {
      color: var(--color-white);
    }

    & .login-link {
      color: var(--color-white);
    }
  }

  &__menu-btn {
    margin-right: 15px;

    @media (min-width: $screen-sm) {
      display: none;
    }
  }

  &__menu {
    position: fixed;
    top: 78px;
    left: -100vw;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 120vh;
    padding: 20px;
    overflow-y: hidden;
    background-color: #fff;
    transition: left 0.2s ease-out;
  }

  &__menu--active {
    left: 0;
  }

  &__button {
    margin: 10px;
    color: var(--color-blue-800) !important;
    font-weight: 700 !important;
    letter-spacing: 0.3px;

    @include font-body1;

    &::before {
      display: none;
    }
  }

  &__links {
    padding: 0;
  }

  &__links-row {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 62px;
    height: 30px;

    @media (min-width: $screen-sm) {
      width: 86px;
      height: 42px;
    }
  }

  &__actions {
    padding: 5px 0;
    font-weight: bold;
    text-transform: uppercase;

    &-separator {
      position: relative;
      top: 5px;
      display: inline-block;
      width: 2px;
      height: 22px;
      background: #041e42;
    }

    &-link {
      margin: 0 24px;
      color: var(--color-blue-800);
      text-decoration: none;
      cursor: pointer;

      @media screen and (max-width: $screen-sm) {
        margin: 0 10px;
      }
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 6px 0 rgb(34 34 34 / 50%);
    cursor: pointer;
  }

  &--up-page &__actions-separator {
    background: var(--color-white);
  }

  &__check-in-alert {
    position: fixed;
    top: 92px;
    z-index: 5;
    width: 100%;
    cursor: pointer;

    &-content {
      color: var(--color-white) !important;

      &.success {
        background-color: var(--color-green-dark) !important;
        border-color: var(--color-green-dark) !important;
      }

      &.error {
        background-color: var(--color-orange-500) !important;
        border-color: var(--color-orange-500) !important;
      }
    }

    &-text {
      margin-left: 16px;
      font-weight: var(--font-weight-bold);
    }

    &--top {
      position: fixed;
      z-index: 5;
      width: 100%;
      cursor: pointer;
    }
  }
}

.user-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 54px;
  background: var(--color-white);
  border-radius: 6px;

  .photo {
    min-height: 100px;
  }

  .name {
    margin-top: 8px;
    overflow: hidden;
    color: var(--color-dark-800);
    text-align: center;
    word-wrap: break-word;
  }
}

.v-menu__content {
  top: 40px !important;
}
</style>
