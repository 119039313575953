import { render, staticRenderFns } from "./EventsPage.vue?vue&type=template&id=91f6790a&scoped=true"
import script from "./EventsPage.vue?vue&type=script&lang=ts&setup=true"
export * from "./EventsPage.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./EventsPage.vue?vue&type=style&index=0&id=91f6790a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91f6790a",
  null
  
)

export default component.exports