// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'

// eslint-disable-next-line import/prefer-default-export
export function useErrorHandlers() {
  function geoErrorHandler(error: GeolocationPositionError) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        store.commit('SHOW_ALERT_TOAST_MESSAGE', 'Please allow access to your location.')
        setTimeout(() => store.commit('SHOW_ALERT_TOAST_MESSAGE', ''), 5000)
        break
      case error.POSITION_UNAVAILABLE:
        store.commit('SHOW_ALERT_TOAST_MESSAGE', 'Location information is unavailable.')
        setTimeout(() => store.commit('SHOW_ALERT_TOAST_MESSAGE', ''), 5000)
        break
      case error.TIMEOUT:
        store.commit('SHOW_ALERT_TOAST_MESSAGE', 'The request to get location timed out.')
        setTimeout(() => store.commit('SHOW_ALERT_TOAST_MESSAGE', ''), 5000)
        break
      default:
        store.commit('SHOW_ALERT_TOAST_MESSAGE', 'An unknown error occurred.')
        setTimeout(() => store.commit('SHOW_ALERT_TOAST_MESSAGE', ''), 5000)
        break
    }
  }

  return {
    geoErrorHandler,
  }
}
