<template>
  <section
    ref="createEvent"
    class="create-event"
  >
    <div class="create-event__background" />
    <div class="create-event__wrapper">
      <div class="create-event__container">
        <h2 class="create-event__title page-title">Create Event</h2>
        <p class="create-event__description px-2">
          All fields here are required, unless otherwise noted as optional, to be filled out to move on to the next step
        </p>
        <v-progress-circular
          v-if="loading"
          class="create-event__loader"
          color="primary"
          indeterminate
          size="50"
        />
      </div>
      <transition
        :name="isClickBtnNext ? 'slide-fade' : 'slide-fade-revers'"
        appear
        mode="out-in"
      >
        <component
          :is="currentScreen"
          :back-step.sync="backStep"
          :event-data.sync="eventData"
          :is-new="isNew"
          :screen.sync="currentScreen"
          class="create-event__screen"
          @publishEvent="publishEvent"
          @saveAsDraft="handlerSaveAsDraft"
          @updateEvent="updateEvent"
        />
      </transition>
      <span
        v-show="error"
        class="create-event__error"
        >{{ error }}</span
      >
    </div>
  </section>
</template>

<script>
import api from '@/api'

import { mapActions, mapGetters } from 'vuex'

import CreateEventInfo from '@/components/CreateEventInfo.vue'
import CreateEventDate from '@/components/CreateEventDate.vue'
import CreateEventTiers from '@/components/CreateEventTiers.vue'
import CreateEventBattles from '@/components/CreateEventBattles.vue'
import CreateEventTotal from '@/components/CreateEventTotal.vue'
import { eventApi } from '@/enitites/event'

export default {
  components: {
    CreateEventInfo,
    CreateEventDate,
    CreateEventTiers,
    CreateEventBattles,
    CreateEventTotal,
  },
  data() {
    return {
      isClickBtnNext: true,
      currentScreen: '',
      eventData: {},

      backStep: false,
      loading: true,
      error: null,

      isNew: true,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
  },
  updated() {
    this.$refs.createEvent.scrollIntoView()
  },
  created() {
    this.initEvent()
  },
  methods: {
    ...mapActions(['setIsCreateEventFlow', 'showAlertToast']),
    async initEvent() {
      if (!this.getUserDB) {
        this.$router.push({ name: 'EventsPage' })
        return
      }

      const event = await eventApi.getEvent(Number(this.$route.params.id))

      if (!event) {
        this.loading = false
        this.$router.push({ name: 'EventsPage' })
        return
      }
      this.eventData = event

      if (this.eventData.title) {
        this.isNew = false
        this.currentScreen = 'CreateEventTotal'
        this.loading = false
      } else {
        this.setIsCreateEventFlow(true)
        this.currentScreen = 'CreateEventInfo'
        this.loading = false
      }
    },
    async updateEvent() {
      if (this.eventData.description) {
        this.eventData.description = this.$sanitize(this.eventData.description)
      }
      await api.events.updateEvent(this.eventData)
    },
    async publishEvent() {
      await api.events.publishEvent(this.eventData.id)
      this.showAlertToast('eventCreated')
      this.$router.push({ name: 'Event', params: { id: this.eventData.id } })
    },
    async handlerSaveAsDraft() {
      await api.events.updateEvent(this.eventData)
      this.showAlertToast('eventCreated')
      this.$router.push({ name: 'Event', params: { id: this.eventData.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/slideFade';
@import '@/assets/style/mixins';

.create-event {
  height: 100%;
  background-color: rgb(255 255 255);

  &__background {
    height: 488px;
    background: #fff url('/img/HeroCE.jpg') 60% 95% / cover no-repeat;
  }

  &__error {
    color: red;
  }

  &__loader {
    margin-top: 40px;
    margin-left: 45%;
  }

  &__wrapper {
    width: 100%;
    min-height: 500px;
    margin: -270px 0 auto;
    padding: 0 20px;
  }

  &__container {
    max-width: 400px;
    margin: 0 auto;
  }

  &__title {
    @include font-h2;

    text-align: center;
    text-transform: uppercase;
  }

  &__description {
    @include font-body2;

    color: var(--color-dark-800);
    text-align: center;
  }
}
</style>
