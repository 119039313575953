<script lang="ts" setup>
import BKImage from '@/components/AppShared/BKImage.vue'
import BKSvgIcon from '@/components/AppShared/BKSvgIcon.vue'
import { useImage } from '@/shared/composables/image'
import { CalendarEvent } from '../model/types'

const { getImageById } = useImage()

interface IProps {
  eventsData: CalendarEvent[]
  activeMobileList: boolean
}

defineProps<IProps>()
const emit = defineEmits<{ (e: 'hide-events'): void }>()

function getReformattedDate(date: Date): string {
  return date
    .toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(/ /g, '')
}
</script>

<template>
  <div
    v-if="eventsData.length"
    ref="mobileEventsList"
    :class="['calendar-mobile-events-list', 'events-mobile-container', { active: activeMobileList }]"
  >
    <router-link
      v-for="event in eventsData"
      :key="event.id"
      :to="{ name: 'Event', params: { id: event.id } }"
      class="event-link"
    >
      <BKImage
        :src="getImageById(event.img, 90, 90, 'event')"
        alt=""
        class="event-img"
        height="45"
        width="45"
      />
      <div class="event-description-wrapper">
        <span class="event-time">
          <template v-if="event.start">
            {{ getReformattedDate(event.start) }}
          </template>
          <template v-if="event.optionalEnd">
            -
            {{ getReformattedDate(event.optionalEnd) }}
          </template>
        </span>
        <span class="event-title">{{ event.title || 'Untitled' }}</span>
      </div>
    </router-link>
    <button
      class="hide-btn"
      type="button"
      @click="emit('hide-events')"
    >
      <BKSvgIcon
        class="icon"
        color="#000"
        height="24px"
        name="arrow-with-stem"
        width="24px"
      />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.calendar-mobile-events-list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  overflow: hidden scroll;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  &.active {
    transition: height 0.5s ease-in-out;
  }

  .event-link {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .event-description-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    overflow: hidden;
  }

  .event-img {
    display: flex !important;
    border-radius: 4px;
  }

  .event-time {
    color: #666;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    text-transform: uppercase;
  }

  .event-title {
    max-width: 292px;
    overflow: hidden;
    color: #333;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-align: left;
    text-transform: uppercase;
    text-overflow: ellipsis;
  }

  .hide-btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    padding: 12px;
    background-color: #fff;
    border-radius: 50%;

    .icon {
      transform: rotate(-90deg);
    }
  }
}
</style>
