<script lang="ts" setup>
import { useImage } from '@/shared/composables/image'
import { EventDB } from '@/enitites/event'
import { EventBaseCard } from '@/shared/ui/cards'

const { getImageById } = useImage()
defineProps<{
  previewEvent: EventDB
}>()
</script>

<template>
  <router-link
    :to="{ name: 'Event', params: { id: previewEvent.id } }"
    class="event-card-mobile"
  >
    <EventBaseCard>
      <template #img>
        <v-img
          :src="getImageById(previewEvent.image, 800, 800, 'event')"
          alt=""
          class="img"
        />
      </template>
      <template #title>
        <h5 class="event-card-title">
          {{ previewEvent.title }}
        </h5>
      </template>
      <template #date>
        <p class="event-card-date">
          {{ previewEvent.dateStart.toLocaleDateString('en-US') }}
        </p>
      </template>
    </EventBaseCard>
  </router-link>
</template>
