<template>
  <div class="staff">
    <div v-if="event.staff.organizer">
      <p class="role">Organizer</p>
      <p class="chip">
        <EventStaffUserChip :user="event.staff.organizer" />
      </p>
    </div>

    <div v-if="event.staff.admins && event.staff.admins.length">
      <p class="role">Admins</p>
      <p
        v-for="(admin, i) in event.staff.admins"
        :key="i + 'Admins'"
        class="chip"
      >
        <EventStaffUserChip :user="admin" />
      </p>
    </div>

    <div v-if="event.staff.hosts && event.staff.hosts.length">
      <p class="role">Host</p>
      <p
        v-for="(host, i) in event.staff.hosts"
        :key="i + 'hosts'"
        class="chip"
      >
        <EventStaffUserChip :user="host" />
      </p>
    </div>

    <div v-if="event.staff.djs && event.staff.djs.length">
      <p class="role">Djs</p>
      <p
        v-for="(dj, i) in event.staff.djs"
        :key="i + 'djs'"
        class="chip"
      >
        <EventStaffUserChip :user="dj" />
      </p>
    </div>
    <p class="role">Share on social media</p>
    <div class="social">
      <a
        :href="facebookUrl"
        class="social-link"
        target="_blank"
        title="Share on Facebook"
      >
        <v-icon
          class="sociall-link__icon"
          color="black"
        >
          {{ mdiFacebook }}
        </v-icon>
      </a>
      <a
        :href="twitterUrl"
        class="social-link"
        target="_blank"
        title="Share on Twitter"
      >
        <v-icon
          class="sociall-link__icon"
          color="black"
        >
          {{ mdiTwitter }}
        </v-icon>
      </a>
    </div>

    <div v-if="event.whoIsGoing && event.whoIsGoing.length">
      <p class="role">
        <span v-if="event.period !== 'past'">Who’s going</span>
        <span v-else>Who went</span>
        <v-btn
          v-if="event.whoIsGoing.length > 5"
          class="btn-link px-2"
          color="#1976d2"
          text
          @click="isShowWhoIsGoing = true"
        >
          view all
        </v-btn>
      </p>
      <div class="d-flex">
        <p
          v-for="(member, i) in event.whoIsGoing.slice(0, 5)"
          :key="i + 'member'"
          class="chip"
        >
          <router-link
            :to="{ name: 'Profile', params: { id: member.id } }"
            class="user-link"
          >
            <v-avatar
              class="mr-1"
              size="48px"
            >
              <v-img :src="getImageById(member.avatar, 48, 48, 'avatar')" />
            </v-avatar>
          </router-link>
        </p>
      </div>
    </div>

    <EventAllMembers
      :is-past-event="event.period === 'past'"
      :is-show-modal.sync="isShowWhoIsGoing"
      :who-is-going="event.whoIsGoing"
    />
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getDateRangeFormatted, getTimeRangeFormatted } from '@/services/date'
import { mdiFacebook, mdiTwitter } from '@mdi/js'
import { EventDB } from '@/enitites/event'
import { ref } from 'vue'
import { useImage } from '@/shared/composables/image'
import EventStaffUserChip from './EventStaffUserChip.vue'
import EventAllMembers from './EventAllMembers.vue'

const { getImageById } = useImage()
const props = defineProps<{
  event: EventDB
}>()

const isShowWhoIsGoing = ref(false)
const eventUrl = window.location.href
const twitterUrl = `https://twitter.com/intent/tweet?text=${props.event.title} - ${getDateRangeFormatted(
  props.event.dateStart,
  props.event.dateEnd
)} - ${getTimeRangeFormatted(props.event.dateStart, props.event.dateEnd)} - ${props.event.locationAddress}&url=${eventUrl}`
const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(eventUrl)}`
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.staff {
  width: 100%;
  max-width: 340px;
  padding: 24px;

  @media (max-width: 920px) {
    max-width: 100%;
    padding: 0;
  }
}

.user-link {
  color: black;
}

.chip {
  position: relative;
  display: block;
  max-width: 265px;
  height: 34px !important;
  margin: 6px 0;
}

.btn-link {
  margin-top: -5px;
  text-transform: none;

  & ::v-deep .v-btn__content {
    @include font-body1;
  }
}

.role {
  margin: 20px 0 0;
  font-weight: var(--font-weight-bold);
}

.social {
  display: flex;
  justify-content: space-between;
  max-width: 80px;
  margin-top: 10px;
}

.organizer-avatar {
  margin: 10px 0 5px;
}
</style>
