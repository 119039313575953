<template>
  <section class="start-signup-form-container">
    <BKButton
      :loading="loadingGoogleBtn"
      class="signup-btn-google"
      outlined
      @click="userLoginGoogle"
    >
      <img
        alt="Google"
        src="/img/google-icon-full-color.svg"
      />
      Create profile with google
    </BKButton>
    <p class="subtitle">Or use your email</p>
    <v-form
      v-model="valid"
      class="start-signup-form"
    >
      <v-text-field
        ref="signup-email"
        v-model="email"
        :rules="[rules.required, rules.email]"
        autocomplete="off"
        background-color="var(--color-white)"
        class="start-signup-form__field"
        hide-details
        label="Email*"
        outlined
        @input="nextTick(() => (email = email?.trim()))"
        @keyup.enter="nextTick(() => signupPassRef?.focus())"
      />
      <p class="start-signup-form__title">Password</p>
      <v-text-field
        ref="signupPassRef"
        v-model="pass"
        :append-icon="showPassword ? mdiEye : mdiEyeOff"
        :rules="[rules.required]"
        :type="showPassword ? 'text' : 'password'"
        autocomplete="off"
        background-color="#fff"
        class="start-signup-form__field"
        hide-details
        label="Create password*"
        outlined
        @keyup.enter="nextTick(() => signupConfirmPassRef?.focus())"
        @click:append="showPassword = !showPassword"
      />
      <v-text-field
        ref="signupConfirmPassRef"
        v-model="confirmPass"
        :append-icon="showPassword ? mdiEye : mdiEyeOff"
        :rules="[rules.required]"
        :type="showPassword ? 'text' : 'password'"
        autocomplete="off"
        background-color="#fff"
        class="start-signup-form__field"
        hide-details
        label="Confirm password*"
        outlined
        @keyup.enter="signupPasAndMail"
        @click:append="showPassword = !showPassword"
      />
      <BKButton
        :disabled="loading || btnDisabled"
        :loading="loading"
        white-text
        @click="signupPasAndMail"
      >
        Create profile
      </BKButton>
      <span
        v-show="error"
        class="start-signup-form__text-error"
      >
        {{ error }}
      </span>
      <span class="start-signup-form__text">
        Already have an account?
        <router-link
          class="start-signup-form__link"
          to="/login"
        >
          Log in here!
        </router-link>
      </span>
    </v-form>
  </section>
</template>

<script lang="ts" setup>
import BKButton from '@/components/AppShared/BKButton.vue'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import useValidationRules from '@/shared/composables/validation'
import { defineEmits, nextTick, ref, watch } from 'vue'

const emit = defineEmits<{
  (e: 'signup'): void
}>()
const rules = useValidationRules()
const loading = ref(false)
const loadingGoogleBtn = ref(false)
const showPassword = ref(false)
const email = ref<string>()
const pass = ref<string>()
const confirmPass = ref<string>()
const error = ref<string>()
const valid = ref(true)
const btnDisabled = ref(false)
const signupConfirmPassRef = ref<HTMLInputElement | null>(null)
const signupPassRef = ref<HTMLInputElement | null>(null)
const auth = getAuth()

async function signupPasAndMail() {
  if (!auth || !email.value || !pass.value) return
  try {
    loading.value = true
    if (pass.value !== confirmPass.value) throw new Error('Passwords must be equal')
    await createUserWithEmailAndPassword(auth, email.value, pass.value)
    if (!auth.currentUser) throw new Error('User not found')
    error.value = undefined
    emit('signup')
  } catch (errorMessage) {
    if (errorMessage instanceof Error && errorMessage.message.includes('already in use')) {
      error.value = errorMessage.message
    }
  } finally {
    loading.value = false
  }
}

async function userLoginGoogle() {
  loadingGoogleBtn.value = true
  try {
    const provider = new GoogleAuthProvider()
    await signInWithPopup(auth, provider)
  } catch (errorMessage) {
    if (errorMessage instanceof Error) {
      error.value = errorMessage.message
    }
  } finally {
    loadingGoogleBtn.value = false
  }
}

watch(valid, (value) => {
  btnDisabled.value = !value
})
</script>

<style lang="scss" scoped>
.start-signup-form-container {
  display: grid;
  gap: 20px;

  .signup-btn-google {
    & ::v-deep .v-btn__content {
      color: var(--color-dark-800);
    }

    & ::v-deep .button-text {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .subtitle {
    margin: 0;
    text-align: center;
  }

  .start-signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 420px;

    &__title {
      width: 100%;
      margin-bottom: 8px;
    }

    &__field {
      width: 100%;
      margin-bottom: 8px !important;
      border-radius: 6px !important;

      &:first-child {
        margin-bottom: 20px !important;
      }

      &:last-of-type {
        margin-bottom: 40px !important;
      }
    }

    &__text-error {
      color: red;
    }

    &__btn-log-social {
      margin-bottom: 12px;
      font-weight: bold;
      border: 2px solid;
    }

    &__text {
      margin: 15px 0;
      color: #747474;
    }

    &__link {
      color: #38a1f3;
      text-decoration: none;
    }
  }
}
</style>
