<script lang="ts" setup>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import BKImage from '@/components/AppShared/BKImage.vue'
import { useImage } from '@/shared/composables/image'
import { computed, ref } from 'vue'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import { CalendarOptions, EventApi, EventSourceFuncArg, MoreLinkContentArg } from '@fullcalendar/core'
import { eventApi, EventDB } from '@/enitites/event'
import { CalendarEvent } from '../model/types'
import CalendarMobileEventsList from './CalendarMobileEventsList.vue'

function getOffsetDate(date: string | Date) {
  const newDate = new Date(date)
  const offsetDate = newDate.getTimezoneOffset()
  return new Date(newDate.getTime() - offsetDate * 60000).toISOString().split('T')[0]
}

const activeMobileList = ref(false)
const modalEventsData = ref<CalendarEvent[]>([])
const clickedDateEl = ref<HTMLElement | null>(null)
const calendar = ref<InstanceType<typeof FullCalendar> | null>(null)

const calendarApi = computed(() => calendar.value?.getApi())

function resetMobileOptions() {
  activeMobileList.value = false
  modalEventsData.value = []
  calendarApi.value?.setOption('dayMaxEvents', 2)
  calendarApi.value?.setOption('moreLinkContent', (args: MoreLinkContentArg) => `+${args.num}`)
  calendarApi.value?.render()
  clickedDateEl.value?.classList.remove('day-clicked')
}

const calendarOptions: CalendarOptions = {
  initialView: 'dayGridMonth',
  plugins: [dayGridPlugin, interactionPlugin],
  headerToolbar: {
    left: '',
    center: 'prev title next',
    end: '',
  },
  events: async (fetchInfo: EventSourceFuncArg) => {
    const start = getOffsetDate(fetchInfo.startStr)
    const end = getOffsetDate(fetchInfo.endStr)

    const data = await eventApi.getEvents({
      pageNumber: 0,
      pageSize: 999,
      start,
      end,
    })

    if (!data || typeof data === 'string') return []
    const publishedEvents = data.filter((event: EventDB) => event.status === 'published')

    return publishedEvents.map((event: EventDB) => ({
      id: event.id,
      title: event.title,
      start: event.dateStart,
      img: event.image,
      optionalEnd: event.dateEnd,
    }))
  },
  eventColor: 'transparent',
  eventBackgroundColor: 'var(--color-gold-800)',
  eventDisplay: 'block',
  dayMaxEvents: true,
  dayHeaderFormat: {
    weekday: 'narrow',
  },
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: 'short',
  },
  views: {
    dayGridMonth: {
      dayMaxEvents: 2,
    },
  },
  fixedWeekCount: false,
  dayPopoverFormat: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  dateClick: (info: DateClickArg) => {
    const { dayEl } = info
    const isOtherDay = dayEl.classList.contains('fc-day-other')
    if (isOtherDay) return
    if (clickedDateEl.value) clickedDateEl.value.classList.remove('day-clicked')
    clickedDateEl.value = dayEl
    modalEventsData.value = []

    const filteredEvents = info.view.calendar.getEvents().filter((event: EventApi) => {
      if (!event.start) return false
      const eventDate = getOffsetDate(event.start)
      const selectedDate = info.dateStr
      return eventDate === selectedDate
    })

    modalEventsData.value = filteredEvents.map((event: EventApi) => {
      return {
        id: event.id,
        title: event.title,
        start: event.start,
        img: event.extendedProps.img,
        optionalEnd: event.extendedProps.optionalEnd,
      }
    })

    calendarApi.value?.setOption('moreLinkContent', () => '')
    calendarApi.value?.setOption('dayMaxEvents', 0)
    calendarApi.value?.render()
    if (!modalEventsData.value.length) {
      resetMobileOptions()
      return
    }
    dayEl.classList.add('day-clicked')
    activeMobileList.value = true
  },
  moreLinkContent: (args: MoreLinkContentArg) => `+${args.num}`,
  moreLinkClick: () => {
    return 'false'
  },
  height: '100%',
  expandRows: true,
}

const { getImageById } = useImage()
const startX = ref(0)
const NECESSARY_RANGE = 50

function touchMoveHandler(event: TouchEvent) {
  const x = event.changedTouches[0].clientX
  const swipeRightRange = x - startX.value
  const swipeLeftRange = startX.value - x
  if (swipeRightRange > NECESSARY_RANGE) calendarApi.value?.prev()

  if (swipeLeftRange > NECESSARY_RANGE) calendarApi.value?.next()
}

function touchStartHandler(event: TouchEvent) {
  startX.value = event.changedTouches[0].clientX
}
</script>

<template>
  <section
    :class="['calendar-mode', { active: activeMobileList }]"
    @touchmove="touchMoveHandler($event)"
    @touchstart="touchStartHandler($event)"
  >
    <h1 class="title">Calendar Mode</h1>
    <FullCalendar
      ref="calendar"
      :events="calendarOptions.events"
      :options="calendarOptions"
      class="calendar"
    >
      <template #eventContent="{ event, timeText }">
        <router-link
          v-if="!activeMobileList"
          :to="{ name: 'Event', params: { id: event.id } }"
          class="link"
        >
          <BKImage
            :src="getImageById(event.extendedProps.img, 90, 90, 'event')"
            alt=""
            class="event-img"
            height="45"
            width="45"
          />
          <div class="event-description-wrapper">
            <span class="time-text">{{ timeText }}</span>
            <span class="title-text">{{ event.title || 'Untitled' }}</span>
          </div>
        </router-link>
      </template>
    </FullCalendar>
    <CalendarMobileEventsList
      :active-mobile-list="activeMobileList"
      :events-data="modalEventsData"
      @hide-events="resetMobileOptions"
    />
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.calendar-mode {
  display: grid;
  height: calc(100vh - 205px);
  height: calc(100dvh - 205px);
  overflow: hidden;

  .title {
    @include visually-hidden;
  }

  .link {
    display: flex;
    gap: 10px;
    align-items: center;
    pointer-events: none;
  }

  .event-description-wrapper {
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 0 2px;
    overflow: hidden;
  }

  .event-img {
    display: none;
  }

  .time-text {
    display: none;
  }

  .title-text {
    @include font-caption2;

    overflow: hidden;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-medium);
    text-overflow: unset;
  }

  .fc-day-other {
    .time-text {
      opacity: 0.2;
    }

    .title-text {
      opacity: 0.2;
    }
  }

  .calendar {
    --fc-border-color: var(--color-grey-700);
    --fc-page-bg-color: var(--color-grey-100);

    height: 100%;
    background-color: var(--color-grey-100);
  }

  ::v-deep {
    .fc-daygrid-body tr {
      height: auto;
    }

    .fc-daygrid-more-link.fc-more-link {
      @include font-caption1;

      margin-top: 6px;
      padding: 0;
      color: var(--color-dark-800);
      font-weight: var(--font-weight-medium);
      pointer-events: none;

      &::before {
        display: none;
        width: 4px;
        height: 4px;
        background-color: var(--color-grey-500);
        border-radius: 50%;
        content: '';
      }
    }

    .fc-day-other {
      pointer-events: none;

      .fc-daygrid-more-link.fc-more-link {
        opacity: 0.2;
      }
    }

    .fc-toolbar.fc-header-toolbar {
      padding-block: 16px;
      margin: 0;
    }

    .fc-toolbar-chunk {
      display: flex;
      gap: 10px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        color: var(--color-grey-300);
        background-color: transparent;
        border: none;

        .fc-icon {
          display: flex;
        }

        &:is(:hover, :focus, :active, :focus-visible) {
          color: var(--color-grey-300) !important;
          background-color: transparent !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }

      .fc-toolbar-title {
        @include font-body2;

        margin: 2px 0 0;
        color: var(--color-grey-300);
      }
    }

    .fc-daygrid-day-events {
      min-height: 0 !important;
      margin: 0 !important;
    }

    .fc-daygrid-event {
      margin-top: 4px;
      margin-inline: 0 !important;
      border: none !important;
    }

    .fc-scrollgrid {
      border-top: none;
    }

    .fc-daygrid-day {
      background-color: var(--color-grey-100);
    }

    .fc-daygrid-day-frame {
      height: auto;
      max-height: 160px;
      padding: 6px 2px 12px;

      .fc-daygrid-day-top {
        justify-content: center;

        .fc-daygrid-day-number {
          @include font-caption1;

          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          color: var(--color-grey-300);
        }
      }
    }

    .fc-col-header-cell.fc-day {
      border: none;
      border-bottom: 1px solid var(--color-grey-700);
    }

    .fc-col-header-cell-cushion {
      @include font-caption1;

      max-height: 40px;
      color: var(--color-grey-500);
      text-align: center;
      padding-block: 12px;
    }

    .fc-event {
      pointer-events: none;
    }

    & .fc-daygrid-day.fc-day-today {
      background-color: var(--color-grey-100);

      & .fc-daygrid-day-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        padding: 4px;
        color: var(--color-grey-300);
        background-color: transparent;
        border-radius: 50%;
      }
    }

    .fc-daygrid-day.day-clicked {
      background-color: var(--color-gold-800);

      .fc-daygrid-day-number {
        color: var(--color-gold-900);
      }

      .time-text {
        color: var(--color-gold-900);
      }

      .title-text {
        color: var(--calendar-active-text);
      }
    }
  }

  &.active {
    grid-template-rows: repeat(2, 1fr);

    ::v-deep {
      .fc-daygrid-more-link.fc-more-link {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: -4px;

        &::before {
          display: flex;
        }
      }

      .fc-daygrid-day.day-clicked {
        background-color: var(--color-gold-800);

        .fc-more-link {
          &::before {
            background-color: var(--color-gold-900);
          }
        }
      }

      .fc-daygrid-day-frame {
        padding: 4px 20px 6px;
      }

      .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        max-height: min-content;
      }
    }
  }
}
</style>
