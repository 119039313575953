<template>
  <ModalWrapper
    :is-show="isShowEditor"
    modal-width="550px"
    @update:isShow="emit('update:isShowEditor', $event)"
  >
    <v-card class="pa-6">
      <h2 class="add-media-title">Add media</h2>
      <section class="content">
        <v-text-field
          v-if="!newMedia.link"
          v-model="videoUrl"
          autocomplete="off"
          background-color="#fff"
          hide-details="auto"
          outlined
          placeholder="https://www.youtube.com/watch?v=id"
        />

        <v-text-field
          v-else
          v-model="newMedia.name"
          autocomplete="off"
          background-color="#fff"
          outlined
          placeholder="Media name"
        />

        <iframe
          v-if="videoEmbedUrl"
          :src="videoEmbedUrl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          height="300"
          title="Media editor"
          width="500"
        />
      </section>
      <section class="add-btn">
        <BKButton
          v-if="!newMedia.link"
          white-text
          @click="addMedia"
        >
          Add
        </BKButton>
      </section>
      <section
        v-if="newMedia.link"
        class="actions"
      >
        <BKButton
          color="var(--color-green)"
          white-text
          @click="saveMedia"
        >
          Save
        </BKButton>
        <BKButton
          color="var(--color-orange-500)"
          white-text
          @click="resetMediaEditor"
        >
          Cancel
        </BKButton>
      </section>
    </v-card>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { ModalWrapper } from '@/shared/ui/modals'
import { mediaApi, MediaDB } from '@/enitites/media'
import { ref, watch } from 'vue'

const props = defineProps<{
  isShowEditor: boolean
  orgId: number
  eventId: number
}>()

const emit = defineEmits<{
  (e: 'update:isShowEditor', value: boolean): void
  (e: 'save', value: MediaDB[]): void
}>()

const newMedia = ref<MediaDB>({
  link: '',
  name: '',
  id: 0,
  userId: 0,
})

const videoUrl = ref('')
const videoEmbedUrl = ref('')

function resetMediaEditor() {
  videoUrl.value = ''
  videoEmbedUrl.value = ''
  newMedia.value = {
    link: '',
    name: '',
    id: 0,
    userId: 0,
  }
}

function getYouTubeMediaId(url: string) {
  const match = url.match(/(?:[?&]v=|\/embed\/|\/1\/|\/v\/|https:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/)
  if (match) {
    const [, id] = match
    return id
  }
  return null
}

function addMedia() {
  const mediaId = getYouTubeMediaId(videoUrl.value)

  if (!mediaId) return

  videoEmbedUrl.value = `https://www.youtube.com/embed/${mediaId}`
  newMedia.value.link = mediaId
}

async function saveMedia() {
  const mediaPayload = { ...newMedia.value, orgId: props.orgId, eventId: props.eventId }
  const updatedMedia = await mediaApi.addMedia(mediaPayload)
  if (updatedMedia) emit('save', updatedMedia)
}

watch(
  () => props.isShowEditor,
  (val) => {
    if (!val) {
      resetMediaEditor()
    }
  }
)
</script>

<style lang="scss" scoped>
.add-media-title {
  margin-bottom: 6px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.content {
  @media (max-width: 768px) {
    padding: 20px 20px 0;
  }
}

.add-btn {
  margin-top: 20px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}
</style>
