<template>
  <article
    v-if="isOpen"
    class="cookie"
  >
    <h5 class="cookie-title">
      <img
        alt="cookie"
        height="22"
        src="/img/cookie.png"
        width="22"
      />
      Cookie settings
    </h5>
    <p class="cookie-text">
      We use our own cookies so that we can show you this website and understand how you use them to improve the
      services we offer
    </p>
    <div class="btn-container">
      <BKButton
        class="btn"
        height="42"
        outlined
        @click="isOpen = false"
      >
        Decline
      </BKButton>
      <BKButton
        class="btn"
        height="42"
        white-text
        @click="acceptCookie"
      >
        Allow
      </BKButton>
    </div>
  </article>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

const isOpen = ref(true)

function acceptCookie() {
  document.cookie = 'cookie=accepted'
  isOpen.value = false
}

onMounted(() => {
  const cookie = document.cookie.split('; ').find((item) => item === 'cookie=accepted')
  isOpen.value = !cookie
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.cookie {
  position: fixed;
  bottom: 18px;
  left: 30px;
  z-index: 10;
  max-width: 360px;
  padding: 16px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-rounded);
  box-shadow:
    0 12px 24px -6px rgb(96 96 96 / 15%),
    0 0 1px rgb(96 96 96 / 10%);

  .cookie-title {
    @include font-h4;

    margin-bottom: 14px;
    color: var(--color-dark-800);

    @media (max-width: $screen-sm) {
      @include font-h5;
    }
  }

  .cookie-text {
    @include font-body2;

    margin-bottom: 20px;
    color: var(--color-grey-300);
  }

  .btn-container {
    display: flex;
    gap: 20px;
  }

  .btn {
    flex-shrink: 1;
  }

  @media (max-width: $screen-sm) {
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 100%;
    padding-bottom: 30px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
