<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
  >
    <template #default>
      <li
        :class="{ active: user.card }"
        class="flip-container"
      >
        <div class="flipper">
          <ProfileDancerCard
            :user="user"
            class="front"
            @avatarLoaded="avatarLoaded($event)"
            @changeAvatar="user.card = $event"
          />
          <ProfileDancerCard
            :avatar-url="avatarUrl"
            :user="user"
            class="back"
            @avatarLoaded="avatarLoaded($event)"
            @changeAvatar="user.card = $event"
          />
        </div>
      </li>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading"
        :loading="loading"
        white-text
        @click="emit('submitForm', user)"
      >
        create profile
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script lang="ts" setup>
import BKButton from '@/components/AppShared/BKButton.vue'
import ProfileDancerCard from '@/components/Profile/ProfileDancerCard.vue'
import { UserDB } from '@/enitites/user'
import { ref } from 'vue'
import { useCloned } from '@vueuse/core'
import OnboardingFormLayout from './OnboardingFormLayout.vue'

const props = withDefaults(
  defineProps<{
    currentStep: number
    error: string
    loading: boolean
    steps: number
    user: UserDB
  }>(),
  {
    currentStep: 0,
    error: '',
    loading: false,
    steps: 0,
  }
)
const emit = defineEmits<{
  (e: 'goBack'): void
  (e: 'submitForm', value: UserDB): void
}>()

const user = ref(useCloned(props.user).cloned)
const avatarUrl = ref('')

function avatarLoaded(url: string) {
  avatarUrl.value = url
}
</script>

<style lang="scss" scoped>
.flip-container {
  width: 100%;
  max-width: 305px;
  height: 363px;
  margin: 0 auto;
  perspective: 1000px;
}

.flipper {
  position: relative;
  height: 100%;
  transform-style: preserve-3d;
  transition: 0.6s;
}

.flip-container.active .flipper {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
  transform: rotateX(0deg);
}

.back {
  transform: rotateY(180deg);
}
</style>
