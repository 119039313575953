<script lang="ts" setup>
import { BKModal } from '@/shared/ui/modals'
import { ref } from 'vue'
import SearchEvents from '@/pages/EventsPage/ui/SearchEvents.vue'
import { mdiMagnify } from '@mdi/js'
import { EventPeriod } from '@/enitites/event'

const isShow = ref(false)
const emit = defineEmits<{ (e: 'changed-tab', tab: EventPeriod): void }>()
</script>

<template>
  <div
    aria-label="Dialog container"
    class="search-events-dialog"
  >
    <BKButton
      aria-label="Open dialog"
      color="var(--color-black)"
      height="24"
      icon
      width="24"
      @click="isShow = true"
    >
      <v-icon>
        {{ mdiMagnify }}
      </v-icon>
    </BKButton>
    <BKModal
      :is-show.sync="isShow"
      aria-label="Dialog"
    >
      <template #default>
        <div class="dialog-content">
          <SearchEvents @changed-tab="emit('changed-tab', $event)" />
        </div>
      </template>
    </BKModal>
  </div>
</template>
<style lang="scss" scoped>
.dialog-content {
  display: grid;
  gap: 40px;
  padding-top: 16px;
}
</style>
