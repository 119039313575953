<template>
  <ModalWrapper
    :is-show="isShowModal"
    class="members"
    @update:isShow="emit('update:isShowModal', $event)"
  >
    <v-card
      class="members__card"
      outlined
      tile
    >
      <div class="members__content">
        <h2 class="members__title mb-4">
          <span v-if="isPastEvent">Who went?</span> <span v-else>Who’s going?</span> ({{ whoIsGoing.length }})
        </h2>

        <div
          v-for="participant in whoIsGoing"
          :key="participant.id"
          class="members__chip"
        >
          <router-link
            :to="{ name: 'Profile', params: { id: participant.id } }"
            class="members__member-link"
          >
            <v-avatar
              class="mr-1"
              size="32px"
            >
              <v-img :src="getImageById(participant.avatar, 32, 32, 'avatar')" />
            </v-avatar>
            {{ participant.dancerName }}
          </router-link>
          <v-divider class="my-4" />
        </div>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { UserChip } from '@/enitites/event'
import { defineEmits } from 'vue'
import { useImage } from '@/shared/composables/image'
import { ModalWrapper } from '@/shared/ui/modals'

const { getImageById } = useImage()
defineProps<{
  isShowModal: boolean
  whoIsGoing: UserChip[]
  isPastEvent: boolean
}>()

const emit = defineEmits<{
  (e: 'update:isShowModal', value: boolean): void
}>()
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.members {
  &__content {
    padding: 40px 70px;

    @media (max-width: 600px) {
      padding: 100px 20px 20px;
    }
  }

  &__member-link {
    color: black;
  }

  &__card {
    position: relative;
  }

  &__title {
    @include font-h2;

    font-weight: 400;
    text-transform: uppercase;
  }

  &__icon {
    transform: rotate(90deg);
  }

  &__buy {
    @include font-caption2;

    text-transform: uppercase;
    vertical-align: middle;
  }

  &__chip {
    position: relative;
  }

  &__circle {
    position: absolute;
    top: 3px;
    right: 0;
    display: inherit;
    padding: 0 6px;
    background-color: #fc7800;
    border-radius: 50%;

    &.cash {
      background-color: #6cace4;
    }

    &.card {
      background-color: var(--color-green);
    }

    &.battle {
      padding: 0 4.4px;
      color: var(--color-white);
    }
  }
}
</style>
