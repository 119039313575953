<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
  >
    <template #default>
      <div class="onboarding-avatar-form">
        <button
          type="button"
          @click="openFileDialog"
        >
          <img
            :class="{ 'onboarding-avatar-form__img--active': filesImgAvatar }"
            :src="avatarUrl"
            alt="avatar"
            class="onboarding-avatar-form__img"
            height="140"
            width="140"
          />
        </button>
        <!--        eslint-disable  vuejs-accessibility/form-control-has-label-->
        <input
          ref="inputRef"
          accept="image/png, image/jpeg"
          name="userImage"
          style="display: none"
          type="file"
          @change="handlerFileChange"
        />
        <!--        eslint-enable  vuejs-accessibility/form-control-has-label-->
        <p class="onboarding-avatar-form__title">Add a profile picture</p>
      </div>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading"
        :loading="loading"
        white-text
        @click="emit('submitForm', user)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script lang="ts" setup>
import BKButton from '@/components/AppShared/BKButton.vue'
import { UserDB } from '@/enitites/user'
import { ref } from 'vue'
import { useImage } from '@/shared/composables/image'
import { useCloned } from '@vueuse/core'
import OnboardingFormLayout from './OnboardingFormLayout.vue'

const props = withDefaults(
  defineProps<{
    currentStep: number
    error: string
    loading: boolean
    steps: number
    user: UserDB
  }>(),
  {
    currentStep: 0,
    error: '',
    loading: false,
    steps: 0,
  }
)

const user = ref(useCloned(props.user).cloned)

const emit = defineEmits<{
  (e: 'goBack'): void
  (e: 'submitForm', value: UserDB): void
}>()
const { saveImage } = useImage()
const avatarUrl = ref('/img/addImageProfile.svg')
const filesImgAvatar = ref<File | null>(null)
const inputRef = ref<HTMLInputElement | null>(null)

function openFileDialog() {
  inputRef.value?.click()
}

async function handlerFileChange(e: Event) {
  const target = e.target as HTMLInputElement
  filesImgAvatar.value = target.files ? target.files[0] : null
  if (!filesImgAvatar.value) return
  const reader = new FileReader()
  reader.onload = (event) => {
    avatarUrl.value = event.target?.result as string
  }
  reader.readAsDataURL(filesImgAvatar.value)

  user.value.avatar = await saveImage(filesImgAvatar.value)
}
</script>

<style lang="scss" scoped>
.onboarding-avatar-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.onboarding-avatar-form__img {
  margin-bottom: 20px;
  object-fit: cover;
  object-position: center;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  perspective: 1000px;
  transition: transform 0.3s ease-in-out;

  &--active {
    border-radius: 50%;
    transform: rotateY(360deg);
  }
}
</style>
