import { render, staticRenderFns } from "./CreateEventPage.vue?vue&type=template&id=e553f000&scoped=true"
import script from "./CreateEventPage.vue?vue&type=script&lang=ts&setup=true"
export * from "./CreateEventPage.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./CreateEventPage.vue?vue&type=style&index=0&id=e553f000&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e553f000",
  null
  
)

export default component.exports