<script lang="ts" setup>
import { useImage } from '@/shared/composables/image'
import { EventDB } from '@/enitites/event'
import { EventBaseCard } from '@/shared/ui/cards'
import BKSvgIcon from '@/components/AppShared/BKSvgIcon.vue'
import { ref } from 'vue'

const { getImageById } = useImage()
const props = defineProps<{
  previewEvent: EventDB
}>()

const eventSharedLink = `${window.location.origin}/event/${props.previewEvent.id}`

const sharedSMLinksMap = new Map([
  ['facebook', `https://www.facebook.com/sharer/sharer.php?u=${eventSharedLink}`],
  ['twitter', `https://twitter.com/intent/tweet?text=${props.previewEvent.title}&url=${eventSharedLink}`],
])
const titleWhiteSpaceValue = ref<'normal' | 'nowrap'>('nowrap')
</script>

<template>
  <EventBaseCard
    class="event-card-desktop"
    @blur="titleWhiteSpaceValue = 'nowrap'"
    @focus="titleWhiteSpaceValue = 'normal'"
    @mouseleave="titleWhiteSpaceValue = 'nowrap'"
    @mouseover="titleWhiteSpaceValue = 'normal'"
  >
    <template #img>
      <v-img
        :src="getImageById(previewEvent.image, 800, 800, 'event')"
        alt=""
        class="img"
      />
    </template>
    <template #meta>
      <div class="meta">
        <BKButton
          :to="{ name: 'Event', params: { id: previewEvent.id } }"
          class="event-title event-link"
          color="var(--color-white)"
        >
          Event info
        </BKButton>
        <div class="share-links-container">
          <span class="share-links-text">Share the event</span>
          <div class="share-links">
            <a
              v-for="[iconName, link] in sharedSMLinksMap"
              :key="link"
              :aria-label="`Share to ${iconName}`"
              :href="link"
              target="_blank"
            >
              <BKSvgIcon :name="`social-media/${iconName}`" />
            </a>
          </div>
        </div>
      </div>
    </template>
    <template #title>
      <h5 class="event-card-title">
        {{ previewEvent.title }}
      </h5>
    </template>
    <template #date>
      <p class="event-card-date">
        {{ previewEvent.dateStart.toLocaleDateString('en-US') }}
      </p>
    </template>
  </EventBaseCard>
</template>
<style lang="scss" scoped>
@import '@/assets/style/mixins';

.event-card-desktop {
  position: relative;
  width: 424px !important;
  max-width: 424px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s ease-out;

  .wrapper-img {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    background-color: var(--color-black);
    border-radius: 10px;
  }

  .event-link {
    grid-row: 2 / 3;
    max-width: 216px;
    color: var(--color-black);
  }

  .share-links-container {
    display: flex;
    flex-direction: column;
    grid-row: 3 / 4;
    align-items: center;
    justify-content: center;

    .share-links-text {
      color: var(--color-white);
    }

    .share-links {
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }

  .meta {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease-out;
    place-items: center center;
  }

  @media (hover: hover) {
    &:hover {
      .meta {
        opacity: 1;
      }

      .img {
        opacity: 0.2;
      }
    }
  }
}
</style>
