<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getDateRangeFormatted } from '@/services/date'
import { mdiDotsHorizontal } from '@mdi/js'
import { ref } from 'vue'
import { useImage } from '@/shared/composables/image'
import { EventDB } from '@/enitites/event'

const { getEventImg } = useImage()

const props = defineProps<{
  event: EventDB
}>()

const isShowDeleteModal = ref(false)

const getEventDate = getDateRangeFormatted(props.event.dateStart, props.event.dateEnd)
</script>

<template>
  <v-col cols="12">
    <div class="event-row align-start">
      <v-img
        :src="getEventImg(event.image, 800)"
        class="flex-grow-0 mr-4 event-img"
      />
      <div class="mr-auto col-6 py-0">
        <p class="event-title">
          {{ getEventDate }}
        </p>
        <p class="events__title">
          <span v-if="event.status === 'draft'"> <span class="draft-label">Draft</span> - </span>
          <router-link
            :to="{ name: 'Event', params: { id: event.id } }"
            class="event-link"
          >
            {{ event.title || 'Event name' }}
          </router-link>
        </p>
      </div>
      <div
        class="d-flex align-center"
        @click.stop=""
      >
        <!-- Event options dropdown -->
        <v-menu
          v-if="event.status === 'draft'"
          bottom
          left
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-5"
              icon
              v-on="on"
            >
              <v-icon>
                {{ mdiDotsHorizontal }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="isShowDeleteModal = true"> Delete Event</v-list-item>
            <v-list-item
              :to="{ name: 'CreateEventProcessing', params: { id: event.id } }"
              link
            >
              Edit Event
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div class="line" />
  </v-col>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.event-row {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  transition: all 0.3s ease;

  .event-link {
    color: inherit;

    &::after {
      position: absolute;
      inset: 0;
      content: '';
    }
  }
}

.draft-label {
  color: #ff2d53;
}

.event-title {
  @include font-caption1;

  margin: 0 4px 0 0;
  color: var(--color-grey-300);
  font-style: normal;

  @media (min-width: 600px) {
    @include font-body1;
  }
}

.line {
  border-bottom: 1px solid #ddd;
}

.event-img {
  width: 80px;
  height: 80px;

  @media (min-width: 600px) {
    width: 200px;
    height: 100px;
  }

  &:hover {
    opacity: 0.7;
  }
}
</style>
